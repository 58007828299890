import Vue from 'vue';
import Vuex from 'vuex';
import { mapKeys, has } from 'lodash/fp';
import { user } from '@/store/modules/user';
import { pathways } from '@/store/modules/pathways';
import { goals } from '@/store/modules/goals';
import { outcomes } from '@/store/modules/outcomes';
import { surveys } from '@/store/modules/surveys';
import { globalErrors } from '@/store/modules/globalErrors';
import { notes } from '@/store/modules/notes';
import { meetings } from '@/store/modules/meetings';

Vue.use(Vuex);

const modules = {
  user,
  pathways,
  goals,
  outcomes,
  surveys,
  globalErrors,
  notes,
  meetings,
};

const store = new Vuex.Store({
  modules,
  state: {},
  mutations: {},
  actions: {
    async init_all(_stateContext) {
      // This will run the init action in all submodules. Called after login
      const modulesNames = Object.keys(modules);
      for (const moduleName of modulesNames) {
        if (has(`${moduleName}.actions.init`, modules)) {
          try {
            await store.dispatch(`${moduleName}/init`);
          } catch (error) {
            // console.log('some init error', error);
          }
        }
      }
      return;
    },
    async listen_all(_stateContext, user_uuid) {
      // This will run the listen action in all submodules
      mapKeys((moduleName) => {
        if (has(`${moduleName}.actions.listen`, modules)) {
          store.dispatch(`${moduleName}/listen`, user_uuid);
        }
        return moduleName;
      }, modules);
    },
  },
});

export default store;
