import Vue from 'vue';
import Vuetify from 'vuetify/lib';
// import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      dark: {
        primary: '#1976D2',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      },
      light: {
        primary: '#0E5174',
        base: '#0E5174',
        rose: '#F7F4F4',
        orange: '#CE5B4B',
        green: '#D2E2E2',
        grape: '#7C368B',
        'light-blue': '#F2F5F7',
        'light-blue-2': '#ECF0F2',
        'dark-blue': '#0E5174',
        'md-blue': '#E2E9EB',
        anchor: '#0E5174',
      },
    },
  },
});
