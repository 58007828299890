<template>
  <div class="mb-6 px-4">
    <h2 class="ml-12 mt-12">View your upcoming schedule</h2>
    <v-card color="light-blue-2 pa-12 mt-6">
      <v-card
        color="light-blue pa-6 mb-2"
        v-for="(meeting, m) in meetings"
        :key="m"
      >
        <v-row class="align-center flex-md-row flex-columns">
          <v-col md="8" cols="12">
            <v-row class="align-center">
              <v-col class="flex-grow-0 flex-shrink-1">
                <v-img
                  v-if="
                    meeting.format === 'acuity' &&
                    meeting.metadata &&
                    meeting.metadata.acuity_calendar === 'social_groups'
                  "
                  src="@/assets/svgs/Group.svg"
                  width="30"
                  class="d-inline-flex"
                />
                <v-img
                  v-else-if="meeting.format === 'acuity'"
                  src="@/assets/svgs/Skill Path Class.svg"
                  width="30"
                  class="d-inline-flex"
                />
                <!-- TODO: add recurrence icon -->
                <!-- <v-img
                  v-else-if="!meeting.meeting_uuid && !!meeting.recurrence_uuid"
                  src="@/assets/svgs/Meeting.svg"
                  width="30"
                  class="d-inline-flex"
                /> -->
                <v-img
                  v-else
                  src="@/assets/svgs/Meeting.svg"
                  width="30"
                  class="d-inline-flex"
                />
              </v-col>
              <v-col
                v-if="!!meeting.scheduled_start"
                class="flex-grow-1 flex-shrink-0"
              >
                <span class="font-weight-medium mb-0">
                  <span
                    class="orange--text"
                    v-if="isToday(meeting.scheduled_start)"
                  >
                    Today!
                  </span>
                  {{ meeting.subject }} <br />
                </span>
                <span :class="{ 'text-caption': true }">
                  {{ meeting.scheduled_start | formatDateExt }}
                  {{ meeting.scheduled_start | formatTime }} -
                  {{ meeting.scheduled_finish | formatTime }}
                  {{ meeting.scheduled_finish | formatTimezone }}
                </span>
              </v-col>
              <v-col
                v-if="!meeting.meeting_uuid && !!meeting.recurrence_uuid"
                class="flex-grow-1 flex-shrink-0"
              >
                <span class="font-weight-medium pb-2">
                  {{ meeting.subject }} <br />
                </span>
                <div
                  v-for="(day, i) in Object.keys(meeting.blocks)"
                  class="text-capition text-no-wrap"
                  :key="i"
                >
                  <span class="text-capitalize">{{ day }}s: </span>
                  <span
                    v-for="(block, index) in meeting.blocks[day]"
                    :key="`${day}${index}`"
                  >
                    {{ block.start | formatTimeOnly }}
                    -
                    {{ block.end | formatTimeOnly }}
                  </span>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="text-end" md="2"
            ><a
              v-if="meeting.format === 'acuity'"
              @click="view(meeting)"
              class="text-decoration-underline font-weight-medium d-block nowrap text-body-2"
            >
              Edit Registration
            </a></v-col
          >
          <v-col class="text-center" md="2" cols="12">
            <v-btn
              small
              dark
              color="grape"
              v-if="meeting.link"
              @click="join(meeting)"
            >
              <span v-if="meeting.format === 'acuity'">
                <span
                  v-if="meeting.metadata.acuity_calendar === 'social_groups'"
                >
                  Join Meetup
                </span>
                <span v-else>Join Class</span>
              </span>
              <span v-else>Join Meeting</span>
              <v-img src="@/assets/svgs/arrow.svg" width="20" class="ml-2" />
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-card>
    <v-dialog v-model="open" width="1000">
      <v-component
        v-if="meeting && open"
        :is="currentComponent"
        :meeting="meeting"
        :item="socialGroup"
        :user="user"
        @close="open = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import MeetingDetails from './MeetingDetails';
import EditRegistration from './EditRegistration';
export default {
  name: 'Schedule',
  props: ['items', 'user'],
  components: { MeetingDetails, EditRegistration },
  data() {
    return {
      open: false,
      meeting: undefined,
      currentComponent: undefined,
      socialGroup: undefined,
      type: undefined,
    };
  },
  computed: {
    meetings: function () {
      if (this.items) {
        return this.items.slice(0, 4);
      }
      return [];
    },
  },
  methods: {
    isToday(date) {
      return moment(date).isSame(moment(), 'day');
    },
    join(meeting) {
      window.open(meeting.link, '_blank');
    },
    view(meeting) {
      this.open = true;
      this.meeting = meeting;
      this.currentComponent =
        meeting.format === 'acuity' ? EditRegistration : MeetingDetails;
    },
  },
};
</script>
