import axios from 'axios';
import {
  outcomeEnrichedGoalUsers,
  outcomesSplitByAmount,
} from '@/helpers/outcomes';

const state = {
  goals: null,
  goalUsers: null,
  loading: true,
  enrichedGoalUsers: null,
  completedEnrichedGoalUsers: null,
  pendingEnrichedGoalUsers: null,
};

const getters = {
  goals: (state) => {
    return state.goals;
  },
  goalUsers: (state) => {
    return state.goalUsers;
  },
  loading: (state) => {
    return state.loading;
  },
};

const actions = {
  sortGoals({ commit }, goals) {
    const goalUsers = [];
    goals.forEach((goal) => goalUsers.push(...goal.goal_users));
    goalUsers.sort(
      (goal_users_a, goal_users_b) =>
        new Date(goal_users_b.updated_at) - new Date(goal_users_a.updated_at)
    );
    commit('cache_goal_users', goalUsers);
  },
  async fetchGoals({ commit, dispatch }) {
    await axios.get('/api/goal_user/goals').then(({ data }) => {
      dispatch('sortGoals', data);
      commit('cache_goals', data);
      return data;
    });
  },
  async init({ dispatch }) {
    await dispatch('fetchGoals');
    return;
  },
};

const mutations = {
  cache_goals(_state, goals) {
    _state.goals = goals;
    _state.loading = false;
  },
  cache_goal_users(_state, goalUsers) {
    _state.goalUsers = goalUsers;
  },
  async cache_enriched_goal_users(_state, settings) {
    const { outcomeUsers, outcomes } = settings;
    const splitOutOutcomes = await outcomesSplitByAmount(outcomeUsers);
    const enrichedGoalUsers = await outcomeEnrichedGoalUsers(
      _state.goalUsers,
      outcomes,
      splitOutOutcomes
    );
    const completedEnrichedGoalUsers = enrichedGoalUsers.filter(
      (enrichedGoalUser) =>
        enrichedGoalUser.primary_interventions.find((block) =>
          block.find((intervention) => intervention.hasQualifyingIntervention)
        )
    );
    const pendingEnrichedGoalUsers = enrichedGoalUsers.filter(
      (enrichedGoalUser) =>
        enrichedGoalUser.primary_interventions.find((block) =>
          block.find((intervention) => !intervention.hasQualifyingIntervention)
        )
    );
    _state.enrichedGoalUsers = enrichedGoalUsers;
    _state.completedEnrichedGoalUsers = completedEnrichedGoalUsers;
    _state.pendingEnrichedGoalUsers = pendingEnrichedGoalUsers;
  },
};

export const goals = {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
