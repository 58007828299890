import axios from 'axios';
export default {
  async createPathwayUser(pathway_uuid) {
    return axios.post(`/api/pathway_user/`, { pathway_uuid });
  },
  async startPathwayUser(pathway_user_uuid) {
    return axios.post(`/api/pathway_user/${pathway_user_uuid}/start`);
  },
  async resumePathwayUser(pathway_user_uuid) {
    return axios.post(`/api/pathway_user/${pathway_user_uuid}/resume`);
  },
  async completePathwayUser(pathway_user_uuid) {
    return axios.post(`/api/pathway_user/${pathway_user_uuid}/complete`);
  },
};
