import axios from 'axios';

const state = {
  outcomes: null,
  outcomesDetails: null,
  loading: true,
  outcomeUsers: [],
};

const getters = {
  outcomes: (state) => {
    return state.outcomes;
  },
  outcomesDetails: (state) => {
    return state.outcomesDetails;
  },
};

const actions = {
  sortOutcomes({ commit, dispatch }, outcomes) {
    const sorted_outcomes = outcomes.sort(async (outcome_a, outcome_b) => {
      const outcome_users_a = await dispatch('sortOutcomeUsers', outcome_a);
      const outcome_users_b = await dispatch('sortOutcomeUsers', outcome_b);
      return (
        new Date(outcome_users_b[0].outcome_user_datetime) -
        new Date(outcome_users_a[0].outcome_user_datetime)
      );
    });
    dispatch('getOutcomeUsers', sorted_outcomes);
    commit('cache_outcomes', sorted_outcomes);
  },
  sortOutcomeUsers({}, outcome) {
    return outcome.outcome_users.sort((outcome_users_a, outcome_users_b) => {
      return (
        new Date(outcome_users_b.outcome_user_datetime) -
        new Date(outcome_users_a.outcome_user_datetime)
      );
    });
  },
  getOutcomeUsers({ commit }, outcomes) {
    const outcomeUsers = [];
    outcomes.forEach((outcome) => {
      outcomeUsers.push(...outcome.outcome_users);
    });
    commit('cache_outcome_users', outcomeUsers);
    commit(
      'goals/cache_enriched_goal_users',
      { outcomeUsers, outcomes },
      { root: true }
    );
  },
  async fetchOutcomes({ dispatch }) {
    await axios.get('/api/outcome_user/outcomes').then(({ data }) => {
      dispatch('sortOutcomes', data);
      return data;
    });
  },
  async fetchOutcomesDetails({ rootGetters, commit }) {
    if (rootGetters['goals/goals'] && rootGetters['goals/goals'].length) {
      const outcome_uuids = [];
      rootGetters['goals/goals'].forEach((goal) => {
        if (goal.primary_interventions) {
          goal.primary_interventions.forEach((item) => {
            if (typeof item === 'object' && !outcome_uuids.includes(item))
              outcome_uuids.push(...item);
            else if (!outcome_uuids.includes(item)) outcome_uuids.push(item);
          });
        }
        if (goal.supporting_interventions) {
          goal.supporting_interventions.forEach((item) => {
            if (typeof item === 'object' && !outcome_uuids.includes(item))
              outcome_uuids.push(...item);
            else if (!outcome_uuids.includes(item)) outcome_uuids.push(item);
          });
        }
      });
      const queryString = new URLSearchParams({
        outcome_uuids: outcome_uuids,
      }).toString();
      await axios.get(`/api/outcome?${queryString}`).then(({ data }) => {
        commit('cache_outcomes_details', data);
        return data;
      });
    } else commit('cache_outcomes_details', []);
  },
  async init({ dispatch }) {
    await dispatch('fetchOutcomes');
    await dispatch('fetchOutcomesDetails');
    return;
  },
};

const mutations = {
  cache_outcomes(_state, outcomes) {
    _state.outcomes = outcomes;
  },
  cache_outcomes_details(_state, outcomesDetails) {
    _state.outcomesDetails = outcomesDetails;
  },
  cache_outcome_users(_state, outcomeUsers) {
    _state.outcomeUsers = outcomeUsers;
  },
};

export const outcomes = {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
