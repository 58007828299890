<template>
  <v-dialog v-model="open" :persistent="persistent" :width="width || 500">
    <v-card>
      <v-card-title class="headline grey lighten-2" primary-title>
        {{ note.title }}
      </v-card-title>
      <v-card-text
        v-for="(element, e) in note.domainNoteElements"
        :key="e"
        class="mt-3"
      >
        <v-component :is="noteComponents[element.type]" v-bind="element" />
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <TrackedButton
          @click="emitCancel()"
          buttonText="Cancel"
          color="primary"
          :name="cancelActionName"
          textStyle
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import TrackedButton from '@/components/TrackedButton';
import NoteCheckbox from '@/components/note/NoteCheckbox';
import NoteDate from '@/components/note/NoteDate';
import NoteEditor from '@/components/note/NoteEditor';
import NoteRadiogroup from '@/components/note/NoteRadiogroup';
import NoteText from '@/components/note/NoteText';

export default {
  name: 'NoteDialog',
  components: {
    NoteCheckbox,
    NoteDate,
    NoteEditor,
    NoteRadiogroup,
    NoteText,
    TrackedButton,
  },
  props: ['cancelActionName', 'note', 'persistent', 'width'],
  data: () => {
    return {
      noteComponents: {
        checkbox: NoteCheckbox,
        date: NoteDate,
        editor: NoteEditor,
        radiogroup: NoteRadiogroup,
        text: NoteText,
      },
    };
  },
  computed: {
    open: {
      get() {
        const vm = this;
        return !!vm.note;
      },
      set(val) {
        return val;
      },
    },
  },
  methods: {
    emitCancel() {
      this.$emit('cancel');
    },
  },
};
</script>
<style scoped></style>
