<template>
  <v-container grid-list-xl style="max-width: fit-content" class="pa-8">
    <v-layout justify-center wrap>
      <v-flex xs12 sm12>
        <personal-settings></personal-settings>
      </v-flex>
      <v-flex xs12 sm12>
        <virtual-resume></virtual-resume>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState } from 'vuex';
import PersonalSettings from '../components/PersonalSettings';
import VirtualResume from '../components/VirtualResume';
export default {
  components: {
    'personal-settings': PersonalSettings,
    'virtual-resume': VirtualResume,
  },
  data: () => ({}),
  computed: {
    ...mapState({
      user: (state) => state.user.user,
    }),
  },
};
</script>
