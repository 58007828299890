import { render, staticRenderFns } from "./NoteText.vue?vue&type=template&id=117d25ca&scoped=true"
import script from "./NoteText.vue?vue&type=script&lang=js"
export * from "./NoteText.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "117d25ca",
  null
  
)

export default component.exports