import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"pa-12"},[_c('div',{staticClass:"pa-12"},[_c(VBtn,{attrs:{"icon":"","absolute":"","top":"","right":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c(VIcon,[_vm._v("mdi-close")])],1),_c('div',{staticClass:"mx-6"},[_c('h2',[_vm._v(_vm._s(_vm.item.name))]),_c('p',[_vm._v(" You’re all set. You’ll receive an email with the "),(_vm.type === 'class')?_c('span',[_vm._v("class")]):_c('span',[_vm._v("meetup")]),_vm._v(" link shortly. The link becomes active 15 minutes before the start of class. ")])]),_c(VCard,{attrs:{"color":"light-blue py-6 px-8 my-2"}},[_c(VRow,{staticClass:"align-center justify-space-between"},[_c('div',{staticClass:"font-weight-medium"},[_c(VRow,{staticClass:"align-center"},[_c(VCol,{staticClass:"flex-grow-0 flex-shrink-1"},[_c(VImg,{attrs:{"src":require("@/assets/svgs/talk.svg"),"width":"30"}})],1),_c(VCol,{staticClass:"flex-grow-1 flex-shrink-0"},[_vm._v(" "+_vm._s(_vm._f("formatStartEndExt")(_vm.item.time,_vm.item.duration))+" ")])],1)],1),_c(VChip,{attrs:{"color":"green","label":""}},[_c(VIcon,{attrs:{"color":"base","small":""}},[_vm._v("mdi-check")]),_c('span',{staticClass:"font-weight-medium ml-2 text-body-2"},[_vm._v(" You’ve "),(_vm.type === 'class')?_c('span',[_vm._v("registered")]):_c('span',[_vm._v("signed up")])])],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }