import axios from 'axios';
import { domainSocket } from '@/socket';
import { get, find } from 'lodash/fp';
import moment from 'moment-timezone';
import { getPermissionsObj } from '@/helpers/permissions';

const state = {
  auth_user: {},
  user: {},
  userTags: [],
  listener: 0,
};

const getters = {
  registered: (state) =>
    ['submitted', 'registered'].includes(state.user.status),
  userType: (state) => {
    if (state.user.roles.user) return 'Member';
  },
  nextModuleToComplete: () => {
    return '1';
  },
  emailVerified: (state) => get('auth_user.email_verified', state),
  verificationExpired: (state) => {
    return (
      !get('auth_user.email_verified', state) &&
      moment(state.user.registration_timestamp) <
        moment(moment().subtract(72, 'hours'))
    );
  },
  email: (state) => {
    return state.user.email;
  },
  name: (state) => {
    if (state.user.profile.first_name && state.user.profile.last_name)
      return `${state.user.profile.first_name} ${state.user.profile.last_name}`;
  },
  permissions: (state) => {
    if ((state.userTags, state.user.tags)) {
      return getPermissionsObj(state.userTags);
    }
    return {};
  },
};

const actions = {
  async listen({ commit, state }, user_uuid) {
    // Only setup channel if listener doesnt already exist
    if (state.listener) return state.listener;
    let channel = domainSocket.join_channel(user_uuid, 'user');
    if (channel) {
      const ref = channel.on(`user:${user_uuid}`, (data) => {
        commit('cache_user', data);
        commit('cache_listener_ref', ref);
        return ref;
      });
    } else {
      return;
    }
  },
  async fetchUser({ commit, dispatch }) {
    const { data } = await axios.get('/api/user');
    commit('cache_user', data);
    dispatch('listen', data.user_uuid);
    moment.tz.setDefault(
      data.profile.timezone ? data.profile.timezone : 'America/Phoenix'
    );
    return data;
  },
  async setUser({ commit, dispatch }, data) {
    commit('cache_user', data);
    dispatch('listen', data.user_uuid);
    return data;
  },
  async fetchUserTags({ commit }) {
    const { data } = await axios.get('/api/tags/user');
    commit('cache_user_tags', data);
    return data;
  },
  async init({ dispatch }) {
    await dispatch('fetchUser');
    await dispatch('fetchUserTags');
    return;
  },
  set_auth_user({ commit }, authUser) {
    commit('cache_auth_user', authUser);
  },
  async updateProfile({ commit, state }, settings) {
    await axios.post('/api/profile', {
      user_uuid: state.user.user_uuid,
      ...settings,
    });
    commit('updateProfile', settings);
    return;
  },
};

const modules = {};

const mutations = {
  cache_user(_state, user) {
    // override hack for now
    _state.user = user;
  },
  cache_listener_ref(_state, ref) {
    _state.listener = ref;
  },
  cache_auth_user(_state, authUser) {
    _state.auth_user = authUser;
  },
  cache_user_tags(_state, userTags) {
    _state.userTags = userTags;
  },
  updateProfile(_state, settings) {
    _state.user = { ...state.user, ...settings };
  },
};

export const user = {
  state,
  getters,
  actions,
  mutations,
  modules,
  namespaced: true,
};
