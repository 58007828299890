<template>
  <v-container fluid class="dashboard">
    <v-row class="align-lg-stretch pa-4">
      <v-col cols="12">
        <v-card height="100%">
          <Personal
            :note="latestNote"
            :user="user"
            :meetings="meetings"
            @openNote="openNote"
          />
        </v-card>
      </v-col>
    </v-row>
    <v-row
      class="px-4"
      v-if="
        (!loading && !permissions.goals) ||
        (permissions.goals && !primary && (!goalUsers || !goalUsers.length))
      "
    >
      <v-col class="py-0" cols="6">
        <v-card class="pa-4">
          <v-card-text>
            <h2 class="base--text font-weight-medium">Manage your profile</h2>
          </v-card-text>
          <v-card-text class="pb-0">
            <p class="base--text">
              Keep your profile up to date! Enter simple resume data to help us
              match you with classes and opportunities.
            </p>
          </v-card-text>
          <v-card-text class="pt-0">
            <v-btn
              small
              dark
              color="grape"
              @click="$router.push({ name: 'profile' })"
            >
              <span>Update Profile</span>
              <v-img src="@/assets/svgs/arrow.svg" width="20" class="ml-2" />
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <template v-if="permissions.goals">
      <v-row class="px-4 pb-7">
        <v-col cols="12" v-if="!loading && goalUsers.length" class="py-0">
          <v-card height="100%" class="pa-4">
            <Goal
              :goal="goal"
              :goal-user="goalUsers[0]"
              :count="goalUsers.length - 1"
              :outcomes="outcomes"
              :outcomes-details="outcomesDetails"
              :outcome-users="outcomeUsers"
              :enriched-goal-user="enrichedGoalUser"
              @goToGoal="goToGoal"
            />
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="loading" class="px-4">
        <v-col>
          <v-skeleton-loader justify="center" max-width="50%" type="card" />
        </v-col>
      </v-row>
      <v-row v-else-if="primary" id="primary_actions" justify="center">
        <v-col class="px-8 py-0">
          <Primary :pathway="primary" @primaryClicked="primaryClicked" />
        </v-col>
      </v-row>
      <v-row v-else class="px-4 mt-0">
        <v-col md="6" class="col-12 pr-md-0">
          <NoPrimary :hasPathways="!!pathways.length" />
        </v-col>
      </v-row>
    </template>
    <v-component
      :is="currentDialog.component"
      v-bind="currentDialog.props"
      @cancel="resetCurrentDialog"
    />
    <schedule :items="schedule" :user="user" />
    <!-- <schedule
      v-if="showSchedule && meetings && meetings.length"
      :items="meetings"
      :user="user"
    /> -->
  </v-container>
</template>

<script>
import Personal from '@/components/dashboard/Personal';
import Goal from '@/components/dashboard/Goal';
import NoPrimary from '@/components/dashboard/NoPrimary';
import Primary from '@/components/dashboard/Primary';
import Secondary from '@/components/dashboard/Secondary';
import Schedule from '@/components/dashboard/Schedule';
import NoteDialog from '@/components/note/NoteDialog';
import { mapActions, mapState, mapGetters } from 'vuex';
import pathway from '@/helpers/pathway';
import moduleHelpers from '@/helpers/moduleHelpers';
import { get, find } from 'lodash';
export default {
  components: { Personal, Goal, NoPrimary, Primary, Secondary, Schedule },
  name: 'Dashboard',
  data: () => {
    return {
      currentDialog: {
        component: false,
        props: {},
      },
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      pathways: (state) => state.pathways.pathways,
      goals: (state) => state.goals.goals,
      goalUsers: (state) => state.goals.goalUsers,
      outcomes: (state) => state.outcomes.outcomes,
      outcomesDetails: (state) => state.outcomes.outcomesDetails,
      outcomeUsers: (state) => state.outcomes.outcomeUsers,
      pendingEnrichedGoalUsers: (state) => state.goals.pendingEnrichedGoalUsers,
      completedEnrichedGoalUsers: (state) =>
        state.goals.completedEnrichedGoalUsers,
    }),
    ...mapGetters({
      latestNote: 'notes/latestNoteWithDomainContent',
      primary: 'pathways/primary',
      meetings: 'meetings/futureMeetings',
      permissions: 'user/permissions',
      schedule: 'meetings/schedule',
    }),
    loading: function () {
      return (
        !this.outcomesDetails || !this.pathways || !this.goals || !this.outcomes
      );
    },
    goal: function () {
      return this.goals.find(
        (goal) => goal.goal_uuid === this.enrichedGoalUser.goal_uuid
      );
    },
    enrichedGoalUser: function () {
      if (this.pendingEnrichedGoalUsers.length) {
        return this.getEnrichedGoalUser(this.pendingEnrichedGoalUsers);
      }
      return this.getEnrichedGoalUser(this.completedEnrichedGoalUsers);
    },
    // showSchedule: function () {
    //   return (
    //     this.permissions.meetings ||
    //     this.permissions.classes ||
    //     this.permissions.community
    //   );
    // },
  },
  created() {
    this.fetchMeetings();
  },
  methods: {
    ...mapActions({
      fetchMeetings: 'meetings/fetchMeetings',
      addPathwayUser: 'pathways/addPathwayUser',
      replacePathwayUser: 'pathways/replacePathwayUser',
      addModuleUser: 'pathways/addModuleUser',
      replaceModuleUser: 'pathways/replaceModuleUser',
    }),
    goToGoal(event) {
      const goalUser = find(this.goalUsers, (gu) => gu.goal_uuid === event);
      this.$router.push({
        name: 'goal-details',
        params: { goal_user_uuid: goalUser.goal_user_uuid },
      });
    },
    resetCurrentDialog() {
      this.currentDialog = {
        component: false,
        props: {},
      };
    },
    openNote() {
      this.resetCurrentDialog();
      this.currentDialog.props = {
        cancelActionName: 'cancel-note-click',
        note: this.latestNote,
        persistent: true,
        width: 800,
      };
      this.currentDialog.component = NoteDialog;
    },
    getEnrichedGoalUser(enrichedGoalUsers) {
      return enrichedGoalUsers.find((enrichedGoalUser) =>
        this.goalUsers.find(
          (goalUser) =>
            goalUser.goal_user_uuid === enrichedGoalUser.goal_user_uuid
        )
      );
    },
    async primaryClicked(event) {
      let { pathway_user_uuid, module_user_uuid } = event;
      let data, status;
      if (!event.pathway_user_uuid) {
        try {
          ({ data, status } = await pathway.createPathwayUser(
            event.pathway_uuid
          ));
          if (status == 201) {
            ({ data } = await pathway.startPathwayUser(data.pathway_user_uuid));
            this.addPathwayUser(data);
            pathway_user_uuid = data.pathway_user_uuid;
          }
        } catch (err) {}
      } else if (event.pathway_user_status === 'created') {
        ({ data } = await pathway.startPathwayUser(pathway_user_uuid));
        this.replacePathwayUser(data);
      } else if (event.pathway_user_status === 'stopped') {
        ({ data } = await pathway.resumePathwayUser(pathway_user_uuid));
        this.replacePathwayUser(data);
      }
      if (!event.module_user_uuid) {
        try {
          ({ data, status } = await moduleHelpers.createModuleUser(
            event.module_uuid,
            pathway_user_uuid
          ));
          if (status == 201) {
            ({ data } = await moduleHelpers.startModuleUser(
              data.module_user_uuid
            ));
            this.addModuleUser(data);
            module_user_uuid = data.module_user_uuid;
          }
        } catch (err) {}
      } else if (event.module_user_status === 'created') {
        ({ data } = await moduleHelpers.startModuleUser(module_user_uuid));
        this.replaceModuleUser(data);
      } else if (event.module_user_status === 'stopped') {
        ({ data } = await moduleHelpers.resumeModuleUser(module_user_uuid));
        this.replaceModuleUser(data);
      }
      /*
      this will route to the module regardless of the success of the above requests.
      we may want to only route there if they succeed. user could be viewing/working
      on the module with no module_user...
      */
      this.routeToModule(
        event.module_uuid,
        module_user_uuid,
        event.pathway_uuid,
        pathway_user_uuid
      );
    },
    routeToModule(
      module_uuid,
      module_user_uuid,
      pathway_uuid,
      pathway_user_uuid
    ) {
      const current_pathway = find(this.pathways, { pathway_uuid });
      const sequence_items = get(current_pathway, 'sequence_items', []);
      const current_module = find(sequence_items, { module_uuid });
      const current_module_user = find(current_module.module_users, {
        module_user_uuid,
      });
      this.$router.push({
        name: 'module-content',
        params: {
          pathway_uuid,
          pathway_user_uuid,
          module_uuid: module_uuid,
          module: current_module,
          module_user: current_module_user,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.alt-row {
  gap: 12px;
  justify-content: space-between;
}
.alt-card {
  flex-grow: 1;
}
</style>
