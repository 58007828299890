import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"pa-8",attrs:{"id":"module","fluid":""}},[(_vm.module)?_c(VRow,[_c(VCol,{attrs:{"md":"12"}},[_c('TrackedButton',{attrs:{"buttonText":"Back to Pathway","color":"primary","name":"back-to-pathway-click","small":"","textStyle":""},on:{"click":function($event){return _vm.$router.push({
            name: 'pathway-content',
            params: {
              pathway_uuid: _vm.$route.params.pathway_uuid,
            },
          })}}})],1)],1):_vm._e(),(!_vm.hideModule)?_c(VRow,[_c(VCol,{attrs:{"md":"10"}},[_c('h1',{staticClass:"head_text"},[_vm._v(" "+_vm._s(_vm.module.strapi_data.name)+" ")])]),(!_vm.module_user || _vm.module_user.status === 'completed')?_c(VCol,[_c(VBtn,{attrs:{"color":"success","disabled":""}},[_c(VIcon,{staticClass:"mr-2",attrs:{"color":"white"}},[_vm._v("mdi-check")]),_vm._v(" Module Completed")],1)],1):_c(VCol,{attrs:{"md":"1"}},[_c('TrackedButton',{attrs:{"buttonText":"Complete Module","color":"success","name":"complete-module-click"},on:{"click":function($event){_vm.completeModuleDialog = true}}})],1),_c(VCol,{attrs:{"md":"12"}},[_c(VRow,_vm._l((_vm.module.strapi_data.content),function(content_block,i){return _c(VCol,{key:i,attrs:{"md":"12"}},[(content_block.__component === 'content.rich-text')?_c('RichText',{attrs:{"content":content_block}}):(
              content_block.__component === 'content.video' &&
              content_block.oembed
            )?_c('OEmbed',{attrs:{"content":content_block}}):_vm._e(),(content_block.__component === 'content.slider')?_c('Slider',{attrs:{"content":content_block}}):_vm._e(),(content_block.__component === 'content.file')?_c('StrapiFile',{attrs:{"content":content_block}}):_vm._e(),(content_block.__component === 'content.questionnaire-form')?_c('SurveyForm',{attrs:{"currentData":_vm.module_user.data ? _vm.module_user.data.submission : null,"currentSurvey":content_block.surveyjs},on:{"submit":_vm.submitSurveyJS}}):_vm._e()],1)}),1)],1)],1):_vm._e(),_c('ConfirmDialog',{attrs:{"cancelActionName":"cancel-complete-module-click","confirmationText":"Are you sure you have completed this module?","persistent":"","submitActionName":"submit-complete-module-click","submitActionText":"confirm-complete-module-click","title":"Complete Module","toggle":_vm.completeModuleDialog},on:{"cancel":function($event){_vm.completeModuleDialog = false},"submit":function($event){return _vm.completeModule()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }