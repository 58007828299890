export const permissions = {
  goals: ['reach', 'medicaid', 'daivergent_staff'],
  modules: ['reach', 'daivergent_staff'],
  pathways: ['reach', 'daivergent_staff'],
  jobs: ['reach', 'daivergent_staff'],
  // meetings: ['vr', 'reach', 'daivergent_staff'],
  classes: ['reach', 'medicaid', 'daivergent_staff'],
  community: ['reach', 'medicaid', 'daivergent_staff'],
  // if required to have two or more tags
  // permission: ['a and c', 'candb'],
};

export const getPermissionsObj = (tags) => {
  const userPermissions = {};
  Object.keys(permissions).forEach((permission) => {
    // if required to have two or more tags
    // const andLogic =  permissions[permission].filter((item) => item.includes(' and '));
    // if (andLogic.length) {
    //   const tagsNames = tags.map((tag) => tag.name);
    //   const checker = andLogic.find((item) =>
    //     item.split(' and ').every((tag) => tagsNames.includes(tag))
    //   );
    //   userPermissions[permission] = !!checker || false;
    // } else
    userPermissions[permission] = tags.some((tag) =>
      permissions[permission].includes(tag.name)
    );
  });
  return userPermissions;
};
