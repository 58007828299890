<template>
  <v-container id="module" fluid class="pa-8">
    <v-row v-if="module">
      <v-col md="12">
        <TrackedButton
          @click="
            $router.push({
              name: 'pathway-content',
              params: {
                pathway_uuid: $route.params.pathway_uuid,
              },
            })
          "
          buttonText="Back to Pathway"
          color="primary"
          name="back-to-pathway-click"
          small
          textStyle
        />
      </v-col>
    </v-row>
    <v-row v-if="!hideModule">
      <v-col md="10">
        <h1 class="head_text">
          {{ module.strapi_data.name }}
        </h1>
      </v-col>
      <v-col v-if="!module_user || module_user.status === 'completed'">
        <v-btn color="success" disabled>
          <v-icon class="mr-2" color="white">mdi-check</v-icon>
          Module Completed</v-btn
        >
      </v-col>
      <v-col md="1" v-else>
        <TrackedButton
          @click="completeModuleDialog = true"
          buttonText="Complete Module"
          color="success"
          name="complete-module-click"
        />
      </v-col>
      <v-col md="12">
        <v-row>
          <v-col
            md="12"
            v-for="(content_block, i) in module.strapi_data.content"
            :key="i"
          >
            <RichText
              :content="content_block"
              v-if="content_block.__component === 'content.rich-text'"
            ></RichText>
            <OEmbed
              :content="content_block"
              v-else-if="
                content_block.__component === 'content.video' &&
                content_block.oembed
              "
            ></OEmbed>
            <Slider
              :content="content_block"
              v-if="content_block.__component === 'content.slider'"
            ></Slider>
            <StrapiFile
              :content="content_block"
              v-if="content_block.__component === 'content.file'"
            ></StrapiFile>
            <SurveyForm
              :currentData="
                module_user.data ? module_user.data.submission : null
              "
              :currentSurvey="content_block.surveyjs"
              @submit="submitSurveyJS"
              v-if="content_block.__component === 'content.questionnaire-form'"
            ></SurveyForm>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <ConfirmDialog
      @cancel="completeModuleDialog = false"
      @submit="completeModule()"
      cancelActionName="cancel-complete-module-click"
      confirmationText="Are you sure you have completed this module?"
      persistent
      submitActionName="submit-complete-module-click"
      submitActionText="confirm-complete-module-click"
      title="Complete Module"
      :toggle="completeModuleDialog"
    />
  </v-container>
</template>

<script>
import { chain, filter, find, get } from 'lodash';
import { mapActions, mapState } from 'vuex';
import RichText from '@/components/strapi/RichText.vue';
import OEmbed from '@/components/strapi/OEmbed.vue';
import Slider from '@/components/strapi/Slider.vue';
import StrapiFile from '@/components/strapi/StrapiFile.vue';
import TrackedButton from '@/components/TrackedButton.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import SurveyForm from '@/components/SurveyForm';
import pathway from '@/helpers/pathway';
import moduleHelpers from '@/helpers/moduleHelpers';

export default {
  components: {
    ConfirmDialog,
    RichText,
    OEmbed,
    Slider,
    StrapiFile,
    SurveyForm,
    TrackedButton,
  },
  data() {
    return {
      completeModuleDialog: false,
    };
  },
  computed: {
    ...mapState({
      pathways: (state) => state.pathways.pathways,
    }),

    hideModule: function () {
      return (
        !this.pathway && !this.pathway_user && !this.module && !this.module_user
      );
    },

    pathway: function () {
      const { pathway_uuid } = this.$route.params;
      const pathways = get(this, 'pathways', []);
      return find(pathways, (p) => p.pathway_uuid === pathway_uuid);
    },

    pathway_user: function () {
      const pathway_users = get(this.pathway, 'pathway_users', []);
      if (!pathway_users.length && this.pathway) {
        this.createPathwayUser(this.pathway.pathway_uuid);
      }
      return chain(pathway_users).sortBy('status_timestamp').last().value();
    },

    module: function () {
      const { module_uuid } = this.$route.params;
      const pathwaySequence = get(this, 'pathway.sequence_items', []);
      return find(pathwaySequence, { module_uuid });
    },

    module_user: function () {
      const module_users = get(this.module, 'module_users', []);
      if (!module_users.length && this.module) {
        this.createModuleUser(this.module.module_uuid);
      }
      return chain(module_users).sortBy('status_timestamp').last().value();
    },
  },
  methods: {
    ...mapActions({
      addPathwayUser: 'pathways/addPathwayUser',
      replacePathwayUser: 'pathways/replacePathwayUser',
      addModuleUser: 'pathways/addModuleUser',
      replaceModuleUser: 'pathways/replaceModuleUser',
    }),

    async createPathwayUser(pathway_uuid) {
      const { status } = await pathway.createPathwayUser(pathway_uuid);
      if (status == 201) {
        const { data } = await pathway.startPathwayUser(data.pathway_user_uuid);
        this.addPathwayUser(data);
      }
    },

    async createModuleUser(module_uuid) {
      const { status } = await moduleHelpers.createModuleUser(module_uuid);
      if (status == 201) {
        const { data } = await pathway.startPathwayUser(data.module_user_uuid);
        this.addModuleUser(data);
      }
    },

    async getModuleData(module_uuid) {
      const vm = this;
      const pathwaySequence = get(vm, 'pathway.sequence_items', []);
      vm.module = find(pathwaySequence, { module_uuid });
      const module_users = get(vm.module, 'module_users', []);
      if (module_users === []) {
        const { status } = await moduleHelpers.createModuleUser(
          module_uuid,
          vm.pathway_user.pathway_user_uuid
        );
        if (status == 201) {
          const { data } = await moduleHelpers.startModuleUser(
            data.module_user_uuid
          );
          vm.addModuleUser(data);
        }
      } else {
        vm.module_user = chain(module_users)
          .sortBy('status_timestamp')
          .last()
          .value();
      }
    },

    async completeModule() {
      const vm = this;
      if (vm.module_user) {
        try {
          await moduleHelpers
            .completeModuleUser(vm.module_user.module_user_uuid)
            .then(({ data, status }) => {
              if (status === 200) {
                vm.completeModuleDialog = false;
                vm.module_user = data;
                vm.replaceModuleUser(data);
                const sequenceModules = filter(
                  vm.pathway.sequence,
                  (i) => !!i.module
                );
                if (
                  parseInt(data.module_users_completed) ===
                  sequenceModules.length
                ) {
                  pathway
                    .completePathwayUser(vm.pathway_user.pathway_user_uuid)
                    .then(({ data }) => vm.replacePathwayUser(data));
                }
              }
            });
        } catch (err) {
          // console.error(err);
        }
      } else {
        await vm.getModuleData(vm.$route.params.module_uuid);
        vm.completeModule();
      }
    },

    async submitSurveyJS(data) {
      const vm = this;
      const anonymous = get(this.module, 'strapi_data.anonymous') || false;
      const strapiContent = get(this.module, 'strapi_data.content', []);
      let grade;
      const gradingKey = get(
        find(strapiContent, (c) => c.grading_key),
        'grading_key'
      );
      if (gradingKey) {
        await moduleHelpers
          .gradeModuleUser({
            submission: data.data,
            grading_key: gradingKey,
          })
          .then(({ data }) => {
            grade = data.grade;
          });
      }
      await moduleHelpers
        .updateModuleUser({
          module_user_uuid: this.module_user.module_user_uuid,
          module_uuid: vm.$route.params.module_uuid,
          anonymous: anonymous,
          data: { submission: data.data, grade: grade },
        })
        .then(({ data }) => {
          vm.module_user = data;
          vm.replaceModuleUser(data);
        });
    },
  },
};
</script>
<style scoped>
.head_text {
  word-break: break-word;
}
</style>
