<template>
  <div class="full-height">
    <template
      v-if="
        pathway.strapi_data &&
        pathway.strapi_data.cards[0] &&
        pathway.strapi_data.cards[0].image
      "
    >
      <card-with-image
        :card="pathway.strapi_data.cards[0]"
        :pathway="pathway"
        v-on:start-pathway-user="startAndGoToPathwayUser($event)"
        v-on:continue-pathway-user="goToPathwayUser($event)"
        v-on:create-pathway-user="createAndGoToPathwayUser($event)"
        v-on:resume-pathway-user="resumeAndGoToPathwayUser($event)"
      />
    </template>
    <template v-else-if="pathway.strapi_data && pathway.strapi_data.cards[0]">
      <card-no-image
        :card="pathway.strapi_data.cards[0]"
        :pathway="pathway"
        v-on:start-pathway-user="startAndGoToPathwayUser($event)"
        v-on:continue-pathway-user="goToPathwayUser($event)"
        v-on:create-pathway-user="createAndGoToPathwayUser($event)"
        v-on:resume-pathway-user="resumeAndGoToPathwayUser($event)"
      ></card-no-image>
    </template>
    <template v-else-if="pathway.strapi_data">
      <fallback-card :card="pathway.strapi_data.cards[0]" :pathway="pathway" />
    </template>
  </div>
</template>

<script>
import CardWithImage from '@/components/strapi/CardWithImage.vue';
import CardNoImage from '@/components/strapi/CardNoImage.vue';
import FallbackCard from '@/components/strapi/FallbackCard.vue';
import { find } from 'lodash';
import { mapActions, mapState } from 'vuex';
import pathway from '@/helpers/pathway';

export default {
  name: 'PathwayCard',
  props: ['pathway'],
  components: {
    CardWithImage,
    CardNoImage,
    FallbackCard,
  },
  computed: {
    ...mapState({
      pathways: (state) => state.pathways.pathways,
    }),
  },
  methods: {
    ...mapActions({
      addPathwayUser: 'pathways/addPathwayUser',
      replacePathwayUser: 'pathways/replacePathwayUser',
    }),
    goToPathwayUser(event) {
      this.routeToPathway(event.pathway_uuid, event.pathway_user_uuid);
    },
    routeToPathway(pathway_uuid, pathway_user_uuid) {
      const current_pathway = find(this.pathways, { pathway_uuid });
      const current_pathway_user = find(current_pathway.pathway_users, {
        pathway_user_uuid,
      });
      this.$router.push({
        name: 'pathway-content',
        params: {
          pathway_uuid: current_pathway.pathway_uuid,
          pathway: current_pathway,
          pathway_user: current_pathway_user,
        },
      });
    },
    async resumeAndGoToPathwayUser(event) {
      try {
        const { data } = await pathway.resumePathwayUser(
          event.pathway_user_uuid
        );
        this.replacePathwayUser(data);
        this.routeToPathway(event.pathway_uuid, event.pathway_user_uuid);
      } catch (err) {}
    },
    async createAndGoToPathwayUser(event) {
      try {
        const { status, data } = await pathway.createPathwayUser(
          event.pathway_uuid
        );
        if (status == 201) {
          const { data: startData } = await pathway.startPathwayUser(
            data.pathway_user_uuid
          );
          this.addPathwayUser(startData);
          this.routeToPathway(event.pathway_uuid, data.pathway_user_uuid);
        }
      } catch (err) {}
    },
    async startAndGoToPathwayUser(event) {
      try {
        const { data } = await pathway.startPathwayUser(
          event.pathway_user_uuid
        );
        this.replacePathwayUser(data);
        this.routeToPathway(event.pathway_uuid, event.pathway_user_uuid);
      } catch (err) {}
    },
  },
};
</script>

<style lang="scss" scoped>
.full-height {
  height: 100%;
}
</style>
