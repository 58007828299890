<template>
  <v-card class="pa-4" height="100%">
    <v-card-title primary-title class="pb-0">
      <h1 class="head_text">Social Groups</h1>
    </v-card-title>
    <v-card-text v-if="pathName === '/'">
      <v-row class="pt-6">
        <v-col
          cols="12"
          xs="12"
          sm="3"
          md="2"
          class="text-center"
          justify="center"
        >
          <v-img
            src="@/img/card-images/social-groups.svg"
            max-width="200"
            contain
          />
        </v-col>
        <v-col cols="12" xs="12" sm="9" md="10">
          <v-row>
            <p class="subheading">
              {{ cardText }}
            </p>
          </v-row>
          <v-row class="pt-2">
            <p>
              <TrackedButton
                @click="$router.push({ name: 'socialgroups' })"
                buttonText="Social Groups"
                color="primary"
                icon="mdi-account-group"
                iconClass="mr-2"
                iconDark
                name="social-groups-card-click"
                rounded
              />
            </p>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text v-else>
      <p>
        <v-img
          src="@/img/card-images/social-groups.svg"
          width="300"
          height="300"
          contain
        />
      </p>
      <p>
        {{ cardText }}
      </p>
      <p>
        <TrackedButton
          @click="$router.push({ name: 'socialgroups' })"
          buttonText="Social Groups"
          color="primary"
          icon="mdi-account-group"
          iconClass="mr-2"
          iconDark
          name="social-groups-card-click"
          rounded
        />
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
import TrackedButton from '@/components/TrackedButton.vue';
export default {
  name: 'SocialGroupsCard',
  components: { TrackedButton },
  data() {
    return {
      cardText:
        'Sign up for Daivergent Social Groups where you can participate in moderated online social gatherings with your peers.',
      pathName: null,
    };
  },

  created() {
    const vm = this;
    vm.pathName = window.location.pathname;
  },
};
</script>

<style scoped>
.icon-adjust {
  vertical-align: middle;
  margin-right: 10px;
}

.head_text {
  word-break: break-word;
}
</style>
