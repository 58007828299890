<template>
  <v-row class="pt-4">
    <template v-for="(outcome, i) in outcomes">
      <v-col
        md="6"
        class="col-12"
        v-if="!isOutcomePathway(outcome)"
        :key="`supporting${i}`"
      >
        <OutcomeCard
          :outcome="getOutcomesDetails(outcome)"
          :qualifiers-list="getQualifiers(outcome, goalUserInterventions)"
          :completed="completedOutcomesList.includes(outcome)"
        />
      </v-col>
      <template v-else>
        <v-col
          md="6"
          class="col-12"
          v-for="(pathway, i) in outcomePathways"
          :key="`pathway${i}`"
        >
          <PathwayCard :pathway="pathway" />
        </v-col>
      </template>
    </template>
  </v-row>
</template>

<script>
import OutcomeCard from './OutcomeCard';
import PathwayCard from './PathwayCard';
import { mapState } from 'vuex';
export default {
  name: 'OutcomesList',
  components: { OutcomeCard, PathwayCard },
  props: [
    'outcomes',
    'outcomesDetails',
    'completedPathways',
    'completed',
    'goalUserInterventions',
    'completedOutcomesList',
    'outcomesList',
  ],
  computed: {
    ...mapState({
      pathways: (state) => state.pathways.pathways,
    }),
    outcomePathways() {
      return this.pathways.filter((pathway) =>
        this.outcomesList.pathways.list.includes(pathway.pathway_uuid)
      );
    },
  },
  methods: {
    getOutcomesDetails(outcome_uuid) {
      return this.outcomesDetails.find(
        (outcome) => outcome.outcome_uuid === outcome_uuid
      );
    },
    getQualifiers(outcome, interventions) {
      const result = interventions.find((intervention) => {
        if (Object.keys(intervention).includes(outcome)) return true;
        else {
          return intervention.find((item) =>
            Object.keys(item).includes(outcome)
          );
        }
      });
      return Object.keys(result).includes(outcome) ? result : result[0];
    },
    isOutcomePathway(outcome) {
      return this.getOutcomesDetails(outcome)
        .name.toLowerCase()
        .includes('pathway');
    },
  },
};
</script>
