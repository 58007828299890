<template>
  <v-row dense align="stretch" align-content="stretch">
    <v-col>
      <v-card class="primary_pathway__card bgimage">
        <v-row>
          <v-col v-if="currentModule" class="px-6">
            <v-row>
              <v-col class="pa-0 flex-grow-0 flex-shrink-1">
                <v-img
                  v-if="currentModuleImage"
                  :src="currentModuleImage.url"
                  :alt="currentModuleImage.name"
                  width="200"
                  style="object-fit: cover"
                />
              </v-col>
              <v-col class="d-flex flex-column justify-space-around px-8 py-0">
                <h4 class="base--text font-weight-medium">
                  <span
                    class="orange--text text-caption font-weight-medium mr-2"
                  >
                    Step {{ pathwayStep }} of {{ pathwayStepTotal }}
                  </span>
                  {{ pathway.strapi_data.cards[0].title }}
                </h4>
                <h3 class="base--text font-weight-medium">
                  {{ currentModule.module.strapi_data.cards[0].title }}
                </h3>
                <p class="base--text">
                  <br />
                  {{
                    currentModule.module.strapi_data.cards[0].short_description
                  }}
                </p>
                <div>
                  <v-btn
                    small
                    color="primary"
                    @click="cardClicked()"
                    :disabled="currentModule.buttonDisabled"
                  >
                    {{ currentModule.buttonText }}
                    <v-img
                      src="@/assets/svgs/arrow.svg"
                      width="20"
                      class="ml-2"
                    />
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            v-if="nextModule"
            md="3"
            cols="12"
            class="primary_pathway__section"
          >
            <h5>Next Module</h5>
            <h4>{{ nextModule.strapi_data.cards[0].title }}</h4>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { chain, each, get, isEmpty, findIndex, noop } from 'lodash';

export default {
  name: 'Primary',
  props: ['pathway'],
  computed: {
    currentModule: function () {
      const vm = this;

      let currentModule;

      if (isEmpty(vm.pathway.pathway_users)) {
        currentModule = {
          buttonText: 'START PATHWAY',
          eventName: 'create-pathway-user-and-module-user',
          eventData: {
            pathway_uuid: vm.pathway.pathway_uuid,
            module_uuid: get(vm, 'pathway.sequence[0].module'),
          },
          module: vm.pathway.sequence_items[0],
        };
      } else {
        each(vm.pathway.sequence_items, (sequenceItem) => {
          const latestModuleUser = vm.getLatestEntityUser(
            sequenceItem.module_users
          );
          const latestPathwayUser = vm.getLatestEntityUser(
            vm.pathway.pathway_users
          );
          if (latestModuleUser) {
            if (latestModuleUser.status == 'in_progress') {
              currentModule = {
                buttonText: 'CONTINUE MODULE',
                module: sequenceItem,
                eventData: {
                  module_uuid: sequenceItem.module_uuid,
                  module_user_uuid: latestModuleUser.module_user_uuid,
                  pathway_uuid: vm.pathway.pathway_uuid,
                  pathway_user_uuid: latestPathwayUser.pathway_user_uuid,
                  module_user_status: latestModuleUser.status,
                  pathway_user_status: latestPathwayUser.status,
                },
              };
              return false;
            } else if (latestModuleUser.status == 'stopped') {
              currentModule = {
                buttonText: 'RESUME MODULE',
                module: sequenceItem,
                eventData: {
                  module_uuid: sequenceItem.module_uuid,
                  module_user_uuid: latestModuleUser.module_user_uuid,
                  pathway_uuid: vm.pathway.pathway_uuid,
                  pathway_user_uuid: latestPathwayUser.pathway_user_uuid,
                  module_user_status: latestModuleUser.status,
                  pathway_user_status: latestPathwayUser.status,
                },
              };
              return false;
            } else if (latestModuleUser.status == 'created') {
              currentModule = {
                buttonText: 'START MODULE',
                module: sequenceItem,
                eventData: {
                  module_uuid: sequenceItem.module_uuid,
                  module_user_uuid: latestModuleUser.module_user_uuid,
                  pathway_uuid: vm.pathway.pathway_uuid,
                  pathway_user_uuid: latestPathwayUser.pathway_user_uuid,
                  module_user_status: latestModuleUser.status,
                  pathway_user_status: latestPathwayUser.status,
                },
              };
              return false;
            }
          } else {
            currentModule = {
              buttonText: 'START MODULE',
              module: sequenceItem,
              eventData: {
                module_uuid: sequenceItem.module_uuid,
                pathway_uuid: vm.pathway.pathway_uuid,
                pathway_user_uuid: latestPathwayUser.pathway_user_uuid,
                pathway_user_status: latestPathwayUser.status,
              },
            };
            return false;
          }
        });
      }

      if (!!!currentModule) {
        currentModule = {
          buttonText: 'COMPLETED',
          buttonDisabled: true,
          module: sequenceItem,
        };
      }
      return currentModule;
    },
    currentModuleImage: function () {
      if (this.currentModule) {
        return (
          get(this.currentModule, 'module.strapi_data.cards[0].image') || {
            url: 'https://daivergent-public.s3.amazonaws.com/domain/modules/module_default.svg',
            name: 'module',
          }
        );
      } else {
        return null;
      }
    },
    pathwayStep: function () {
      const vm = this;
      return (
        findIndex(
          vm.pathway.sequence,
          (s) => s.module == get(vm.currentModule, 'module.module_uuid')
        ) + 1
      );
    },
    pathwayStepTotal: function () {
      return this.pathway.sequence.length;
    },
    nextModule: function () {
      if (this.currentModule && this.pathwayStep < this.pathwayStepTotal) {
        return this.pathway.sequence_items[this.pathwayStep];
      }
      return noop();
    },
  },
  methods: {
    cardClicked: function () {
      this.$emit('primaryClicked', this.currentModule.eventData);
    },
    getLatestEntityUser(entity_users) {
      return chain(entity_users).sortBy('status_timestamp').last().value();
    },
  },
};
</script>

<style lang="scss" scoped>
.primary_pathway__card {
  padding: 2rem 1rem;
}
@media screen and (width >= 960px) {
  .primary_pathway__section {
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    align-self: center;
    &:last-of-type {
      border-right: none;
    }
  }
}

.primary_pathway--pathway-image {
  img {
    max-height: 200px;
  }
}

.main-cta {
  margin-top: 50px;
}
</style>
