<template>
  <!-- DELETE -->
  <v-container fluid>
    <v-row justify="center">
      <v-col md="6" class="text-center">
        <v-card class="pa-4">
          <p>
            <img src="@/img/verify-email.svg" width="180" class="pa-4 pb-0" />
          </p>
          <h1 class="head_text text-center">Thank you for signing up!</h1>
          <h1 class="head_text text-center">
            We just need you to verify your email address.
          </h1>
          <v-card-text>
            <p class="mt-4">
              Please check your email for an email from domain@daivergent.com
              with the subject, "Verify your email." Open that email and click
              on the "Confirm my account" link.<br /><br />
              Once you have verified, please refresh this page to continue your
              registration.
            </p>
            <p class="mt-4">
              Can't find the emails?<br />
              Contact
              <a href="mailto:help@daivergent.com">help@daivergent.com</a> to
              receive support.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'verify-email',
  data: () => ({
    loading: null,
  }),
  methods: {
    async fetchUser() {
      this.loading = true;
      setTimeout(async () => {
        const res = await this.$store.dispatch('user/fetchUser');
        // console.log(this.$store.state.user.auth_user.email_verified);
        if (this.$store.state.user.auth_user.email_verified) {
          this.$router.push({ name: 'onboarding' });
        } else {
          // tell them they should try again
        }
      }, 2000);
    },
  },
};
</script>
<style scoped>
.head_text {
  word-break: break-word;
}
</style>
