<template>
  <v-row justify="center">
    <v-col cols="12">
      <div v-html="slider"></div>
    </v-col>
  </v-row>
</template>

<script>
// import axios from 'axios';
export default {
  props: ['content'],
  data() {
    return {
      slider: '',
    };
  },
  created() {},
};
</script>

<style></style>
