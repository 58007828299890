<template>
  <v-btn
    v-on="clickListeners"
    :color="color"
    :disabled="disabled"
    :href="href"
    :icon="iconStyle"
    :large="large"
    :outlined="outlined"
    :rounded="rounded"
    :small="small"
    :text="textStyle"
    :v-scroll-to="scrollTo"
    :x-large="xLarge"
    :x-small="xSmall"
    :block="block"
  >
    <v-icon
      v-if="icon && icon.length"
      :class="iconClass"
      :dark="iconDark"
      :small="iconSmall"
      >{{ icon }}</v-icon
    >
    {{ buttonText }}
    <slot />
  </v-btn>
</template>
<script>
import axios from 'axios';

export default {
  name: 'TrackedButton',
  props: [
    'buttonText',
    'color',
    'data',
    'disabled',
    'href',
    'icon',
    'iconClass',
    'iconDark',
    'iconSmall',
    'iconStyle',
    'large',
    'name',
    'outlined',
    'rounded',
    'textStyle',
    'scrollTo',
    'small',
    'xLarge',
    'xSmall',
    'block',
  ],
  computed: {
    clickListeners: function () {
      var vm = this;
      // `Object.assign` merges objects together to form a new object
      return Object.assign(
        {},
        // We add all the listeners from the parent
        this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.
        {
          // This ensures that the component works with v-model
          click: function (event) {
            vm.trackClick();
            vm.$emit('click', event.target.value);
          },
        }
      );
    },
  },
  methods: {
    trackClick() {
      axios.post('/api/action_taken', {
        action_name: this.name,
        action_data: this.data,
        url: this.$route.path,
      });
      return true;
    },
  },
};
</script>
<style scoped></style>
