import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"pa-12"},[_c(VOverlay,{attrs:{"value":_vm.loading}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"64"}})],1),_c('div',{staticClass:"pa-12"},[_c(VBtn,{attrs:{"icon":"","absolute":"","top":"","right":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c(VIcon,[_vm._v("mdi-close")])],1),_c('div',{staticClass:"mx-4"},[_c('h2',[_vm._v(_vm._s(_vm.item.name))]),(_vm.type === 'class')?_c('p',{staticClass:"mt-8 mb-4"},[_vm._v("Select a class time.")]):_c('p',{staticClass:"mt-8 mb-4"},[_vm._v("Select a meetup time.")])]),_vm._l((_vm.item.classes),function(classItem){return _c(VCard,{key:classItem.id,attrs:{"color":"light-blue py-6 px-8 my-2"}},[_c(VRow,{staticClass:"align-center justify-space-between"},[_c('div',{staticClass:"font-weight-medium"},[_c(VRow,{staticClass:"align-center"},[_c(VCol,{staticClass:"flex-grow-0 flex-shrink-1"},[(_vm.type === 'social')?_c(VImg,{attrs:{"src":require("@/assets/svgs/Group.svg"),"width":"30"}}):_c(VImg,{attrs:{"src":require("@/assets/svgs/Skill Path Class.svg"),"width":"30"}})],1),_c(VCol,{staticClass:"flex-grow-1 flex-shrink-0"},[_vm._v(" "+_vm._s(_vm._f("formatStartEndExt")(classItem.time,classItem.duration))+" ")])],1)],1),_c(VBtn,{attrs:{"color":"dark-blue","dark":!_vm.loading,"small":"","disabled":_vm.loading},on:{"click":function($event){return _vm.$emit('register', classItem)}}},[(_vm.type === 'class')?_c('span',[_vm._v("Register")]):_c('span',[_vm._v("Sign up")]),_c(VImg,{staticClass:"ml-2",attrs:{"src":require("@/assets/svgs/arrow.svg")}})],1)],1)],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }