<template>
  <v-col cols="10" sm="10" md="4">
    <v-card rounded p-xs-2 class="pl-4 pa-4">
      <v-card-title primary-title class="justify-center pb-0">
        <h2 class="head_text">{{ listing.name }}</h2>
      </v-card-title>
      <v-card-text>
        <div class="ribbon-wrapper" v-if="listing.user_uuid === user.user_uuid">
          <div class="ribbon">Interested</div>
        </div>
        <v-container fluid grid-list-lg class="pa-0">
          <v-layout row wrap>
            <v-flex xs12>
              <p class="text-center">
                <v-img
                  :src="
                    listing.image_path ||
                    'https://storage.googleapis.com/daivergent-guilford-public/domain/listing_default.png'
                  "
                  contain
                  height="270"
                />
              </p>
              <p class="listing-title">{{ listing.title }}</p>
              <p class="subheading" style="font-size: medium">
                {{
                  listing.description.short || 'Click "See More" for details.'
                }}
              </p>
              <TrackedButton
                buttonText="See More"
                color="primary"
                :data="{ listing: listing.listing_uuid }"
                large
                name="see-more-listing-click"
                @click="$emit('showSeeMoreDialog', listing)"
              />
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>
  </v-col>
</template>
<script>
import { mapState } from 'vuex';
import TrackedButton from '@/components/TrackedButton.vue';
export default {
  props: ['listing'],
  components: { TrackedButton },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
    }),
  },
};
</script>
<style>
.listing-title {
  font-weight: bold;
  font-size: large;
}
.not-available {
  opacity: 0.4;
}

.ribbon-wrapper {
  width: 85px;
  height: 88px;
  overflow: hidden;
  position: absolute;
  top: -3px;
  left: -3px;
}
.ribbon-wrapper .ribbon {
  font: bold 14px sans-serif;
  color: #333;
  text-align: center;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  position: relative;
  padding: 7px 0;
  top: 15px;
  left: -30px;
  width: 100px;
  background-color: #36a81f;
  color: #fff;
}

.head_text {
  word-break: break-word;
}
</style>
