<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col md="10">
        <v-container fluid>
          <v-row justify="center">
            <v-col md="12">
              <h1>Page not found</h1>
            </v-col>
            <v-col sm="12">
              <v-row row wrap>
                <v-col xs="12" sm="3">
                  <p>
                    <v-img
                      src="@/img/not-found.svg"
                      alt="surveys"
                      contain
                      max-width="350"
                    />
                  </p>
                </v-col>
                <v-col xs="12" sm="9">
                  <p class="mt-3">
                    The page you're trying to visit does not exist. Please
                    navigate to a different page, or if you're experiencing
                    issues, please fill out a "Technology Issue" form or email
                    us at
                    <a href="mailto:help@daivergent.com">help@daivergent.com</a
                    >.
                  </p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'PageNotFound',
};
</script>
<style lang="scss" scoped></style>
