<template>
  <v-card class="pa-12">
    <div class="pa-12">
      <v-btn icon absolute top right @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <div class="mx-6">
        <h2>{{ item.name }}</h2>
        <p>
          You’re all set. You’ll receive an email with the
          <span v-if="type === 'class'">class</span>
          <span v-else>meetup</span> link shortly. The link becomes active 15
          minutes before the start of class.
        </p>
      </div>
      <v-card color="light-blue py-6 px-8 my-2">
        <v-row class="align-center justify-space-between">
          <div class="font-weight-medium">
            <v-row class="align-center">
              <v-col class="flex-grow-0 flex-shrink-1">
                <v-img src="@/assets/svgs/talk.svg" width="30" />
              </v-col>
              <v-col class="flex-grow-1 flex-shrink-0">
                {{ item.time | formatStartEndExt(item.duration) }}
              </v-col>
            </v-row>
          </div>
          <v-chip color="green" label>
            <v-icon color="base" small>mdi-check</v-icon>
            <span class="font-weight-medium ml-2 text-body-2">
              You’ve
              <span v-if="type === 'class'">registered</span>
              <span v-else>signed up</span>
            </span>
          </v-chip>
        </v-row>
      </v-card>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'SuccessModal',
  props: ['item', 'type'],
};
</script>
