import axios from 'axios';
import { find, get, map } from 'lodash';

const state = {
  notes: [],
};

const getters = {
  latestNoteWithDomainContent: (state) => {
    return find(state.notes, (n) => n.domainNoteElements.length);
  },
};

const actions = {
  async fetchNotes({ commit }) {
    await axios.get('/api/note').then(async ({ data }) => {
      if (data.length) {
        const form_ids = map(data, (note) => note.form_version);
        const forms = await axios.get('/api/note/forms', {
          params: { form_ids: form_ids },
        });
        map(data, (note) => {
          note.form = find(forms.data, (f) => f.id === note.form_version);
          note.domainNoteElements = getDomainElements(note);
          return note;
        });
      }
      commit('cache_notes', data);
      return data;
    });
  },
  async init({ dispatch }) {
    await dispatch('fetchNotes');
    return;
  },
};

const mutations = {
  cache_notes(_state, notes) {
    _state.notes = notes;
  },
};

function getDomainElements(note) {
  const noteFormElements = get(note, 'form.data.pages[0].elements');
  const noteData = get(note, 'submission');
  const elements = [];
  if (noteFormElements) {
    for (const e of noteFormElements) {
      if (e.domain) {
        const el = e;
        const correspondingKey = find(
          Object.keys(noteData),
          (d) => d === e.name
        );
        el.value = noteData[correspondingKey];
        elements.push(el);
      }
    }
    return elements;
  } else {
    return [];
  }
}

export const notes = {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
