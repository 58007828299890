<template>
  <v-row justify="center">
    <v-col cols="12">
      <div v-html="file"></div>
    </v-col>
  </v-row>
</template>

<script>
// import axios from 'axios';
export default {
  props: ['content'],
  data() {
    return {
      file: '',
    };
  },
  created() {
    // const file = JSON.parse(this.content.oembed);
    // this.file = file.rawData.html;
  },
};
</script>

<style></style>
