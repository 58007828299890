import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,{staticClass:"pb-0 pb-md-3"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VProgressLinear,_vm._g(_vm._b({attrs:{"value":_vm.progress,"color":_vm.settings[_vm.status].color,"height":"10"}},'v-progress-linear',attrs,false),on))]}}])},[_c('span',[_vm._v(_vm._s(_vm.settings[_vm.status].text))])])],1),_c(VIcon,{attrs:{"color":_vm.settings[_vm.status].iconColor}},[_vm._v(" "+_vm._s(_vm.settings[_vm.status].icon)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }