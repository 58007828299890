import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{class:{ 'pa-12': !_vm.datetime }},[(!_vm.datetime)?_c(VBtn,{attrs:{"icon":"","absolute":"","top":"","right":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c(VIcon,[_vm._v("mdi-close")])],1):_vm._e(),_c('div',{class:{ 'mx-6': !_vm.datetime }},[_c('h2',[_vm._v(_vm._s(_vm.meeting.subject))]),(!_vm.datetime)?_c('p',[_vm._v(" "+_vm._s(_vm.meeting.description.description)+" ")]):_vm._e()]),_c(VCard,{class:{ 'mt-4': _vm.datetime },attrs:{"color":"light-blue py-6 px-8 my-2"}},[_c(VRow,{staticClass:"align-center justify-space-between"},[_c('div',{staticClass:"font-weight-medium"},[_c(VRow,{staticClass:"align-center"},[_c(VCol,{staticClass:"flex-grow-0 flex-shrink-1"},[_c(VImg,{attrs:{"src":require("@/assets/svgs/video.svg"),"width":"30"}})],1),_c(VCol,{staticClass:"flex-grow-1 flex-shrink-0"},[_c('span',{class:{ 'text-caption': _vm.datetime }},[_vm._v(" "+_vm._s(_vm._f("formatDateExt")(_vm.meeting.scheduled_start))+" "),(!_vm.datetime)?_c('span',[_vm._v("/")]):_c('br'),_vm._v(" "+_vm._s(_vm._f("formatTime")(_vm.meeting.scheduled_start))+" - "+_vm._s(_vm._f("formatTime")(_vm.meeting.scheduled_finish))+" "+_vm._s(_vm._f("formatTimezone")(_vm.meeting.scheduled_finish))+" ")]),(_vm.isVR && _vm.isSubstitute)?_c('div',{staticClass:"text-caption mt-2"},[_c('span',[_vm._v("Your coach for this session is "+_vm._s(_vm.meeting.organizers[0].first_name)+" "+_vm._s(_vm.meeting.organizers[0].last_name))])]):_vm._e()])],1)],1),(_vm.meeting.link)?_c(VBtn,{attrs:{"color":"grape","dark":"","small":""},on:{"click":function($event){return _vm.joinMeeting()}}},[(_vm.meeting.format === 'acuity')?_c('span',[(
              _vm.meeting.metadata &&
              _vm.meeting.metadata.acuity_calendar === 'social_groups'
            )?_c('span',[_vm._v(" Join Meetup ")]):_c('span',[_vm._v("Join Class")])]):_c('span',[_vm._v("Join Meeting")]),_c(VImg,{staticClass:"ml-2",attrs:{"src":require("@/assets/svgs/arrow.svg"),"width":"20"}})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }