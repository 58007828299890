<template>
  <v-layout justify-center id="survey-form">
    <v-container grid-list-xl class="assessments-container">
      <v-card
        rounded
        p-xs-2
        class="assessment-card elevation-10 pa-2"
        v-if="survey"
      >
        <v-card-text>
          <v-container fluid grid-list-lg class="pa-0">
            <v-layout row wrap>
              <v-flex xs12>
                <survey :survey="survey" v-if="survey"></survey>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
      </v-card>
    </v-container>
  </v-layout>
</template>

<script>
import * as SurveyVue from 'survey-vue';
var Survey = SurveyVue.Survey;
Survey.cssType = 'default';

export default {
  name: 'SurveyForm',
  props: ['completedHtml', 'currentData', 'currentSurvey'],
  data() {
    return {
      survey: false,
    };
  },
  async created() {
    const vm = this;
    try {
      /* eslint-disable vue/no-mutating-props */
      if (!vm.currentSurvey.completedHtml)
        vm.currentSurvey.completedHtml = vm.completedHtml;
      vm.survey = new SurveyVue.Model(vm.currentSurvey);
      vm.survey.data = vm.currentData;

      // TODO: change functionality to check title/name of first page and set only if welcome page exists
      if (vm.survey.isLastPage) vm.survey.firstPageIsStarted = false;
      else vm.survey.firstPageIsStarted = true;

      if (vm.survey.maxTimeToFinish) {
        vm.survey.showTimerPanel = 'top';

        vm.survey.onTimerPanelInfoText.add((sender, options) => {
          const timeTaken = sender.timeSpent;
          const maxMinutes = sender.maxTimeToFinish / 60;
          options.text = `You have currently taken ${timeTaken} seconds. When ${maxMinutes} minutes (${sender.maxTimeToFinish} seconds) have passed, your answers will automatically be submitted.`;
        });

        // allows for completion/submission if there are validation errors on the current page
        vm.survey.onTimer.add((sender) => {
          if (sender.timeSpent >= sender.maxTimeToFinish - 1) {
            vm.survey.ignoreValidation = true;
          }
        });
      }

      vm.survey.onComplete.add((result) => {
        // console.log('survey completed', result);
        vm.$emit('submit', {
          data: result.data,
          time_spent: result.timeSpent,
        });
      });
    } catch (error) {
      // console.error('error', error);
    }
  },
};
</script>

<style lang="scss">
#survey-form .v-text-field__details {
  display: none !important;
}
.sv_main input[type='button'],
.sv_main button {
  background-color: #1976d1 !important;
  height: 36px;
  min-width: 64px;
  padding: 0 18px;
  border-radius: 28px;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.06em;
}
.sv_main .sv_body {
  /* border-top: 2px solid #1976d1; */
  border-top: none;
}

.sv_main .sv_p_root > .sv_row:nth-child(even) {
  background-color: #fff !important;
  border-bottom: none;
}
/* text color */
.sv_main .sv_container {
  color: #3e4042;
}
.sv_main .sv_p_root > .sv_row {
  border-bottom: none !important;
}
.container {
  display: block !important;
}
.sv_q input {
  border: solid 1px #6f6d6d !important;
  color: #111 !important;
  border-radius: 3px;
  padding: 1.2em 1em;
}
.title-left {
  min-width: 150px;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q {
  padding: 0;
}
.assessments-container .v-card__title {
  border-bottom: solid 2px rgba(0, 0, 0, 0.05);
}
.sv_nav {
  margin-top: 1em;
}
.assessments-layout {
  display: block !important;
}
@media (max-width: 1300px) {
  .sv_main .sv_container .sv_header h3 {
    font-size: 2em !important;
  }
}
.sv_main .sv_header {
  color: #1976d1;
}

.survey-title {
  background: #f7f7f7;
  padding: 10px;
}

.sv_main {
  .sv_container {
    .sv_header {
      h3 {
        font-weight: 400;
        padding: 10px 0px;
      }

      h5 {
        padding: 5px 0px;
      }
    }

    .sv_body {
      .sv_q_file {
        button.sv_q_file_remove_button {
          display: none;
        }
      }

      .sv_q {
        img {
          max-width: 100%;
        }
      }
    }
  }
}
</style>
