import {
  cloneDeep,
  lowerCase,
  map,
  replace,
  unset,
  isEmpty,
  isArray,
} from 'lodash';

export const amountCheckers = ['at_least', 'exactly', 'less_than'];
export const countCheckers = ['or', 'and'];

export const normalizeValue = (value) => {
  if (isArray(value)) {
    return map(value, (v) => normalizeValue(v));
  } else if (typeof value === 'string') {
    return replace(lowerCase(value), /\s/g, '');
  }
};

export const interventionQualifiersSatisfied = (
  gu_outcome_uuid,
  guInterventionQualifiers,
  outcomeUsers
) => {
  let filteredOutcomeUsers = outcomeUsers.filter(
    (outcomeUser) => outcomeUser.outcome_uuid === gu_outcome_uuid
  );
  const filteredQualifiers = guInterventionQualifiers.filter(
    (qualifier) => !qualifier.amount
  );
  filteredQualifiers.forEach((qualifier) => {
    const logic =
      qualifier.logic && countCheckers.includes(qualifier.logic)
        ? qualifier.logic
        : 'or';
    unset(qualifier, 'logic');
    const key = Object.keys(qualifier)[0];
    const value = normalizeValue(qualifier[key]);
    filteredOutcomeUsers = logicChecker[logic]({
      filteredOutcomeUsers,
      key,
      value,
    });
  });
  const amountQualifier = guInterventionQualifiers.find((qualifier) =>
    Object.keys(qualifier).includes('amount')
  );
  const amountLogic =
    amountQualifier && amountCheckers.includes(amountQualifier.logic)
      ? amountQualifier.logic
      : 'default';
  unset(amountQualifier, 'logic');
  const amount = amountQualifier ? Object.values(amountQualifier)[0] : null;
  return logicChecker[amountLogic]({ filteredOutcomeUsers, amount });
};

export const outcomesSplitByAmount = (outcomeUsers) => {
  // NOTE: can result in outcomeUsers with duplicate outcome_user_uuid
  const splitOutOutcomeUsers = [];
  const outcomeUsersClone = cloneDeep(outcomeUsers);
  outcomeUsersClone.forEach((outcomeUser) => {
    if (!isEmpty(outcomeUser) && outcomeUser.qualifiers) {
      const amountQualifier = outcomeUser.qualifiers.find(
        (qualifier) => !!qualifier.amount
      );
      if (amountQualifier) {
        const amount = parseInt(amountQualifier.amount);
        for (let i = 0; i < amount; i++) {
          splitOutOutcomeUsers.push(outcomeUser);
        }
      } else splitOutOutcomeUsers.push(outcomeUser);
    } else splitOutOutcomeUsers.push(outcomeUser);
  });
  return splitOutOutcomeUsers;
};

export const outcomeEnrichedGoalUsers = (
  goalUsers,
  outcomes,
  splitOutOutcomes
) => {
  if (!isEmpty(goalUsers)) {
    const enrichedGoalUsers = [];
    goalUsers.forEach((goalUser) => {
      const enrichedGoalUser = cloneDeep(goalUser);
      if (!isEmpty(enrichedGoalUser.primary_interventions)) {
        enrichInterventions({
          interventions: enrichedGoalUser.primary_interventions,
          outcomes,
          splitOutOutcomes,
        });
      }
      if (!isEmpty(enrichedGoalUser.supporting_interventions)) {
        enrichInterventions({
          interventions: enrichedGoalUser.supporting_interventions,
          outcomes,
          splitOutOutcomes,
        });
      }
      enrichedGoalUsers.push(enrichedGoalUser);
    });
    return enrichedGoalUsers;
  }
  return [];
};

export const enrichInterventions = (settings) => {
  const { interventions, outcomes, splitOutOutcomes } = settings;
  interventions.forEach((block) => {
    block.forEach((intervention) => {
      const [outcome_uuid] = Object.keys(intervention);
      intervention.outcome = outcomes.find(
        (outcome) => outcome.outcome_uuid === outcome_uuid
      );
      if (!isEmpty(splitOutOutcomes)) {
        intervention.hasQualifyingIntervention =
          interventionQualifiersSatisfied(
            outcome_uuid,
            intervention[outcome_uuid],
            splitOutOutcomes
          );
      }
    });
  });
};

export const logicChecker = {
  or: (values) => {
    const { filteredOutcomeUsers, key, value } = values;
    return filteredOutcomeUsers.filter((outcomeUser) => {
      return (
        outcomeUser.qualifiers &&
        outcomeUser.qualifiers.length &&
        outcomeUser.qualifiers.find((qualifier) => {
          let checker = false;
          for (const qualifierKey in qualifier)
            if (qualifierKey !== 'amount' && qualifierKey === key) {
              checker =
                value === normalizeValue(qualifier[qualifierKey]) ||
                value.includes(normalizeValue(qualifier[qualifierKey]));
            }
          return checker;
        })
      );
    });
  },
  and: (values) => {
    const { filteredOutcomeUsers, key, value } = values;
    const outcomeUsersAcc = [];
    let matchingValues = [];
    filteredOutcomeUsers.forEach((outcomeUser) => {
      if (outcomeUser.qualifiers && outcomeUser.qualifiers.length) {
        const matchingQualifier = outcomeUser.qualifiers.find((qualifier) => {
          let checker = false;
          for (const qualifierKey in qualifier)
            if (qualifierKey !== 'amount') checker = key === qualifierKey;
          return checker;
        });
        if (matchingQualifier) {
          matchingValues.push(normalizeValue(Object.values(matchingQualifier)));
        }
        if (value.length <= matchingValues.length) {
          outcomeUsersAcc.push(outcomeUser);
        }
      }
    });
    return outcomeUsersAcc;
  },
  at_least: (values) => {
    const { filteredOutcomeUsers, amount } = values;
    return filteredOutcomeUsers.length >= parseInt(amount);
  },
  exactly: (values) => {
    const { filteredOutcomeUsers, amount } = values;
    return filteredOutcomeUsers.length === parseInt(amount);
  },
  less_than: (values) => {
    const { filteredOutcomeUsers, amount } = values;
    return filteredOutcomeUsers.length < parseInt(amount);
  },
  default: (values) => {
    const { filteredOutcomeUsers } = values;
    return filteredOutcomeUsers.length >= 1;
  },
};
