<template>
  <div class="pa-8">
    <v-card color="rose" class="py-6 px-8">
      <v-row class="pa-4 justify-space-between">
        <v-col cols="12" md="6">
          <v-row>
            <v-col cols="10">
              <h1 class="base--text text-h3 font-weight-bold">
                Build valuable skills in Daivergent Skill Path classes.
              </h1>
            </v-col>
            <v-col cols="12">
              <p class="base--text">
                Taught by Daivergent coaches, Skill Path classes provide useful
                training for real-world situations for work, home, and life.
                Sign up for as many classes as you’d like! Once you register,
                you’ll receive a confirmation email with the virtual classroom
                link.
              </p>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="5">
          <v-spacer class="py-6"></v-spacer>
          <h4 class="base--text mt-3 mb-3">Classes at a glance</h4>
          <div class="ml-4">
            <p class="d-flex align-center mb-1">
              <v-img
                width="15"
                height="15"
                src="@/assets/svgs/check.svg"
                class="mr-3 flex-grow-0"
              />
              <span>Qualified coaches</span>
            </p>
            <p class="d-flex align-center mb-1">
              <v-img
                width="15"
                height="15"
                src="@/assets/svgs/check.svg"
                class="mr-3 flex-grow-0"
              />
              <span>All-virtual classes</span>
            </p>
            <p class="d-flex align-center mb-1">
              <v-img
                width="15"
                height="15"
                src="@/assets/svgs/check.svg"
                class="mr-3 flex-grow-0"
              />
              <span>Small group settings</span>
            </p>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'AboutClasses',
};
</script>
