<template>
  <span class="alt-span d-flex flex-column justify-space-between">
    <v-row>
      <v-col>
        <v-card-text>
          <span class="font-weight-medium">{{ goal.name }}</span>
        </v-card-text>
        <v-card-text class="pt-0 pr-6">
          <ProgressBar :progress="progress" :status="status" />
        </v-card-text>
        <v-card-text class="pt-0 pb-0" v-html="message" />
      </v-col>
    </v-row>
    <v-card-actions class="justify-end">
      <span v-if="count" class="pr-2">
        +{{ count }} {{ count === 1 ? 'other goal' : 'other goals' }}
      </span>
      <TrackedButton
        color="primary"
        buttonText="Go to Goal"
        @click="goToGoal()"
        outlined
        small
      />
    </v-card-actions>
  </span>
</template>

<script>
import TrackedButton from '@/components/TrackedButton.vue';
import ProgressBar from '@/components/goal-center/ProgressBar.vue';
export default {
  name: 'Goal',
  components: { TrackedButton, ProgressBar },
  props: [
    'goal',
    'count',
    'outcomes',
    'outcomesDetails',
    'goalUser',
    'outcomeUsers',
    'enrichedGoalUser',
  ],
  computed: {
    status: function () {
      if (this.completedPrimary.length) return 'completed';
      return 'created';
    },
    progress: function () {
      return this.completedPrimary.length ? 100 : this.getProgress();
    },
    primary: function () {
      return this.getInterventionUUID(this.goal.primary_interventions);
    },
    supporting: function () {
      return this.getInterventionUUID(this.goal.supporting_interventions);
    },
    completedSupporting: function () {
      return this.getCompletedInterventions(
        this.enrichedGoalUser.supporting_interventions
      );
    },
    completedPrimary: function () {
      return this.getCompletedInterventions(
        this.enrichedGoalUser.primary_interventions
      );
    },
    isCompleted: function () {
      return this.status === 'completed';
    },
    otherGoals: function () {
      return this.count;
    },
    message: function () {
      return this.isCompleted
        ? this.completedMessage()
        : this.inProgressMessage();
    },
    goalDescription: function () {
      return this.goal.description && this.goal.description.domain
        ? this.goal.description.domain
        : '';
    },
  },
  methods: {
    goToGoal() {
      this.$emit('goToGoal', this.goal.goal_uuid);
    },
    getInterventionUUID(interventions) {
      const list = [];
      if (interventions) {
        interventions.forEach((intervention) => list.push(...intervention));
      }
      return list;
    },
    getProgress() {
      const total = this.primary.length + this.supporting.length;
      const totalCompleted =
        this.completedPrimary.length + this.completedSupporting.length;
      return (totalCompleted / total) * 100;
    },
    getLastCompletedIntervention() {
      return this.supporting.find((intervention) =>
        this.completedSupporting.includes(intervention)
      );
    },
    getInterventionDetails(outcomeId) {
      return this.outcomesDetails.find((outcome) =>
        outcomeId.includes(outcome.outcome_uuid)
      );
    },
    completedMessage() {
      return `
        You completed this goal. Amazing! <br />
        Talk to your Coach/VSP to keep it going with some new goals.
      `;
    },
    inProgressMessage() {
      const outcome_uuid = this.getLastCompletedIntervention();
      if (!outcome_uuid) return this.goalDescription;
      const intervention = this.getInterventionDetails(outcome_uuid);
      return `You recently completed "${intervention.name}". Great Job!`;
    },
    getCompletedInterventions(interventions) {
      const completed = [];
      interventions.forEach((block) =>
        block.forEach((intervention) => {
          if (intervention && intervention.hasQualifyingIntervention) {
            completed.push(Object.keys(intervention)[0]);
          }
        })
      );
      return completed;
    },
  },
};
</script>

<style lang="scss" scoped>
.alt-span {
  height: 100%;
}
</style>
