<template>
  <span>
    <v-list-item-group
      class="pt-2 pb-2"
      v-for="(entry, index) in filteredQualifiers"
      :key="index"
    >
      <v-list-item-content class="pa-0">
        <v-list-item-title>
          <span
            class="font-weight-medium text-capitalize base--text"
            v-html="`${convertToText(entry[0])}: `"
          />
          <span v-html="convertToText(entry[1], entry[0])" class="base--text" />
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item-group>
  </span>
</template>

<script>
export default {
  name: 'QualifierCard',
  props: ['qualifier'],
  computed: {
    filteredQualifiers: function () {
      return Object.entries(this.qualifier).filter(
        (entry) => !this.isPathway(entry) && !this.isLogic(entry)
      );
    },
    logic: function () {
      const amount = this.amountLogic.includes(this.qualifier.logic)
        ? this.qualifier.logic
        : 'or';
      const list = this.listLogic.includes(this.qualifier.logic)
        ? this.qualifier.logic
        : '';
      return { amount, list };
    },
  },
  data() {
    return {
      amountLogic: ['at_least', 'exactly', 'less_than'],
      listLogic: ['or', 'and'],
    };
  },
  methods: {
    isPathway(value) {
      return value[0].toLowerCase().includes('pathway');
    },
    isLogic(value) {
      return value[0].toLowerCase().includes('logic');
    },
    convertToText(value, key) {
      if (typeof value === 'object') {
        value = value.join(` ${this.logic.list} `);
      }
      if (key === 'amount') {
        value = `${this.logic.amount} ${value}`;
      }
      return value.toString().replace(/_/g, ' ');
    },
  },
};
</script>
