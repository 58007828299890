<template>
  <v-card class="pa-0" height="100%">
    <v-card-title primary-title>
      <h1 class="head_text">{{ name }}</h1>
    </v-card-title>
    <v-card-text>
      <TrackedButton
        @click="
          module
            ? $router.push({
                name: 'module-content',
                params: {
                  pathway_uuid: pathway.pathway_uuid,
                  module: module,
                  module_uuid: module.module_uuid,
                },
              })
            : $router.push({
                name: 'pathway-content',
                params: {
                  pathway_uuid: pathway.pathway_uuid,
                  pathway: pathway,
                },
              })
        "
        :buttonText="`GO TO ${module ? 'MODULE' : 'PATHWAY'}`"
        color="primary"
        :data="
          module
            ? { module: module.module_uuid }
            : { pathway: pathway.pathway_uuid }
        "
        icon="mdi-calendar-star"
        iconClass="mr-2"
        iconDark
        :name="`${module ? 'module' : 'pathway'}-card-click`"
        rounded
      />
    </v-card-text>
  </v-card>
</template>

<script>
import TrackedButton from '@/components/TrackedButton';
export default {
  name: 'FallbackCard',
  components: { TrackedButton },
  props: ['pathway', 'module'],
};
</script>

<style scoped>
.icon-adjust {
  vertical-align: middle;
  margin-right: 10px;
}

.v-card .v-card__title h1 {
  word-break: break-word;
}

.head_text {
  word-break: break-word;
}
</style>
