<template>
  <v-card
    title="Edit Profile"
    text="Complete your profile"
    v-if="virtualResume"
  >
    <v-card-text>
      <v-flex xs12>
        <v-layout row wrap>
          <v-flex xs12 md3>
            <v-img src="@/img/resume.png" />
          </v-flex>
          <v-flex xs12 md9>
            <v-layout row wrap>
              <v-flex xs12 sm6 class="pb-0 mb-3">
                <h2 class="head_text pt-1 base--text">VIRTUAL RESUME</h2>
              </v-flex>
              <v-flex xs12 sm6 class="text-right pb-0 mb-3">
                <TrackedButton
                  buttonText="edit"
                  color="primary"
                  name="edit-virtual-resume-click"
                  outlined
                  rounded
                  small
                  @click="editing = !editing"
                  v-if="!editing"
                />
                <TrackedButton
                  buttonText="cancel"
                  class="mr-1"
                  color="primary"
                  name="cancel-virtual-resume-click"
                  rounded
                  small
                  textStyle
                  @click="editing = false"
                  v-if="editing"
                />
                <TrackedButton
                  buttonText="Save"
                  color="primary"
                  icon="mdi-content-save"
                  iconClass="mr-3"
                  iconSmall
                  name="save-vitual-resume-click"
                  rounded
                  small
                  @click="updateVirtualResume"
                  v-if="editing"
                />
              </v-flex>
            </v-layout>
            <p class="subheading-1 base--text">
              Please take a moment to fill out your virtual resume so that we
              may match you with job opportunities.<br />This information will
              remain private and will only be used for matching you with job
              opportunities.
            </p>
            <!--
            Relevant Coursework
            References
            -->
            <v-layout wrap>
              <!-- ABOUT -->
              <v-flex xs12>
                <h3 class="pb-0 base--text">About</h3>
                <v-textarea
                  v-if="editing"
                  name="summary"
                  label="summary"
                  class="base--text"
                  v-model="virtualResume.summary"
                ></v-textarea>
                <span class="base--text" v-else-if="virtualResume.summary">{{
                  virtualResume.summary
                }}</span>
                <span class="base--text" v-else>
                  * Please add your summary.
                </span>
              </v-flex>
              <!-- END ABOUT -->

              <!-- EDUCATION -->
              <v-flex xs12>
                <h3 class="pb-0 base--text" id="education">Education</h3>
                <!-- NEW EDUCATION -->
                <v-form
                  v-if="editing"
                  class="new-list-box"
                  v-model="validEducation"
                  ref="education"
                >
                  <v-text-field
                    name="school"
                    label="School Name"
                    v-model.trim="newEducation.school"
                    :rules="[(v) => !!v || 'School is required']"
                  ></v-text-field>
                  <v-select
                    :items="degreeType"
                    v-model="newEducation.degree"
                    label="Degree type"
                  ></v-select>
                  <v-layout row wrap>
                    <v-flex xs3>
                      <v-select
                        v-model="newEducation.startMonth"
                        name="month"
                        label="month"
                        :items="months"
                        :rules="[(v) => !!v || 'Start Month is required']"
                      ></v-select>
                    </v-flex>
                    <v-flex xs3>
                      <v-select
                        v-model="newEducation.startYear"
                        name="year"
                        label="year"
                        :items="workYearList"
                        :rules="[(v) => !!v || 'Start Year is required']"
                      ></v-select>
                    </v-flex>
                    <v-flex
                      v-if="!newEducation.current"
                      xs1
                      style="padding-top: 32px; padding-left: 20px"
                      >to</v-flex
                    >
                    <v-flex xs3 v-if="!newEducation.current">
                      <v-select
                        v-model="newEducation.toMonth"
                        name="month"
                        label="month"
                        :items="months"
                      ></v-select>
                    </v-flex>
                    <v-flex xs2 v-if="!newEducation.current">
                      <v-select
                        v-model="newEducation.toYear"
                        name="year"
                        label="year"
                        :items="workYearList"
                      ></v-select>
                    </v-flex>
                  </v-layout>
                  <v-checkbox
                    color="primary"
                    label="Currently Attending"
                    v-model="newEducation.current"
                  ></v-checkbox>
                  <v-flex xs12 class="text-right" v-if="!editingEducation">
                    <TrackedButton
                      buttonText="Add education"
                      color="primary"
                      icon="mdi-plus"
                      iconSmall
                      name="add-education-click"
                      outlined
                      small
                      @click="addItem('education')"
                      :disabled="!validEducation"
                    />
                  </v-flex>
                  <v-flex xs12 class="text-right" v-if="editingEducation">
                    <TrackedButton
                      buttonText="done editing"
                      color="primary"
                      name="done-editing-education-click"
                      outlined
                      small
                      :disabled="!validEducation"
                      @click="
                        newEducation = {
                          current: true,
                        };
                        editingEducation = false;
                        $refs.education.resetValidation();
                      "
                    />
                  </v-flex>
                </v-form>
                <!-- END NEW EDUCATION -->
                <div v-if="virtualResume.education.length">
                  <div
                    v-for="(education, index) in sortedEducation"
                    :key="index"
                  >
                    <div class="list-box base--text">
                      {{ education.school }} -
                      <span
                        >{{ education.startMonth }}/{{
                          education.startYear
                        }}</span
                      >
                      <span
                        v-if="
                          education.toMonth &&
                          education.toYear &&
                          !education.current
                        "
                      >
                        to {{ education.toMonth }}/{{ education.toYear }}</span
                      >
                      <br />{{ education.degree }}
                      <br />
                      <div v-if="editing">
                        <TrackedButton
                          buttonText="Edit"
                          class="mr-3"
                          color="grey"
                          name="edit-education-click"
                          outlined
                          x-small
                          @click="editItem(education, 'newEducation')"
                          :scrollTo="{ el: '#education' }"
                        />
                        <TrackedButton
                          buttonText="REMOVE"
                          color="grey"
                          name="remove-education-click"
                          outlined
                          textStyle
                          x-small
                          @click="virtualResume.education.splice(index, 1)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <span v-if="!virtualResume.education.length" class="base--text">
                  * No education added yet.
                </span>
              </v-flex>
              <!-- END EDUCATION -->

              <!-- EXPERIENCEE -->
              <v-flex xs12>
                <h3 class="pb-0 base--text" id="experience">Experience</h3>
                <!-- NEW EXPERIENCE -->
                <v-form
                  v-if="editing"
                  class="new-list-box"
                  v-model="validExperience"
                  ref="experience"
                >
                  <v-text-field
                    name="company"
                    label="Company"
                    v-model.trim="newExperience.company"
                    :rules="[(v) => !!v || 'Company is required']"
                  ></v-text-field>
                  <v-text-field
                    name="title"
                    label="Title"
                    v-model.trim="newExperience.title"
                    :rules="[(v) => !!v || 'Title is required']"
                  ></v-text-field>
                  <v-textarea
                    name="description"
                    label="Description"
                    v-model="newExperience.description"
                  ></v-textarea>
                  <v-layout row wrap>
                    <v-flex xs3>
                      <v-select
                        v-model="newExperience.startMonth"
                        name="month"
                        label="month"
                        :items="months"
                        :rules="[(v) => !!v || 'Start Month is required']"
                      ></v-select>
                    </v-flex>
                    <v-flex xs3>
                      <v-select
                        v-model="newExperience.startYear"
                        name="year"
                        label="year"
                        :items="workYearList"
                        :rules="[(v) => !!v || 'Start Year is required']"
                      ></v-select>
                    </v-flex>
                    <v-flex
                      v-if="!newExperience.current"
                      xs1
                      style="padding-top: 32px; padding-left: 20px"
                      >to</v-flex
                    >
                    <v-flex xs3 v-if="!newExperience.current">
                      <v-select
                        v-model="newExperience.toMonth"
                        name="month"
                        label="month"
                        :items="months"
                      ></v-select>
                    </v-flex>
                    <v-flex xs2 v-if="!newExperience.current">
                      <v-select
                        v-model="newExperience.toYear"
                        name="year"
                        label="year"
                        :items="workYearList"
                      ></v-select>
                    </v-flex>
                  </v-layout>
                  <v-checkbox
                    color="primary"
                    label="This is a current postion"
                    v-model="newExperience.current"
                  ></v-checkbox>
                  <v-checkbox
                    class="mt-0 pt-0"
                    color="primary"
                    label="Volunteer Experience"
                    v-model="newExperience.volunteer"
                  ></v-checkbox>
                  <v-flex xs12 class="text-right" v-if="!editingExperience">
                    <TrackedButton
                      buttonText="Add experience"
                      color="primary"
                      icon="mdi-plus"
                      iconSmall
                      name="add-experience-click"
                      outlined
                      small
                      @click="addItem('experience')"
                      :disabled="!validExperience"
                    />
                  </v-flex>
                  <v-flex xs12 class="text-right" v-if="editingExperience">
                    <TrackedButton
                      buttonText="Done Editing"
                      color="primary"
                      name="done-editing-experience-click"
                      outlined
                      small
                      :disabled="!validExperience"
                      @click="
                        newExperience = {
                          current: true,
                        };
                        editingExperience = false;
                        $refs.experience.resetValidation();
                      "
                    />
                  </v-flex>
                </v-form>
                <!-- END NEW EXPERIENCE -->
                <div v-if="virtualResume.experience.length">
                  <div
                    v-for="(experience, index) in sortedExperience"
                    :key="index"
                  >
                    <div class="list-box base--text">
                      {{ experience.company }}<br />
                      <strong
                        >{{ experience.title }}
                        <span v-if="experience.volunteer"
                          >( Volunteer )</span
                        ></strong
                      >
                      -
                      <span>
                        {{ experience.startMonth }}/{{ experience.startYear }}
                      </span>
                      <span
                        v-if="
                          experience.toMonth &&
                          experience.toYear &&
                          !experience.current
                        "
                      >
                        to {{ experience.toMonth }}/{{
                          experience.toYear
                        }}</span
                      >
                      <br />
                      <span>
                        {{ experience.description }}
                      </span>
                      <br />
                      <div v-if="editing">
                        <TrackedButton
                          buttonText="Edit"
                          class="mr-3"
                          color="grey"
                          name="edit-experience-click"
                          outlined
                          x-small
                          :scrollTo="{ el: '#experience' }"
                          @click="editItem(experience, 'newExperience')"
                        />
                        <TrackedButton
                          buttonText="REMOVE"
                          color="grey"
                          name="remove-experience-click"
                          outlined
                          textStyle
                          x-small
                          @click="virtualResume.experience.splice(index, 1)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <span
                  v-if="!virtualResume.experience.length"
                  class="base--text"
                >
                  * No experience added yet.
                </span>
              </v-flex>
              <!-- END EXPERIENCEE -->

              <!-- ACCOMPLISHMENTS -->
              <v-flex xs12>
                <h3 class="pb-0 base--text">Accomplishments / Awards</h3>
                <v-combobox
                  v-if="editing"
                  multiple
                  v-model="virtualResume.accomplishments"
                  chips
                  deletable-chips
                  append-icon
                  label="Add accomplishments or awards, and press enter key to add more."
                ></v-combobox>
                <span
                  v-else-if="virtualResume.accomplishments.length"
                  class="base--text"
                >
                  {{ virtualResume.accomplishments.join(', ') }}
                </span>
                <span v-else class="base--text">
                  * No accomplishments or awards added yet.
                </span>
              </v-flex>
              <!-- END ACCOMPLISHMENTS -->

              <!-- SKILSS -->
              <v-flex xs12>
                <h3 class="pb-0 base--text">Skills / Trainings</h3>
                <v-combobox
                  v-if="editing"
                  multiple
                  v-model="virtualResume.skills"
                  chips
                  deletable-chips
                  append-icon
                  label="Add skills or training, and press enter key to add more."
                ></v-combobox>
                <span
                  v-else-if="virtualResume.skills.length"
                  class="base--text"
                >
                  {{ virtualResume.skills.join(', ') }}
                </span>
                <span v-else class="base--text">
                  * No skills or trainings added yet.
                </span>
              </v-flex>
              <!-- END SKILLS -->
              <v-flex xs12 class="text-right pt-4 mt-4">
                <TrackedButton
                  buttonText="cancel"
                  class="mr-1"
                  color="primary"
                  name="cancel-edit-skills-click"
                  rounded
                  small
                  textStyle
                  @click="editing = false"
                  v-if="editing"
                />
                <TrackedButton
                  buttonText="Save"
                  color="primary"
                  icon="mdi-content-save"
                  iconClass="mr-3"
                  iconSmall
                  name="save-skills-click"
                  rounded
                  small
                  @click="updateVirtualResume"
                  v-if="editing"
                />
              </v-flex>
            </v-layout>
            <v-snackbar
              color="info"
              top
              v-model="snackbar"
              :timeout="timeout"
              >{{ text }}</v-snackbar
            >
          </v-flex>
        </v-layout>
      </v-flex>
    </v-card-text>
  </v-card>
</template>

<script>
import TrackedButton from '@/components/TrackedButton.vue';
import { mapState } from 'vuex';
import { assign } from 'lodash';
import utils from '@/helpers/utils';

export default {
  components: { TrackedButton },
  data: () => ({
    editing: false,
    validEducation: true,
    validExperience: true,
    editingEducation: false,
    editingExperience: false,
    virtualResume: {
      experience: [],
      education: [],
      skills: [],
      accomplishments: [],
    },
    snackbar: false,
    text: 'Successfully Saved Virtual Resume!',
    timeout: 2000,
    months: utils.months,
    workYearList: utils.workYearList,
    degreeType: utils.degreeType,
    newExperience: {
      current: true,
    },
    newEducation: {
      current: true,
    },
  }),
  computed: {
    sortedEducation() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.virtualResume.education
        .sort(function (a, b) {
          return (
            `${a.startYear}${a.startMonth}` - `${b.startYear}${b.startMonth}`
          );
        })
        .reverse();
    },
    sortedExperience() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.virtualResume.experience
        .sort(function (a, b) {
          return (
            `${a.startYear}${a.startMonth}` - `${b.startYear}${b.startMonth}`
          );
        })
        .reverse();
    },
    ...mapState({
      user: (state) => state.user.user,
    }),
  },
  async created() {
    assign(this.virtualResume, this.user.virtual_resume);
  },
  methods: {
    async updateVirtualResume() {
      try {
        await this.$store.dispatch('user/updateProfile', {
          virtual_resume: this.virtualResume,
        });
        this.snackbar = true;
        this.editing = false;
      } catch (error) {
        //console.log('Not able to update Virtual Resume.', error);
      }
    },
    addItem(type) {
      const newItem = type == 'education' ? 'newEducation' : 'newExperience';
      this.virtualResume[type].push(this[newItem]);
      this[newItem] = {
        current: true,
      };
      this.$refs[type].resetValidation();
    },
    editItem(item, type) {
      const editingType =
        type == 'newEducation' ? 'editingEducation' : 'editingExperience';
      this[type] = item;
      this[editingType] = true;
    },
  },
};
</script>

<style scoped>
.list-box,
.new-list-box {
  border-left: solid 3px #cbd2d8;
  margin: 10px 0 20px 0;
  background: #f9f9f9;
  padding: 10px;
  border-radius: 5px;
}
.new-list-box {
  padding: 20px;
  margin-bottom: 10px;
  background: whitesmoke;
  border: solid 1px rgb(235, 235, 235);
  border-radius: 10px;
}
.list-box:last-of-type {
  margin-bottom: 0;
}

.head_text {
  word-break: break-word;
}
</style>
