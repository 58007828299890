<template>
  <v-card class="pa-12">
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div class="pa-12">
      <v-btn icon absolute top right @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <div class="mx-4">
        <h2>{{ item.name }}</h2>
        <p class="mt-8 mb-4" v-if="type === 'class'">Select a class time.</p>
        <p class="mt-8 mb-4" v-else>Select a meetup time.</p>
      </div>
      <v-card
        color="light-blue py-6 px-8 my-2"
        v-for="classItem in item.classes"
        :key="classItem.id"
      >
        <v-row class="align-center justify-space-between">
          <div class="font-weight-medium">
            <v-row class="align-center">
              <v-col class="flex-grow-0 flex-shrink-1">
                <v-img
                  v-if="type === 'social'"
                  src="@/assets/svgs/Group.svg"
                  width="30"
                />
                <v-img
                  v-else
                  src="@/assets/svgs/Skill Path Class.svg"
                  width="30"
                />
              </v-col>
              <v-col class="flex-grow-1 flex-shrink-0">
                {{ classItem.time | formatStartEndExt(classItem.duration) }}
              </v-col>
            </v-row>
          </div>
          <v-btn
            color="dark-blue"
            :dark="!loading"
            small
            :disabled="loading"
            @click="$emit('register', classItem)"
          >
            <span v-if="type === 'class'">Register</span>
            <span v-else>Sign up</span>
            <v-img src="@/assets/svgs/arrow.svg" class="ml-2" />
          </v-btn>
        </v-row>
      </v-card>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'ClassTimeModal',
  props: ['item', 'loading', 'type'],
};
</script>
