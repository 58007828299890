<template>
  <v-app>
    <v-overlay :value="$auth.loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-navigation-drawer
      :mini-variant="drawer"
      app
      dark
      id="drawer"
      width="240"
      :permanent="true"
      v-if="registered && !cleanRoute()"
    >
      <div class="text-center pa-0 mt-2">
        <router-link to="/">
          <v-img
            v-if="drawer"
            src="./img/logo-v-color.svg"
            class="ma-auto"
            height="40"
            contain
          />
          <v-img
            v-else
            src="./img/logo-color.svg"
            height="65"
            class="ma-auto"
            contain
          />
        </router-link>
      </div>
      <v-list dense>
        <template v-for="menu_item in menu_items">
          <v-list-item
            v-if="!menu_item.visibility || permissions[menu_item.visibility]"
            class="my-2 px-3"
            :href="menu_item.to_external"
            :target="menu_item.target"
            :to="menu_item.to_external ? null : menu_item.to"
            :key="menu_item.label"
            link
            light
          >
            <v-list-item-action>
              <v-icon color="base">{{ menu_item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ menu_item.label }}
                <v-icon v-if="menu_item.appendIcon" small class="pl-1">
                  {{ menu_item.appendIcon }}
                </v-icon>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-dialog v-model="newSurveyDialog.visible" width="800">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          New Survey
        </v-card-title>
        <v-container class="pt-0">
          <v-row v-if="feedbackSurvey" class="mb-n2">
            <v-col md="12">
              <SurveyForm
                :currentSurvey="feedbackSurvey.survey_js"
                :completedHtml="surveyCompletedHtml"
                @submit="sumbitSurveyUser($event)"
              />
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <span v-if="newSurveyDialog.confirming" class="confirm_text pr-2">
            Are you sure you want to exit this survey without completing it?
            Your feedback will be very helpful.
          </span>
          <TrackedButton
            color="primary"
            buttonText="Close"
            name="close-feedback-survey-click"
            textStyle
            @click="runDialogClosingSequence()"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-app-bar app color="white" v-if="!$auth.loading" style="z-index: 11">
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        v-if="registered && !cleanRoute()"
      />
      <v-toolbar-title class="font-weight-regular pl-0 d-flex align-center">
        <span v-if="!$auth.isAuthenticated || !registered">
          <img src="./img/logo-color.svg" class="pt-2 pr-4" />
        </span>
        Member Portal
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-width="200"
        offset-y
        v-if="$auth.isAuthenticated"
      >
        <template v-slot:activator="{ on }">
          <v-btn icon class="mr-2" v-on="on">
            <v-avatar :size="36" color="grey lighten-4">
              <img
                v-if="authUser.picture"
                :src="authUser.picture"
                alt="avatar"
              />
              <v-icon v-else>mdi-account</v-icon>
            </v-avatar>
          </v-btn>
        </template>

        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <img v-if="authUser.picture" :src="authUser.picture" />
                <v-icon v-else>mdi-account</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{ full_name }}</v-list-item-title>
                <v-list-item-subtitle class="base--text">
                  Daivergent Member
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list dense v-if="registered">
            <v-list-item-group color="grey darken-2">
              <v-list-item :to="{ name: 'profile' }" @click="menu = false">
                <v-list-item-content>
                  <v-list-item-title>Manage Profile</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>

          <v-card-actions>
            <v-spacer></v-spacer>
            <TrackedButton
              buttonText="Log Out"
              class="px-4"
              name="log-out-click"
              textStyle
              @click="
                menu = false;
                logout();
              "
            />
          </v-card-actions>
        </v-card>
      </v-menu>
      <v-btn v-else icon class="mr-2" @click="login">
        <v-avatar :size="36" color="grey lighten-4">
          <v-icon>mdi-account</v-icon>
        </v-avatar>
      </v-btn>
    </v-app-bar>

    <v-main class="content">
      <div v-if="!$auth.loading">
        <div>
          <v-snackbar
            color="warning"
            v-model="verifySnackbar"
            dense
            top
            centered
            :timeout="-1"
          >
            <v-row>
              <v-col md="1" class="snackbar_text">
                <v-icon large>mdi-alert-outline</v-icon>
              </v-col>
              <v-col md="10" class="snackbar_text">
                Please check your email for an email from domain@daivergent.com
                with the subject<br />"Verify your email". In that email, click
                "Confirm my account". Please do this within 3 days of signing up
                for Daivergent so that we can continue to serve you.
              </v-col>
              <v-col md="1">
                <v-icon color="white" @click="verifySnackbarClosed = true">
                  mdi-close
                </v-icon>
              </v-col>
            </v-row>
          </v-snackbar>
        </div>
        <router-view />
      </div>
      <div v-else>
        <!-- <Loading> -->
      </div>
      <div>
        <v-snackbar
          color="error"
          top
          centered
          :timeout="-1"
          v-model="globalErrors.length"
        >
          <v-row>
            <v-col md="11" class="snackbar_text">
              <span>
                Sorry, but some of your data has failed to load. As a result,
                some pages might experience issues. If you experience any
                trouble, please fill out a Technology Issue form.
              </span>
            </v-col>
            <v-col md="1">
              <v-icon color="white" @click="clearErrors()">mdi-close</v-icon>
            </v-col>
          </v-row>
        </v-snackbar>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import axios from 'axios';
import TrackedButton from '@/components/TrackedButton.vue';
import SurveyForm from '@/components/SurveyForm';
import { mapActions, mapState, mapGetters } from 'vuex';
import { every, filter, find, isArray, isEmpty, map } from 'lodash';

export default {
  name: 'App',
  components: {
    SurveyForm,
    TrackedButton,
  },
  data: () => ({
    drawer: null,
    menu: false,
    issueDialog: false,
    issueSelected: null,
    survey_user: null,
    surveyCompletedHtml: '<h2>Thank you for your feedback.</h2>',
    verifySnackbarClosed: false,
    menu_items: [
      {
        to: { name: 'home' },
        label: 'Dashboard',
        icon: 'mdi-view-dashboard',
      },
      {
        to: { name: 'goals' },
        label: 'Goals',
        icon: 'mdi-trophy',
        visibility: 'goals',
      },
      {
        to: { name: 'joblistings' },
        label: 'Job Listings',
        icon: 'mdi-briefcase',
        visibility: 'jobs',
      },
      {
        to: { name: 'classes' },
        label: 'Classes',
        icon: 'mdi-forum',
        visibility: 'classes',
      },
      {
        to: { name: 'community' },
        label: 'Community',
        icon: 'mdi-pulse',
        visibility: 'community',
      },
      {
        to: { name: 'profile' },
        label: 'Profile',
        icon: 'mdi-account',
      },
    ],
    newSurveyDialog: {
      visible: false,
      requiresConfirm: true,
      confirming: false,
    },
    feedbackSurvey: null,
  }),

  created() {
    const vm = this;
    if (vm.$vuetify.breakpoint.xs) {
      vm.drawer = true;
    }
  },

  computed: {
    ...mapGetters({
      registered: 'user/registered',
      emailVerified: 'user/emailVerified',
      permissions: 'user/permissions',
    }),
    ...mapState({
      user: (state) => state.user.user,
      userTags: (state) => state.user.userTags,
      authUser: (state) => state.user.auth_user,
      globalErrors: (state) => state.globalErrors.errors,
    }),
    full_name: function () {
      this.permissions;
      if (this.user && this.user.profile)
        return `${this.user.profile.first_name} ${this.user.profile.last_name}`;
      return '';
    },
    filteredMenuItems: function () {
      return filter(this.menu_items, (i) => this.isVisible(i.visibility));
    },
    verifySnackbar: function () {
      return (
        this.$route.name &&
        this.$route.name !== 'intake' &&
        this.$route.name !== 'systemerror' &&
        this.$route.name !== 'expired' &&
        !this.emailVerified &&
        !this.verifySnackbarClosed
      );
    },
  },

  // watch: {
  //   '$store.state.surveys.newFeedbackSurvey': function () {
  //     const vm = this;
  //     if (vm.$store.state.surveys.newFeedbackSurvey) {
  //       vm.feedbackSurvey = vm.$store.state.surveys.newFeedbackSurvey;
  //       vm.survey_user =
  //         vm.$store.state.surveys.newFeedbackSurvey.pending_survey_user;
  //       vm.newSurveyDialog.visible = true;
  //     }
  //   },
  // },

  methods: {
    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
    cleanRoute() {
      return this.$route.meta.cleanRoute;
    },

    ...mapActions({
      clearErrors: 'globalErrors/clearErrors',
    }),

    isVisible(visibility) {
      if (isEmpty(visibility)) {
        return true;
      } else {
        const userTagNames = map(this.userTags, (t) => t.name);
        const userRoles = !isEmpty(this.user.roles)
          ? filter(Object.keys(this.user.roles), (r) => !!this.user.roles[r])
          : [];
        return !!find(visibility, (v) => {
          return (
            (!isArray(v.roles) ||
              every(v.roles, (r) => userRoles.indexOf(r) !== -1)) &&
            (!isArray(v.tags) ||
              every(v.tags, (t) => userTagNames.indexOf(t) !== -1))
          );
        });
      }
    },

    runDialogClosingSequence(timeout = 0) {
      const vm = this;
      if (
        vm.newSurveyDialog.requiresConfirm &&
        !vm.newSurveyDialog.confirming
      ) {
        vm.newSurveyDialog.confirming = true;
      } else {
        setTimeout(() => {
          vm.newSurveyDialog.visible = false;
          vm.newSurveyDialog.confirming = false;
          vm.feedbackSurvey = null;
        }, timeout);
      }
    },

    async sumbitSurveyUser(surveyData) {
      const vm = this;
      try {
        await axios
          .post('/api/survey_user/submit', {
            survey_uuid: vm.survey_user.survey_uuid,
            survey_user_uuid: vm.survey_user.survey_user_uuid,
            submission: surveyData.data,
          })
          .then((surveyResults) => {
            // console.log('Survey submitted.', surveyResults);
            //call mutation for surveyUser
            vm.survey_user = false; //reset vm.survey_user to allow subsequent forms
            vm.newSurveyDialog.requiresConfirm = false;
            vm.newSurveyDialog.confirming = false;
            vm.runDialogClosingSequence(2000);
          })
          .catch((err) => {
            // console.log('Error submitting survey', err);
          });
      } catch (error) {
        // console.error(error);
      }
    },
  },
};
</script>

<style>
#drawer {
  background: #f2f5f7;
}

#drawer .v-list-item--active .v-list-item__title,
#drawer .v-list-item--active .v-icon {
  color: #9731ad !important;
}

#drawer .v-icon {
  background-color: #e2e8eb;
  padding: 4px;
  border-radius: 5px;
}

#drawer .v-list-item {
  background-color: #ecf0f2;
}

.content {
  background-color: #fafafa;
}
p {
  line-height: 2;
}

.v-card,
.v-toolbar {
  box-shadow: none !important;
}

.v-toolbar__content,
.v-toolbar__extension {
  border-bottom: solid 1px gainsboro !important;
}

.snackbar_text {
  text-align: center;
  color: white;
}

.confirm_text {
  color: red;
  font-style: italic;
}
</style>
