<template>
  <div>
    <b>{{ title }}</b>
    <p v-if="Array.isArray(value)">
      <span v-for="(val, v) in value" :key="v"> {{ val }}</span>
    </p>
    <p v-else-if="typeof value === 'string'">
      {{ value }}
    </p>
  </div>
</template>
<script>
export default {
  name: 'NoteCheckbox',
  props: ['title', 'value'],
  data: () => {
    return {};
  },
};
</script>
<style scoped></style>
