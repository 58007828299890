<template>
  <v-col class="mb-3">
    <v-card class="mb-5">
      <v-row>
        <v-col :md="description ? 6 : 12" class="col-12">
          <v-card-text>
            <h2 class="font-weight-medium">{{ goal.name }}</h2>
          </v-card-text>
          <v-card-text :class="[description ? 'py-0 py-md-3' : 'pb-8 pt-4']">
            <ProgressBar :progress="progress" :status="status" />
          </v-card-text>
        </v-col>
        <v-col md="6" class="col-12" v-if="description">
          <v-card-text>
            <h2>About this goal:</h2>
          </v-card-text>
          <v-card-text class="pt-0" v-html="description" />
        </v-col>
      </v-row>
      <v-col class="col-12 col py-0 px-7 text-right">
        <ToggleDetailsBtn
          absolute
          :show="!showDetails"
          @toggle="toggleDetail()"
        />
      </v-col>
    </v-card>
    <v-col v-if="showDetails && showPrimary">
      <h3 class="font-weight-medium mt-4">This goal will be completed when:</h3>
      <OutcomesList
        :outcomes="primary"
        :completed-pathways="completedPathways"
        :outcomes-details="outcomesDetails"
        :completed="completedPrimary"
        :goal-user-interventions="goalUser.primary_interventions"
        :completed-outcomes-list="completedOutcomesList"
        :outcomes-list="outcomesList.primary"
      />
    </v-col>
    <v-col v-if="showDetails && showSupporting">
      <h3 class="font-weight-medium mt-4">
        Other things you can do to make progress:
      </h3>
      <OutcomesList
        :outcomes="supporting"
        :completed-pathways="completedPathways"
        :outcomes-details="outcomesDetails"
        :completed="completedSupporting"
        :goal-user-interventions="goalUser.supporting_interventions"
        :completed-outcomes-list="completedOutcomesList"
        :outcomes-list="outcomesList.supporting"
      />
    </v-col>
  </v-col>
</template>

<script>
import ProgressBar from './ProgressBar';
import ToggleDetailsBtn from './ToggleDetailsBtn';
import OutcomesList from './OutcomesList';
import { mapState } from 'vuex';
export default {
  name: 'GoalDetail',
  components: { ProgressBar, ToggleDetailsBtn, OutcomesList },
  props: [
    'goalUser',
    'outcomes',
    'outcomesDetails',
    'completedPathways',
    'index',
    'outcomeUsers',
    'enrichedGoalUser',
  ],
  data() {
    return {
      showDetails: false,
    };
  },
  created() {
    this.showDetails =
      this.$route.params.goal_user_uuid === this.goalUser.goal_user_uuid;
  },
  computed: {
    ...mapState({
      pathways: (state) => state.pathways.pathways,
      goals: (state) => state.goals.goals,
    }),
    goal: function () {
      return this.goals.find(
        (goal) => goal.goal_uuid === this.goalUser.goal_uuid
      );
    },
    status: function () {
      if (this.completedPrimary.length) return 'completed';
      if (!this.completedSupporting.length) return 'created';
      return 'started';
    },
    isCompleted: function () {
      return this.status === 'completed';
    },
    progress: function () {
      if (this.isCompleted) return 100;
      return this.getProgress();
    },
    showPrimary: function () {
      return (
        this.outcomesList.primary.outcomes.length ||
        this.outcomesList.primary.pathways.list.length
      );
    },
    showSupporting: function () {
      return (
        this.outcomesList.supporting.outcomes.length ||
        this.outcomesList.supporting.pathways.list.length
      );
    },
    outcomesList: function () {
      const pathways = {
        primary: this.getOutcomesList(this.goalUser.primary_interventions),
        supporting: this.getOutcomesList(
          this.goalUser.supporting_interventions
        ),
      };
      return pathways;
    },
    primary: function () {
      return this.getInterventionUUID(this.goal.primary_interventions);
    },
    supporting: function () {
      return this.getInterventionUUID(this.goal.supporting_interventions);
    },
    completedSupporting: function () {
      return this.getCompletedInterventions(
        this.enrichedGoalUser.supporting_interventions
      );
    },
    completedPrimary: function () {
      return this.getCompletedInterventions(
        this.enrichedGoalUser.primary_interventions
      );
    },
    description: function () {
      return this.goal.description && this.goal.description.domain
        ? this.goal.description.domain
        : undefined;
    },
    completedOutcomesList: function () {
      return [...this.completedPrimary, ...this.completedSupporting];
    },
  },
  methods: {
    getProgress() {
      const total = this.primary.length + this.supporting.length;
      const totalCompleted =
        this.completedPrimary.length + this.completedSupporting.length;
      return (totalCompleted / total) * 100;
    },
    toggleDetail() {
      this.showDetails = !this.showDetails;
    },
    getInterventionUUID(interventions) {
      const list = [];
      if (interventions) {
        interventions.forEach((intervention) => list.push(...intervention));
      }
      return list;
    },
    getCompletedInterventions(interventions) {
      const completed = [];
      interventions.forEach((block) =>
        block.forEach((intervention) => {
          if (intervention && intervention.hasQualifyingIntervention) {
            completed.push(Object.keys(intervention)[0]);
          }
        })
      );
      return completed;
    },
    getOutcomesList(interventions) {
      const pathways = { hasPathways: false, list: [] };
      const outcomes = [];
      interventions.forEach((block) =>
        block.forEach((intervention) =>
          Object.values(intervention).forEach((value) => {
            if (value.length) {
              value.forEach((qualifier) => {
                if (qualifier.pathway) {
                  pathways.hasPathways = true;
                  qualifier.pathway.forEach((qualifierPathway) => {
                    this.pathways.forEach((pathway) => {
                      if (pathway.pathway_uuid === qualifierPathway)
                        pathways.list.push(qualifierPathway);
                    });
                  });
                } else {
                  outcomes.push(intervention);
                }
              });
            } else {
              outcomes.push(intervention);
            }
          })
        )
      );
      return { pathways, outcomes };
    },
  },
};
</script>
