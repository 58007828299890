<template>
  <v-card height="100%">
    <v-card-text>
      <v-card-title primary-title>
        <v-col class="pa-0 d-flex justify-space-between">
          <span class="text-h3 base--text font-weight-medium head_text">
            {{ outcome.name }}
          </span>
          <v-tooltip v-if="completed" left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="green" v-on="on" v-bind="attrs">
                mdi-checkbox-marked-circle
              </v-icon>
            </template>
            <span>Completed</span>
          </v-tooltip>
        </v-col>
        <v-card-text class="pa-0 text-caption base--text">
          {{ completionNote }}
        </v-card-text>
      </v-card-title>
      <v-card-text v-if="hasDescription">
        <span
          v-for="(description, index) in descriptions"
          :key="index"
          class="base--text"
        >
          {{ description }}
        </span>
      </v-card-text>
      <v-card-text v-if="hasQualifiers">
        <span v-for="(qualifier, index) in qualifiers" :key="index">
          <QualifierCard :qualifier="qualifier" />
        </span>
      </v-card-text>
    </v-card-text>
  </v-card>
</template>

<script>
import QualifierCard from './QualifierCard';
export default {
  name: 'OutcomeCard',
  components: { QualifierCard },
  props: ['outcome', 'qualifiersList', 'completed'],
  computed: {
    descriptions: function () {
      return Object.values(this.outcome.description);
    },
    qualifiers: function () {
      return this.qualifiersList[this.outcome.outcome_uuid];
    },
    hasDescription: function () {
      return this.outcome.description && this.descriptions.length;
    },
    hasQualifiers: function () {
      return this.qualifiersList && this.qualifiers && this.qualifiers.length;
    },
  },
  data() {
    return {
      completionNote: 'Let your Coach/VSP know if you did',
    };
  },
};
</script>
