<template>
  <v-container class="pa-8 ma-0">
    <v-row>
      <v-col class="col-12">
        <h1 class="text-h3 font-weight-medium">Goals</h1>
      </v-col>
    </v-row>
    <v-row
      v-if="showGoals && showOutcomes && pendingEnrichedGoalUsers.length"
      justify="center"
    >
      <v-col
        class="col-12 pa-0"
        v-for="(goalUser, index) in pendingGoalUsers"
        :id="goalUser.goal_uuid"
        :key="index"
      >
        <v-divider class="mb-7" v-if="!!index" />
        <GoalDetail
          :goal-user="goalUser"
          :outcomes="outcomes"
          :id="goalUser.goal_uuid"
          :outcomes-details="outcomesDetails"
          :outcome-users="outcomeUsers"
          :completed-pathways="completed"
          :enriched-goal-user="getEnrichedGoalUser(goalUser.goal_user_uuid)"
          :index="index"
        />
      </v-col>
    </v-row>
    <v-row v-if="showGoals && showOutcomes && showPathways">
      <v-divider class="mb-2" />
    </v-row>
    <v-row class="my-6" v-if="showPathways">
      <v-col md="12">
        <v-row id="primary_actions" v-if="primary">
          <v-col cols="9">
            <h3 class="head_text">Most Recent Pathways</h3>
          </v-col>
          <v-col cols="3" class="text-right">
            <ToggleDetailsBtn
              :show="!toggle.primary"
              @toggle="toggleDetail('primary')"
            />
          </v-col>
        </v-row>
        <v-row v-if="toggle.primary">
          <v-col md="6">
            <PathwayCard v-if="primary" :pathway="primary" />
          </v-col>
          <v-col md="6">
            <PathwayCard v-if="secondaryPathway" :pathway="secondaryPathway" />
          </v-col>
        </v-row>
        <div class="my-4" v-if="otherPathways && otherPathways.length">
          <v-divider class="mb-2" />
          <v-row>
            <v-col cols="9">
              <h3 class="head_text">Other Available Pathways</h3>
            </v-col>
            <v-col cols="3" class="text-right">
              <ToggleDetailsBtn
                :show="!toggle.others"
                @toggle="toggleDetail('others')"
              />
            </v-col>
          </v-row>
          <v-row v-if="toggle.others">
            <v-col v-for="(pathway, p) in otherPathways" :key="p" md="6">
              <PathwayCard :pathway="pathway" />
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-row
      class="mb-8"
      v-if="
        ((showGoals && showOutcomes) || showPathways) &&
        completedEnrichedGoalUsers &&
        (completedEnrichedGoalUsers.length || (completed && completed.length))
      "
    >
      <v-divider class="mb-2" />
    </v-row>
    <div
      v-if="
        showGoals &&
        showOutcomes &&
        completedEnrichedGoalUsers &&
        completedEnrichedGoalUsers.length
      "
    >
      <v-row justify="center">
        <v-col cols="9">
          <h3 class="head_text">Completed Goals</h3>
        </v-col>
        <v-col cols="3" class="text-right">
          <ToggleDetailsBtn
            :show="!toggle.completedGoals"
            @toggle="toggleDetail('completedGoals')"
          />
        </v-col>
        <template v-if="toggle.completedGoals">
          <v-col
            class="col-12 pa-0"
            v-for="(goalUser, index) in completedGoalUsers"
            :id="goalUser.goal_uuid"
            :key="index"
          >
            <v-divider class="mb-7" v-if="!!index" />
            <GoalDetail
              :goal-user="goalUser"
              :outcomes="outcomes"
              :id="goalUser.goal_uuid"
              :outcomes-details="outcomesDetails"
              :outcome-users="outcomeUsers"
              :completed-pathways="completed"
              :enriched-goal-user="getEnrichedGoalUser(goalUser.goal_user_uuid)"
              :index="index"
            />
          </v-col>
        </template>
      </v-row>
    </div>
    <div v-if="showPathways && completed && completed.length">
      <v-row>
        <v-col cols="9">
          <h3 class="head_text">Completed Pathways</h3>
        </v-col>
        <v-col cols="3" class="text-right">
          <ToggleDetailsBtn
            :show="!toggle.completed"
            @toggle="toggleDetail('completed')"
          />
        </v-col>
      </v-row>
      <v-row v-if="toggle.completed">
        <v-col v-for="(pathway, p) in completed" :key="p" md="6">
          <PathwayCard :pathway="pathway" />
        </v-col>
      </v-row>
    </div>
    <v-row justify="center" v-if="!showGoals && !showPathways && !loading">
      <GoalCenterEmpty />
    </v-row>
  </v-container>
</template>

<script>
import { every, filter, flattenDeep, get, isEmpty, map } from 'lodash';
import { mapGetters, mapState } from 'vuex';
import PathwayCard from '@/components/goal-center/PathwayCard';
import GoalDetail from '@/components/goal-center/GoalDetail';
import GoalCenterEmpty from '@/components/goal-center/GoalCenterEmpty';
import ToggleDetailsBtn from '@/components/goal-center/ToggleDetailsBtn';
export default {
  name: 'GoalCenter',
  components: {
    PathwayCard,
    GoalDetail,
    GoalCenterEmpty,
    ToggleDetailsBtn,
  },
  computed: {
    ...mapGetters({
      completed: 'pathways/completed',
      primary: 'pathways/primary',
      loading: 'goals/loading',
    }),
    ...mapState({
      pathways: (state) => state.pathways.pathways,
      goals: (state) => state.goals.goals,
      goalUsers: (state) => state.goals.goalUsers,
      enrichedGoalUsers: (state) => state.goals.enrichedGoalUsers,
      outcomes: (state) => state.outcomes.outcomes,
      outcomesDetails: (state) => state.outcomes.outcomesDetails,
      outcomeUsers: (state) => state.outcomes.outcomeUsers,
      pendingEnrichedGoalUsers: (state) => state.goals.pendingEnrichedGoalUsers,
      completedEnrichedGoalUsers: (state) =>
        state.goals.completedEnrichedGoalUsers,
    }),
    showPathways: function () {
      return this.pathways && this.pathways.length;
    },
    showOutcomes: function () {
      return this.outcomesDetails && this.outcomes;
    },
    showGoals: function () {
      return this.goals && this.goals.length;
    },
    completedGoalUsers: function () {
      return this.completedEnrichedGoalUsers.map((enrichedGoalUser) =>
        this.goalUsers.find(
          (goalUser) =>
            enrichedGoalUser.goal_user_uuid === goalUser.goal_user_uuid
        )
      );
    },
    pendingGoalUsers: function () {
      return this.pendingEnrichedGoalUsers.map((enrichedGoalUser) =>
        this.goalUsers.find(
          (goalUser) =>
            enrichedGoalUser.goal_user_uuid === goalUser.goal_user_uuid
        )
      );
    },
    secondaryPathway: function () {
      if (this.primary && this.pathways) {
        return filter(
          this.pathways,
          (p) =>
            p.pathway_uuid !== this.primary.pathway_uuid &&
            every(p.pathway_users, (pu) => pu.status !== 'completed')
        )[0];
      } else {
        return null;
      }
    },
    otherPathways: function () {
      this.test;
      if (!this.primary && !this.secondaryPathway) {
        return [];
      }
      const alreadySectioned = flattenDeep([
        get(this, 'primary', {}),
        get(this, 'secondaryPathway', {}),
      ]);
      if (!isEmpty(this.pathways) && !isEmpty(alreadySectioned)) {
        const alreadySectionedUuids = map(
          alreadySectioned,
          (a) => a.pathway_uuid
        );
        return filter(
          this.pathways,
          (p) =>
            alreadySectionedUuids.indexOf(p.pathway_uuid) === -1 &&
            every(p.pathway_users, (pu) => pu.status !== 'completed')
        );
      } else {
        return [];
      }
    },
  },
  data() {
    return {
      toggle: {
        primary: true,
        completed: false,
        others: false,
        completedGoals: false,
      },
    };
  },
  methods: {
    getEnrichedGoalUser(goal_user_uuid) {
      return this.enrichedGoalUsers.find(
        (enrichedGoalUser) => enrichedGoalUser.goal_user_uuid === goal_user_uuid
      );
    },
    toggleDetail(value) {
      this.toggle[value] = !this.toggle[value];
    },
  },
};
</script>

<style scoped>
#yt-frame {
  width: 100%;
}
.head_text {
  word-break: break-word;
}
</style>
