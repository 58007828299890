import axios from 'axios';
import { every, filter, get, isEmpty, orderBy, some } from 'lodash';

const state = {
  pathways: null,
};

const getters = {
  completed: (state) => {
    return filter(
      state.pathways,
      (p) =>
        p.pathway_users &&
        !isEmpty(p.strapi_data) &&
        !isEmpty(p.pathway_users) &&
        every(p.pathway_users, (pu) => pu.status === 'completed')
    );
  },

  primary: (state) => {
    if (isEmpty(state.pathways)) {
      return null;
    }
    if (every(state.pathways, (p) => isEmpty(p.pathway_users))) {
      return orderBy(state.pathways, 'updated_at', 'desc')[0];
    }
    const activePathways = [];
    for (const p of state.pathways) {
      if (
        !isEmpty(p.strapi_data) &&
        (isEmpty(p.pathway_users) ||
          (some(
            p.pathway_users,
            (pu) => ['created', 'in_progress'].indexOf(pu.status) !== -1
          ) &&
            some(
              p.sequence_items,
              (si) =>
                isEmpty(si.module_users) ||
                every(
                  si.module_users,
                  (mu) => ['completed', 'stopped'].indexOf(mu.status) === -1
                )
            )))
      ) {
        activePathways.push(p);
      }
    }
    if (!isEmpty(activePathways)) {
      let primaryPathway = {};
      for (const p of activePathways) {
        p.primary_pathway_user = orderBy(
          p.pathway_users,
          [(pu) => pu.status === 'in_progress', 'status_timestamp'],
          ['desc', 'desc']
        )[0];
        if (
          isEmpty(primaryPathway.latest_pathway_user) ||
          (p.primary_pathway_user === 'in_progress' &&
            get(p, 'primary_pathway_user.status_timestamp') >
              get(primaryPathway, 'primary_pathway_user.status_timestamp')) ||
          (p.primary_pathway_user.status === 'created' &&
            primaryPathway.primary_pathway_user.status === 'created' &&
            get(p, 'primary_pathway_user.status_timestamp') >
              get(primaryPathway, 'primary_pathway_user.status_timestamp'))
        ) {
          primaryPathway = p;
        }
      }
      return primaryPathway;
    } else {
      return null;
    }
  },
};

const actions = {
  async fetchPathways({ commit }) {
    await axios.get('/api/pathway').then(({ data }) => {
      commit('cache_pathways', data);
      return data;
    });
  },
  addPathwayUser({ commit }, pu) {
    commit('add_pathway_user', pu);
  },
  replacePathwayUser({ commit }, pu) {
    commit('replace_pathway_user', pu);
  },
  addModuleUser({ commit }, mu) {
    commit('add_module_user', mu);
  },
  replaceModuleUser({ commit }, mu) {
    commit('replace_module_user', mu);
  },
  async init({ dispatch }) {
    await dispatch('fetchPathways');
    return;
  },
};

const mutations = {
  cache_pathways(_state, pathways) {
    // override hack for now
    _state.pathways = pathways;
  },
  add_pathway_user(_state, pu) {
    for (const p of _state.pathways) {
      if (p.pathway_uuid === pu.pathway_uuid) {
        p.pathway_users.push(pu);
        break;
      }
    }
  },
  replace_pathway_user(_state, pu) {
    for (const p of _state.pathways) {
      if (p.pathway_uuid === pu.pathway_uuid) {
        p.pathway_users = filter(
          p.pathway_users,
          (p_u) => p_u.pathway_user_uuid !== pu.pathway_user_uuid
        );
        p.pathway_users.unshift(pu);
        break;
      }
    }
  },
  add_module_user(_state, mu) {
    for (const p of _state.pathways) {
      for (const pu of p.pathway_users) {
        if (mu.pathway_user_uuid === pu.pathway_user_uuid) {
          for (const s of p.sequence_items) {
            if (s.module_uuid === mu.module_uuid) {
              s.module_users.push(mu);
              break;
            }
          }
          break;
        }
      }
    }
  },
  replace_module_user(_state, mu) {
    let found;
    for (const p of _state.pathways) {
      for (const s of p.sequence_items) {
        if (s.module_uuid === mu.module_uuid) {
          s.module_users = filter(
            s.module_users,
            (m_u) => m_u.module_user_uuid !== mu.module_user_uuid
          );
          s.module_users.unshift(mu);
          found = true;
          break;
        }
      }
      if (found) break;
    }
  },
};

export const pathways = {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
