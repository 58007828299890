<template>
  <v-row>
    <v-col>
      <v-card>
        <v-card-title>Supporting Content/Actions</v-card-title>
        <v-card-text>
          Something goes here. maybe another pathway, maybe form to complete
          profile, etc. Should use sub components
        </v-card-text>
        <v-card-actions>
          <v-btn plain small text>action maybe?</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
