<template>
  <v-container fluid class="mt-6">
    <v-snackbar
      v-model="snackbar.visible"
      top
      :color="snackbar.color"
      :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-icon color="white" v-bind="attrs" @click="snackbar.visible = false"
          >mdi-close</v-icon
        >
      </template>
    </v-snackbar>
    <v-dialog v-model="seeMoreDialog" width="800">
      <v-card v-if="selectedListing">
        <v-card-title class="headline grey lighten-2" primary-title
          >{{ selectedListing.title }}
          <v-chip
            v-if="selectedListing.user_uuid === user.user_uuid"
            color="#ebb134"
            text-color="white"
            class="ma-2"
            style="font-style: italic"
            >Interested</v-chip
          ></v-card-title
        >
        <v-container class="pt-0">
          <v-row>
            <v-col md="12" class="pb-0">
              <v-card-text class="text-center">
                <v-img
                  :src="
                    selectedListing.image_path ||
                    'https://storage.googleapis.com/daivergent-guilford-public/domain/listing_default.png'
                  "
                  contain
                  height="270"
                />
              </v-card-text>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              v-if="
                selectedListing.description &&
                selectedListing.description.long &&
                selectedListing.description.long.blocks
              "
              class="pa-0"
            >
              <v-list-item
                v-for="(block, b) in selectedListing.description.long.blocks"
                :key="b"
                class="my-n2"
              >
                <v-list-item-content v-if="block.type === 'header'">
                  <h4>{{ block.data.text }}</h4>
                </v-list-item-content>
                <v-list-item-content
                  class="pa-0"
                  v-else-if="block && block.type === 'list'"
                >
                  <v-list
                    v-for="(item, i) in block.data.items"
                    :key="i"
                    class="pa-0"
                  >
                    <v-list-item>
                      <v-list-item-content>
                        <div v-html="item"></div>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-list-item-content>
                <v-list-item-content
                  v-else-if="block && block.type === 'paragraph'"
                >
                  <div v-html="block.data.text"></div>
                </v-list-item-content>
                <v-list-item-content
                  v-else-if="block && block.type === 'linkTool'"
                >
                  <a
                    :href="
                      block.data.link.startsWith('http')
                        ? block.data.link
                        : `https://${block.data.link}`
                    "
                    target="_blank"
                    >{{ block.data.link }}</a
                  >
                </v-list-item-content>
                <v-list-item-content v-else-if="block && block.type === 'raw'">
                  {{ block.data.html }}
                </v-list-item-content>
                <v-list-item-content v-else>
                  <span style="color: red">ERROR WITH DESCRIPTION SECTION</span>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <TrackedButton
            @click="seeMoreDialog = false"
            buttonText="Close"
            color="primary"
            :data="{ listing: selectedListing.listing_uuid }"
            name="close-see-more-click"
            textStyle
          />
          <TrackedButton
            v-if="selectedListing.user_uuid === user.user_uuid"
            @click="deleteListingUser()"
            buttonText="Remove Interest"
            color="primary"
            :data="{ listing: selectedListing.listing_uuid }"
            name="remove-listing-interest-click"
          />
          <TrackedButton
            v-else
            @click="createListingUser()"
            buttonText="Indicate Interest"
            color="primary"
            :data="{ listing: selectedListing.listing_uuid }"
            name="indicate-listing-interest-click"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row justify="center">
      <v-col cols="10">
        <h1 class="head_text">Job Listings</h1>
      </v-col>
      <v-col v-if="!listings.length" cols="10">
        <h3 class="head_text">
          There are currently no available job listings.
        </h3>
      </v-col>
      <ListingCard
        v-for="l in listings"
        :key="l.listing_uuid"
        :listing="l"
        @showSeeMoreDialog="
          seeMoreDialog = true;
          selectedListing = $event;
        "
      />
    </v-row>
  </v-container>
</template>
<script>
import TrackedButton from '@/components/TrackedButton.vue';
import ListingCard from '../components/ListingCard';
import { mapState } from 'vuex';
import axios from 'axios';
import { isArray, get, replace } from 'lodash/fp';
export default {
  components: {
    ListingCard,
    TrackedButton,
  },
  data: () => ({
    listings: [],
    snackbar: {
      visible: false,
      color: 'primary',
      text: '',
      timeout: 3000,
    },
    selectedListing: null,
    seeMoreDialog: false,
  }),

  computed: {
    ...mapState({
      user: (state) => state.user.user,
    }),
  },

  async created() {
    const vm = this;
    vm.fetchListings();
  },

  methods: {
    async fetchListings() {
      const vm = this;
      await axios.get('/api/listing').then((res) => {
        for (const d of res.data) {
          const blocks = get('description.long.blocks', d);
          if (blocks) {
            d.description.long.blocks = vm.addAnchorTargets(blocks);
          }
        }
        vm.listings = res.data;
      });
    },

    addAnchorTargets(blocks) {
      const finalBlocks = [];
      for (const b of blocks) {
        finalBlocks.push(this.replaceAnchors(b));
      }
      return finalBlocks;
    },

    replaceAnchors(block) {
      let finalBlock = {};
      if (typeof block === 'string') {
        const anchors = block.match(RegExp('<a ', 'g')) || [];
        const targets = block.match(RegExp('target="_blank"', 'g')) || [];
        if (anchors.length !== targets.length) {
          const targetsRemoved = replace(
            RegExp('target="_blank"', 'g'),
            '',
            block
          );
          finalBlock = replace(
            RegExp('<a ', 'g'),
            '<a target="_blank" ',
            targetsRemoved
          );
        } else {
          finalBlock = block;
        }
      } else if (isArray(block)) {
        const blockItems = [];
        for (const i of block) {
          blockItems.push(this.replaceAnchors(i));
        }
        finalBlock = blockItems;
      } else if (Object.keys(block).length) {
        for (const k of Object.keys(block)) {
          finalBlock[k] = this.replaceAnchors(block[k]);
        }
      } else {
        finalBlock = block;
      }
      return finalBlock;
    },

    async createListingUser() {
      const vm = this;
      if (vm.selectedListing) {
        await axios
          .post('/api/listing', {
            listing_uuid: vm.selectedListing.listing_uuid,
            user_uuid: vm.user.user_uuid,
          })
          .then(() => {
            vm.fetchListings();
            vm.seeMoreDialog = false;
            vm.snackbar.text = `You are interested in ${vm.selectedListing.title}`;
            vm.snackbar.visible = true;
          });
      }
    },

    async deleteListingUser() {
      const vm = this;
      if (vm.selectedListing) {
        await axios
          .delete('/api/listing', {
            data: {
              listing_user_uuid: vm.selectedListing.listing_user_uuid,
            },
          })
          .then(() => {
            vm.fetchListings();
            vm.seeMoreDialog = false;
            vm.snackbar.text = `Removed interested from ${vm.selectedListing.title}`;
            vm.snackbar.visible = true;
          });
      }
    },
  },
};
</script>
<style scoped>
.head_text {
  word-break: break-word;
}
</style>
