import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"dashboard",attrs:{"fluid":""}},[_c(VRow,{staticClass:"align-lg-stretch pa-4"},[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{attrs:{"height":"100%"}},[_c('Personal',{attrs:{"note":_vm.latestNote,"user":_vm.user,"meetings":_vm.meetings},on:{"openNote":_vm.openNote}})],1)],1)],1),(
      (!_vm.loading && !_vm.permissions.goals) ||
      (_vm.permissions.goals && !_vm.primary && (!_vm.goalUsers || !_vm.goalUsers.length))
    )?_c(VRow,{staticClass:"px-4"},[_c(VCol,{staticClass:"py-0",attrs:{"cols":"6"}},[_c(VCard,{staticClass:"pa-4"},[_c(VCardText,[_c('h2',{staticClass:"base--text font-weight-medium"},[_vm._v("Manage your profile")])]),_c(VCardText,{staticClass:"pb-0"},[_c('p',{staticClass:"base--text"},[_vm._v(" Keep your profile up to date! Enter simple resume data to help us match you with classes and opportunities. ")])]),_c(VCardText,{staticClass:"pt-0"},[_c(VBtn,{attrs:{"small":"","dark":"","color":"grape"},on:{"click":function($event){return _vm.$router.push({ name: 'profile' })}}},[_c('span',[_vm._v("Update Profile")]),_c(VImg,{staticClass:"ml-2",attrs:{"src":require("@/assets/svgs/arrow.svg"),"width":"20"}})],1)],1)],1)],1)],1):_vm._e(),(_vm.permissions.goals)?[_c(VRow,{staticClass:"px-4 pb-7"},[(!_vm.loading && _vm.goalUsers.length)?_c(VCol,{staticClass:"py-0",attrs:{"cols":"12"}},[_c(VCard,{staticClass:"pa-4",attrs:{"height":"100%"}},[_c('Goal',{attrs:{"goal":_vm.goal,"goal-user":_vm.goalUsers[0],"count":_vm.goalUsers.length - 1,"outcomes":_vm.outcomes,"outcomes-details":_vm.outcomesDetails,"outcome-users":_vm.outcomeUsers,"enriched-goal-user":_vm.enrichedGoalUser},on:{"goToGoal":_vm.goToGoal}})],1)],1):_vm._e()],1),(_vm.loading)?_c(VRow,{staticClass:"px-4"},[_c(VCol,[_c(VSkeletonLoader,{attrs:{"justify":"center","max-width":"50%","type":"card"}})],1)],1):(_vm.primary)?_c(VRow,{attrs:{"id":"primary_actions","justify":"center"}},[_c(VCol,{staticClass:"px-8 py-0"},[_c('Primary',{attrs:{"pathway":_vm.primary},on:{"primaryClicked":_vm.primaryClicked}})],1)],1):_c(VRow,{staticClass:"px-4 mt-0"},[_c(VCol,{staticClass:"col-12 pr-md-0",attrs:{"md":"6"}},[_c('NoPrimary',{attrs:{"hasPathways":!!_vm.pathways.length}})],1)],1)]:_vm._e(),_c(_vm.currentDialog.component,_vm._b({tag:"v-component",on:{"cancel":_vm.resetCurrentDialog}},'v-component',_vm.currentDialog.props,false)),_c('schedule',{attrs:{"items":_vm.schedule,"user":_vm.user}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }