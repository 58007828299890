import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"height":"100%"}},[_c(VCardText,[_c(VCardTitle,{attrs:{"primary-title":""}},[_c(VCol,{staticClass:"pa-0 d-flex justify-space-between"},[_c('span',{staticClass:"text-h3 base--text font-weight-medium head_text"},[_vm._v(" "+_vm._s(_vm.outcome.name)+" ")]),(_vm.completed)?_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"green"}},'v-icon',attrs,false),on),[_vm._v(" mdi-checkbox-marked-circle ")])]}}],null,false,1707546215)},[_c('span',[_vm._v("Completed")])]):_vm._e()],1),_c(VCardText,{staticClass:"pa-0 text-caption base--text"},[_vm._v(" "+_vm._s(_vm.completionNote)+" ")])],1),(_vm.hasDescription)?_c(VCardText,_vm._l((_vm.descriptions),function(description,index){return _c('span',{key:index,staticClass:"base--text"},[_vm._v(" "+_vm._s(description)+" ")])}),0):_vm._e(),(_vm.hasQualifiers)?_c(VCardText,_vm._l((_vm.qualifiers),function(qualifier,index){return _c('span',{key:index},[_c('QualifierCard',{attrs:{"qualifier":qualifier}})],1)}),0):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }