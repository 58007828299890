<template>
  <v-card class="pa-4" height="100%">
    <v-card-title primary-title class="pb-0">
      <h1 class="head_text">Forum</h1>
    </v-card-title>
    <v-card-text>
      <p>
        <v-img
          src="@/img/card-images/community.svg"
          width="300"
          height="300"
          contain
        />
      </p>
      <p>
        Join the Daivergent Community Forums: a safe space to have a
        conversation about your interests and experiences.
      </p>
      <p>
        <TrackedButton
          @click="goToCommunity()"
          buttonText="Access Community"
          color="primary"
          icon="mdi-open-in-new"
          iconClass="mr-2"
          name="forum-card-click"
          rounded
        />
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
import TrackedButton from '@/components/TrackedButton.vue';

export default {
  name: 'ForumCard',
  components: { TrackedButton },
  data() {
    return {};
  },
  methods: {
    goToCommunity() {
      window.open('https://community.daivergent.com', '_blank');
    },
  },
};
</script>

<style scoped>
.icon-adjust {
  vertical-align: middle;
  margin-right: 10px;
}

.head_text {
  word-break: break-word;
}
</style>
