import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"mt-6",attrs:{"fluid":""}},[_c(VSnackbar,{attrs:{"top":"","color":_vm.snackbar.color,"timeout":_vm.snackbar.timeout},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c(VIcon,_vm._b({attrs:{"color":"white"},on:{"click":function($event){_vm.snackbar.visible = false}}},'v-icon',attrs,false),[_vm._v("mdi-close")])]}}]),model:{value:(_vm.snackbar.visible),callback:function ($$v) {_vm.$set(_vm.snackbar, "visible", $$v)},expression:"snackbar.visible"}},[_vm._v(" "+_vm._s(_vm.snackbar.text)+" ")]),_c(VDialog,{attrs:{"width":"800"},model:{value:(_vm.seeMoreDialog),callback:function ($$v) {_vm.seeMoreDialog=$$v},expression:"seeMoreDialog"}},[(_vm.selectedListing)?_c(VCard,[_c(VCardTitle,{staticClass:"headline grey lighten-2",attrs:{"primary-title":""}},[_vm._v(_vm._s(_vm.selectedListing.title)+" "),(_vm.selectedListing.user_uuid === _vm.user.user_uuid)?_c(VChip,{staticClass:"ma-2",staticStyle:{"font-style":"italic"},attrs:{"color":"#ebb134","text-color":"white"}},[_vm._v("Interested")]):_vm._e()],1),_c(VContainer,{staticClass:"pt-0"},[_c(VRow,[_c(VCol,{staticClass:"pb-0",attrs:{"md":"12"}},[_c(VCardText,{staticClass:"text-center"},[_c(VImg,{attrs:{"src":_vm.selectedListing.image_path ||
                  'https://storage.googleapis.com/daivergent-guilford-public/domain/listing_default.png',"contain":"","height":"270"}})],1)],1)],1),_c(VRow,[(
              _vm.selectedListing.description &&
              _vm.selectedListing.description.long &&
              _vm.selectedListing.description.long.blocks
            )?_c(VCol,{staticClass:"pa-0"},_vm._l((_vm.selectedListing.description.long.blocks),function(block,b){return _c(VListItem,{key:b,staticClass:"my-n2"},[(block.type === 'header')?_c(VListItemContent,[_c('h4',[_vm._v(_vm._s(block.data.text))])]):(block && block.type === 'list')?_c(VListItemContent,{staticClass:"pa-0"},_vm._l((block.data.items),function(item,i){return _c(VList,{key:i,staticClass:"pa-0"},[_c(VListItem,[_c(VListItemContent,[_c('div',{domProps:{"innerHTML":_vm._s(item)}})])],1)],1)}),1):(block && block.type === 'paragraph')?_c(VListItemContent,[_c('div',{domProps:{"innerHTML":_vm._s(block.data.text)}})]):(block && block.type === 'linkTool')?_c(VListItemContent,[_c('a',{attrs:{"href":block.data.link.startsWith('http')
                      ? block.data.link
                      : ("https://" + (block.data.link)),"target":"_blank"}},[_vm._v(_vm._s(block.data.link))])]):(block && block.type === 'raw')?_c(VListItemContent,[_vm._v(" "+_vm._s(block.data.html)+" ")]):_c(VListItemContent,[_c('span',{staticStyle:{"color":"red"}},[_vm._v("ERROR WITH DESCRIPTION SECTION")])])],1)}),1):_vm._e()],1)],1),_c(VCardActions,[_c(VSpacer),_c('TrackedButton',{attrs:{"buttonText":"Close","color":"primary","data":{ listing: _vm.selectedListing.listing_uuid },"name":"close-see-more-click","textStyle":""},on:{"click":function($event){_vm.seeMoreDialog = false}}}),(_vm.selectedListing.user_uuid === _vm.user.user_uuid)?_c('TrackedButton',{attrs:{"buttonText":"Remove Interest","color":"primary","data":{ listing: _vm.selectedListing.listing_uuid },"name":"remove-listing-interest-click"},on:{"click":function($event){return _vm.deleteListingUser()}}}):_c('TrackedButton',{attrs:{"buttonText":"Indicate Interest","color":"primary","data":{ listing: _vm.selectedListing.listing_uuid },"name":"indicate-listing-interest-click"},on:{"click":function($event){return _vm.createListingUser()}}})],1)],1):_vm._e()],1),_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"10"}},[_c('h1',{staticClass:"head_text"},[_vm._v("Job Listings")])]),(!_vm.listings.length)?_c(VCol,{attrs:{"cols":"10"}},[_c('h3',{staticClass:"head_text"},[_vm._v(" There are currently no available job listings. ")])]):_vm._e(),_vm._l((_vm.listings),function(l){return _c('ListingCard',{key:l.listing_uuid,attrs:{"listing":l},on:{"showSeeMoreDialog":function($event){_vm.seeMoreDialog = true;
        _vm.selectedListing = $event;}}})})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }