import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"pa-0",attrs:{"height":"100%"}},[_c(VCardTitle,{attrs:{"primary-title":""}},[_c('h1',{staticClass:"head_text"},[_vm._v(_vm._s(_vm.name))])]),_c(VCardText,[_c('TrackedButton',{attrs:{"buttonText":("GO TO " + (_vm.module ? 'MODULE' : 'PATHWAY')),"color":"primary","data":_vm.module
          ? { module: _vm.module.module_uuid }
          : { pathway: _vm.pathway.pathway_uuid },"icon":"mdi-calendar-star","iconClass":"mr-2","iconDark":"","name":((_vm.module ? 'module' : 'pathway') + "-card-click"),"rounded":""},on:{"click":function($event){_vm.module
          ? _vm.$router.push({
              name: 'module-content',
              params: {
                pathway_uuid: _vm.pathway.pathway_uuid,
                module: _vm.module,
                module_uuid: _vm.module.module_uuid,
              },
            })
          : _vm.$router.push({
              name: 'pathway-content',
              params: {
                pathway_uuid: _vm.pathway.pathway_uuid,
                pathway: _vm.pathway,
              },
            })}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }