<template>
  <div class="pa-8" v-if="filteredItems.length">
    <h2 class="base--text px-12 pb-12 pt-md-0 pt-4">
      <span v-if="type === 'class'">
        <span class="orange--text">Skill Path</span> classes
      </span>
      <span v-else><span class="orange--text">Social Group</span> meetups</span>
      <br />
      happening this week
    </h2>
    <v-card
      class="mt-2 mb-12"
      color="light-blue"
      v-for="(item, index) in filteredItems"
      :key="index"
    >
      <v-row>
        <v-col
          class="flex-grow-0 py-0 pr-md-0 d-none d-md-block"
          v-if="item.image"
        >
          <v-img
            :src="item.image"
            width="350"
            height="100%"
            class="grey darken-4 rounded-lg rounded-r-0"
          />
        </v-col>
        <v-col class="py-0 pr-md-0 d-md-none" v-if="item.image" cols="12">
          <v-img
            :src="item.image"
            height="250"
            width="100%"
            class="grey darken-4 rounded-lg rounded-b-0 d-md-none"
          />
        </v-col>
        <v-col
          md="7"
          cols="12"
          class="d-flex flex-column justify-space-between px-14 py-10"
        >
          <div>
            <v-chip class="ma-2" color="light-blue-2" label small>
              <span class="font-weight-medium">{{ item.category }}</span>
            </v-chip>
            <div class="pa-2 pa-0">
              <h3 class="mb-4">{{ item.name }}</h3>
              <p v-if="item.description">
                {{ item.description }}
              </p>
            </div>
          </div>
          <div class="pa-2 pa-0">
            <v-btn color="dark-blue" dark small @click="setupClasstime(item)">
              <span v-if="type === 'class'">Select a class time</span>
              <span v-else>Select a meetup time</span>
              <v-img src="@/assets/svgs/arrow.svg" width="20" class="ml-2" />
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card>
    <div class="text-center mt-16 mb-10" v-if="loadMore">
      <v-btn outlined @click="splitVal += 3">Load more</v-btn>
    </div>
    <v-dialog v-model="open" width="800">
      <v-component
        @close="open = false"
        @register="scheduleClass"
        :loading="loading"
        :is="currentComponent"
        :type="type"
        v-bind="properties"
        v-if="open"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ClassTimeModal from './ClassTimeModal';
import SuccessModal from './SuccessModal';
export default {
  name: 'ClassList',
  props: ['items', 'user', 'type'],
  components: { ClassTimeModal, SuccessModal },
  data() {
    return {
      open: false,
      currentComponent: undefined,
      properties: {},
      loading: false,
      splitVal: 3,
    };
  },
  computed: {
    loadMore: function () {
      return this.items.length >= this.splitVal;
    },
    filteredItems: function () {
      return this.items
        .filter(({ classes }) => classes && classes.length)
        .slice(0, this.splitVal);
    },
  },
  methods: {
    ...mapActions({
      createAppointment: 'meetings/createAppointment',
      cancelAppointment: 'meetings/cancelAppointment',
    }),
    setupClasstime(item) {
      this.currentComponent = ClassTimeModal;
      this.properties = { item };
      this.open = true;
    },
    async scheduleClass(item) {
      this.loading = true;
      const payload = {
        appointmentTypeID: item.appointmentTypeID.toString(),
        datetime: item.time,
        email: this.user.email,
        firstName: this.user.profile.first_name,
        lastName: this.user.profile.last_name,
        type: this.type,
      };
      try {
        await this.createAppointment(payload);
        this.properties = { item, type: this.type };
        this.currentComponent = SuccessModal;
        this.open = true;
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    cancel(id) {
      // console.log(id);
    },
  },
};
</script>
