<template>
  <v-card>
    <div class="d-flex flex-column flex-md-row">
      <v-btn icon absolute top right @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-img
        v-if="meeting.metadata.acuity_image"
        :src="meeting.metadata.acuity_image"
        width="300"
        class="col-md-4 col-12 d-none d-md-block"
      />
      <v-img
        v-if="meeting.metadata.acuity_image"
        :src="meeting.metadata.acuity_image"
        max-height="200"
        class="col-md-4 col-12 d-block d-md-none"
      />
      <div class="col-md-8 col-12 d-flex flex-column pa-md-12 pa-6">
        <div class="mx-md-6">
          <div>
            <v-chip
              class="ma-2"
              color="light-blue-2"
              label
              small
              v-for="(tag, i) in meeting.tags"
              :key="i"
            >
              <span class="font-weight-medium">
                {{ tag }}
              </span>
            </v-chip>
          </div>
          <div class="pa-2 pa-0">
            <h3 class="mb-4">{{ meeting.subject }}</h3>
            <p v-if="meeting.description && meeting.description.description">
              {{ meeting.description.description }}
            </p>
          </div>
        </div>
        <div class="px-md-8 px-2 mb-16 pb-14" v-if="!showConfirmation">
          <a
            class="text-decoration-underline font-weight-medium text-body-2"
            @click="showConfirmation = true"
          >
            Cancel registration
          </a>
        </div>
        <div v-else-if="showSuccess" class="mx-md-8 mx-2 mt-6">
          <v-chip color="green" label>
            <v-icon color="base" small>mdi-check</v-icon>
            <span class="font-weight-medium ml-2 text-body-2">
              Your registration has been cancelled.
            </span>
          </v-chip>
        </div>
        <div v-else>
          <v-card outlined class="pa-md-8 pa-4 border-orange">
            Are you sure you want to cancel?
            <div class="d-flex mt-6 align-center">
              <v-btn
                color="orange"
                small
                @click="cancel()"
                dark
                class="mr-6"
                :disabled="loading"
              >
                Yes, please cancel
              </v-btn>
              <a
                class="text-decoration-underline font-weight-medium text-body-2"
                @click="showConfirmation = false"
              >
                No, don’t cancel my registration
              </a>
            </div>
          </v-card>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'EditRegistration',
  props: ['meeting', 'user'],
  data() {
    return {
      showConfirmation: false,
      showSuccess: false,
      loading: false,
    };
  },
  computed: {
    meetingID: function () {
      return this.meeting.guests.find(
        ({ user_uuid }) => user_uuid === this.user.user_uuid
      ).appointment_id;
    },
  },
  methods: {
    ...mapActions({
      cancelAppointment: 'meetings/cancelAppointment',
      addError: 'gloablErrors/addError',
    }),
    async cancel() {
      this.loading = true;
      await this.cancelAppointment(this.meetingID)
        .then(() => (this.showSuccess = true))
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style scoped>
.border-orange {
  border-color: #e08174;
}
</style>
