import { userChannel } from '@/userChannel';

const state = {
  errors: [],
};

const actions = {
  async listen({ commit, state }) {
    userChannel.channel.on('global_error', (data) => {
      commit('addError', data.message);
    });
  },

  async clearErrors({ commit }) {
    commit('clearErrors');
  },
};

const modules = {};

const mutations = {
  addError(state, error) {
    state.errors.push(error);
  },

  clearErrors(state) {
    state.errors = [];
  },
};

export const globalErrors = {
  state,
  actions,
  mutations,
  modules,
  namespaced: true,
};
