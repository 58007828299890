<template>
  <v-col cols="12" md="6">
    <v-card class="px-6 py-3">
      <v-card-title primary-title>
        <h2 class="head_text text-h2 font-weight-bold">Goal Center</h2>
      </v-card-title>
      <v-card-text>
        <p class="base--text">
          Here you will be able to set your goals, get personalized content
          matched to those goals, and access that content. We hope this will
          become your daily hub for all things Daivergent.
        </p>
        <p class="base--text">
          Currently you have not been assigned to any goals.
        </p>
        <p class="base--text">
          Get in touch with us on chat (button on bottom-right) if you would
          like to get access.
        </p>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
export default {
  name: 'GoalCenterEmpty',
};
</script>
