<template>
  <div>
    <about-classes />
    <v-skeleton-loader
      v-if="loading"
      type="image, article, actions"
      class="mx-8 mb-6"
    />
    <class-list
      v-else-if="skill_path_classes"
      :items="skill_path_classes"
      :user="user"
      type="class"
    />
  </div>
</template>

<script>
import AboutClasses from '@/components/classes/AboutClasses.vue';
import ClassList from '@/components/classes/ClassList.vue';
import { mapState } from 'vuex';

export default {
  name: 'Classes',
  components: { AboutClasses, ClassList },
  computed: {
    ...mapState({
      skill_path_classes: (state) => state.meetings.skill_path_classes,
      user: (state) => state.user.user,
      loading: (state) => state.meetings.loading,
    }),
  },
};
</script>

<style scoped></style>
