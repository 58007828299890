<template>
  <v-row justify="center">
    <v-col cols="12">
      <div v-html="content.rich_text"></div>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios';
export default {
  props: ['content'],
  data() {
    return {};
  },
};
</script>

<style></style>
