import { domainSocket } from '@/socket';

class UserChannel {
  constructor() {
    this.channel;
  }

  join_channel(user_uuid) {
    this.channel = domainSocket.join_channel(user_uuid, 'user');
  }
}

export const userChannel = new UserChannel();
