import axios from 'axios';
import { filter, sortBy, unset } from 'lodash';
import { getFutureMeetings } from '@/helpers/meetings';

const state = {
  loading: false,
  meetings: undefined,
  recurrences: undefined,
  signature: undefined,
  sdk: undefined,
  social_groups: undefined,
  skill_path_classes: undefined,
};

const getters = {
  loading: (state) => {
    return state.loading;
  },
  meetings: (state) => {
    return state.meetings;
  },
  schedule: (state) => {
    // returns list of recurrences ++ meetings which are ordered by scheduled_start
    const recurrences = state.recurrences || [];
    let orderedNonRecurringMeetings = [];
    if (state.meetings) {
      const nonRecurringMeetings = filter(
        state.meetings,
        (m) => !m.recurrence_uuid
      );
      orderedNonRecurringMeetings = sortBy(nonRecurringMeetings, [
        'scheduled_start',
      ]);
    }
    return recurrences.concat(orderedNonRecurringMeetings);
  },
  futureMeetings: (state) => {
    if (state.meetings) {
      return getFutureMeetings(state.meetings);
    }
    return undefined;
  },
};

const actions = {
  async fetchRecurrences({ commit }) {
    await axios
      .get('/api/recurrence')
      .then((res) => commit('cache_recurrences', res.data));
  },
  async fetchMeetings({ commit }) {
    await axios
      .get('/api/meeting')
      .then((res) => commit('cache_meetings', res.data));
  },
  async fetchSocialGroups({ commit }) {
    try {
      const { data } = await axios.get('/api/meeting/social_groups');
      commit('cache_social_groups', data);
    } catch {
      commit('cache_social_groups', []);
    }
  },
  async fetchClasses({ commit }) {
    try {
      const { data } = await axios.get('/api/meeting/skill_path_classes');
      commit('cache_skill_path_classes', data);
    } catch {
      commit('cache_skill_path_classes', []);
    }
  },
  async init({ dispatch, commit }) {
    commit('cache_loading', true);
    await dispatch('fetchMeetings');
    await dispatch('fetchRecurrences');
    await dispatch('fetchClasses');
    await dispatch('fetchSocialGroups');
    commit('cache_loading', false);
    return;
  },
  async createAppointment({ dispatch }, payload) {
    // commit('cache_loading', true);
    const payloadClone = payload;
    unset(payloadClone, 'type');
    try {
      await axios.post('/api/meeting/register', payload);
      if (payload.type === 'class') await dispatch('fetchClasses');
      if (payload.type === 'social') await dispatch('fetchSocialGroups');
      await dispatch('fetchMeetings');
    } catch (err) {
      return err;
    }
    // commit('cache_loading', false);
  },
  async cancelAppointment({ dispatch }, id) {
    // commit('cache_loading', true);
    await axios.delete(`/api/meeting/${id}`);
    await dispatch('fetchMeetings');
    await dispatch('fetchSocialGroups');
    await dispatch('fetchMeetings');
    // commit('cache_loading', false);
  },
};

const modules = {};

const mutations = {
  cache_loading(_state, loading) {
    _state.loading = loading;
  },
  cache_meetings(_state, meetings) {
    _state.meetings = meetings;
  },
  cache_recurrences(_state, recurrences) {
    _state.recurrences = recurrences;
  },
  cache_social_groups(_state, social_groups) {
    _state.social_groups = social_groups;
  },
  cache_skill_path_classes(_state, skill_path_classes) {
    _state.skill_path_classes = skill_path_classes;
  },
};

export const meetings = {
  actions,
  getters,
  mutations,
  modules,
  namespaced: true,
  state,
};
