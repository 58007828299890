<template>
  <div>
    <b>{{ title }}</b>
    <div v-html="value" />
  </div>
</template>
<script>
export default {
  name: 'NoteEditor',
  props: ['title', 'value'],
  data: () => {
    return {};
  },
};
</script>
<style scoped></style>
