<template>
  <div>
    <b>{{ title }}</b>
    <p>
      {{ value }}
    </p>
  </div>
</template>
<script>
export default {
  name: 'NoteRadiogroup',
  props: ['title', 'value'],
  data: () => {
    return {};
  },
};
</script>
<style scoped></style>
