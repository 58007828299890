<template>
  <v-container id="pathway" fluid class="pa-8">
    <v-row v-if="pathway">
      <v-col md="12">
        <TrackedButton
          @click="
            $router.push({
              name: 'goals',
            })
          "
          buttonText="Back to Goals"
          color="primary"
          name="back-to-goal-center-click"
          small
          textStyle
        />
      </v-col>
    </v-row>
    <v-row v-if="pathway && pathway.strapi_data">
      <v-col md="12">
        <h1 class="head_text">
          {{ pathway.strapi_data.name }}
        </h1>
      </v-col>
      <v-col md="12">
        <v-row>
          <v-col
            md="12"
            v-for="(content_block, i) in pathway.strapi_data.content"
            :key="i"
          >
            <RichText
              :content="content_block"
              v-if="content_block.__component === 'content.rich-text'"
            ></RichText>
            <OEmbed
              :content="content_block"
              v-else-if="
                content_block.__component === 'content.video' &&
                content_block.oembed
              "
            ></OEmbed>
            <Slider
              :content="content_block"
              v-if="content_block.__component === 'content.slider'"
            ></Slider>
            <StrapiFile
              :content="content_block"
              v-if="content_block.__component === 'content.file'"
            ></StrapiFile>
          </v-col>
          <v-divider></v-divider>
          <v-row
            v-if="pathway && pathway.sequence_items"
            class="fit-card mx-auto"
          >
            <v-col
              v-for="(item, i) in pathway.sequence_items"
              :key="i"
              cols="12"
              sm="12"
              md="6"
            >
              <div
                class="card-wrapper card-img-fit"
                v-if="
                  item.strapi_data &&
                  item.strapi_data.cards[0] &&
                  item.strapi_data.cards[0].image
                "
              >
                <card-with-image
                  :card="item.strapi_data.cards[0]"
                  :pathway="item.pathway_uuid ? item : pathway"
                  :module="item.pathway_uuid ? null : item"
                  v-on:start-module-user="startAndGoToModuleUser"
                  v-on:continue-module-user="goToModuleUser"
                  v-on:create-module-user="createAndGoToModuleUser"
                  v-on:resume-module-user="resumeAndGoToModuleUser"
                  v-on:start-pathway-user="startAndGoToPathwayUser"
                  v-on:continue-pathway-user="goToPathwayUser"
                  v-on:create-pathway-user="createAndGoToPathwayUser"
                  v-on:resume-pathway-user="resumeAndGoToPathwayUser"
                />
              </div>
              <div
                class="card-wrapper card-img-fit"
                v-else-if="item.strapi_data && item.strapi_data.cards[0]"
              >
                <card-no-image
                  :card="item.strapi_data.cards[0]"
                  :pathway="item.pathway_uuid ? item : pathway"
                  :module="item.pathway_uuid ? null : item"
                  v-on:start-module-user="startAndGoToModuleUser"
                  v-on:continue-module-user="goToModuleUser"
                  v-on:create-module-user="createAndGoToModuleUser"
                  v-on:resume-module-user="resumeAndGoToModuleUser"
                  v-on:start-pathway-user="startAndGoToPathwayUser"
                  v-on:continue-pathway-user="goToPathwayUser"
                  v-on:create-pathway-user="createAndGoToPathwayUser"
                  v-on:resume-pathway-user="resumeAndGoToPathwayUser"
                />
              </div>
              <div class="card-wrapper card-img-fit" v-else>
                <fallback-card
                  :pathway="item.pathway_uuid ? item : pathway"
                  :module="item.pathway_uuid ? null : item"
                />
              </div>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col class="pl-12" md="12">
              <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { chain, find, get, isEmpty } from 'lodash';
import { mapActions, mapState } from 'vuex';
import CardWithImage from '@/components/strapi/CardWithImage.vue';
import CardNoImage from '@/components/strapi/CardNoImage.vue';
import FallbackCard from '@/components/strapi/FallbackCard.vue';
import RichText from '@/components/strapi/RichText.vue';
import OEmbed from '@/components/strapi/OEmbed.vue';
import Slider from '@/components/strapi/Slider.vue';
import StrapiFile from '@/components/strapi/StrapiFile.vue';
import TrackedButton from '@/components/TrackedButton.vue';
import pathway from '@/helpers/pathway';
import moduleHelpers from '@/helpers/moduleHelpers';

export default {
  components: {
    CardWithImage,
    CardNoImage,
    FallbackCard,
    RichText,
    OEmbed,
    Slider,
    StrapiFile,
    TrackedButton,
  },
  data() {
    return {
      pathway_user: null,
    };
  },
  computed: {
    ...mapState({
      pathways: (state) => state.pathways.pathways,
    }),
    pathway: function () {
      if (this.$route.params.pathway) {
        return this.$route.params.pathway;
      } else if (this.pathways) {
        return this.getPathway(this.$route.params.pathway_uuid);
      } else {
        return null;
      }
    },
  },
  watch: {
    pathway: function () {
      const vm = this;
      if (vm.pathway && !vm.pathway_user) {
        vm.getPathwayUser(vm.pathway.pathway_uuid);
      }
    },
  },
  async created() {
    const vm = this;
    if (!!vm.$route.params.pathway_user) {
      vm.pathway_user = vm.$route.params.pathway_user;
    }
  },
  methods: {
    ...mapActions({
      addPathwayUser: 'pathways/addPathwayUser',
      replacePathwayUser: 'pathways/replacePathwayUser',
      addModuleUser: 'pathways/addModuleUser',
      replaceModuleUser: 'pathways/replaceModuleUser',
    }),

    getPathway(pathway_uuid) {
      const pathways = get(this, 'pathways', []);
      return find(pathways, (p) => p.pathway_uuid === pathway_uuid);
    },
    async getPathwayUser(pathway_uuid) {
      const vm = this;
      if (vm.pathway) {
        const pathway_users = get(vm, 'pathway.pathway_users', []);
        if (isEmpty(pathway_users)) {
          const { status } = await pathway.createPathwayUser(pathway_uuid);
          if (status == 201) {
            const { data } = await pathway.startPathwayUser(
              data.pathway_user_uuid
            );
            this.addPathwayUser(data);
          }
        } else {
          vm.pathway_user = chain(pathway_users)
            .sortBy('status_timestamp')
            .last()
            .value();
        }
      }
    },
    routeToPathway(pathway_uuid, pathway_user_uuid) {
      /*
      TODO:
      Plan out nested pathways so that a user can route to pathways within pathways
      and trace/navigate back to parent pathways just as they can on the Module page.
      Current code just attempts to reduntantly navigate to the same pathway if an identical
      pathway exists under it and is clicked.
       **/
      const current_pathway = find(this.pathways, { pathway_uuid });
      const current_pathway_user = find(current_pathway.pathway_users, {
        pathway_user_uuid,
      });
      this.$router.push({
        name: 'pathway-content',
        params: {
          pathway_uuid: current_pathway.pathway_uuid,
          pathway: current_pathway,
          pathway_user_uuid: pathway_user_uuid,
          pathway_user: current_pathway_user,
        },
      });
    },
    goToPathwayUser(event) {
      this.routeToPathway(event.pathway_uuid, event.pathway_user_uuid);
    },
    async resumeAndGoToPathwayUser(event) {
      try {
        const { data } = await pathway.resumePathwayUser(
          event.pathway_user_uuid
        );
        this.replacePathwayUser(data);
        this.routeToPathway(event.pathway_uuid, event.pathway_user_uuid);
      } catch (err) {}
    },
    async startAndGoToPathwayUser(event) {
      try {
        const { data } = await pathway.startPathwayUser(
          event.pathway_user_uuid
        );
        this.replacePathwayUser(data);
        this.routeToPathway(event.pathway_uuid, event.pathway_user_uuid);
      } catch (err) {}
    },
    async createAndGoToPathwayUser(event) {
      try {
        const { status } = await pathway.createPathwayUser(event.pathway_uuid);
        if (status == 201) {
          const { data } = await pathway.startPathwayUser(
            data.pathway_user_uuid
          );
          this.addPathwayUser(data);
          this.routeToPathway(event.pathway_uuid, data.pathway_user_uuid);
        }
      } catch (err) {}
    },
    routeToModule(
      module_uuid,
      module_user_uuid,
      pathway_uuid,
      pathway_user_uuid
    ) {
      const sequence_items = get(this.pathway, 'sequence_items', []);
      const current_module = find(sequence_items, { module_uuid });
      const current_module_user = find(current_module.module_users, {
        module_user_uuid,
      });
      this.$router.push({
        name: 'module-content',
        params: {
          pathway_uuid,
          pathway_user_uuid,
          module_uuid: module_uuid,
          module: current_module,
          module_user: current_module_user,
        },
      });
    },
    goToModuleUser(event) {
      this.routeToModule(
        event.module_uuid,
        event.module_user_uuid,
        event.pathway_uuid,
        event.pathway_user_uuid
      );
    },
    async resumeAndGoToModuleUser(event) {
      try {
        const { data } = await moduleHelpers.resumeModuleUser(
          event.module_user_uuid
        );
        this.replaceModuleUser(data);
        this.routeToModule(
          event.module_uuid,
          event.module_user_uuid,
          event.pathway_uuid,
          event.pathway_user_uuid
        );
      } catch (err) {
        // console.error(err);
      }
    },
    async startAndGoToModuleUser(event) {
      try {
        const { data } = await moduleHelpers.startModuleUser(
          event.module_user_uuid
        );
        this.replaceModuleUser(data);
        this.routeToModule(
          event.module_uuid,
          event.module_user_uuid,
          event.pathway_uuid,
          event.pathway_user_uuid
        );
      } catch (err) {
        // console.error(err);
      }
    },

    async createAndGoToModuleUser(event) {
      try {
        const { status, data } = await moduleHelpers.createModuleUser(
          event.module_uuid,
          event.pathway_user_uuid || this.pathway_user.pathway_user_uuid
        );
        if (status == 201) {
          const { data: startData } = await moduleHelpers.startModuleUser(
            data.module_user_uuid
          );
          this.addModuleUser(startData);
          this.routeToModule(
            event.module_uuid,
            data.module_user_uuid,
            event.pathway_uuid,
            event.pathway_user_uuid
          );
        }
      } catch (err) {
        // console.error(err);
      }
    },
  },
};
</script>
<style scoped>
.head_text {
  word-break: break-word;
}

.card-wrapper {
  height: 100%;
}

.card-img-fit .v-responsive {
  max-width: 200px !important;
  height: auto;
}

.row.fit-card {
  max-width: 100%;
}
</style>
