<template>
  <div>
    <b>{{ title }}</b>
    <p>{{ formattedValue }}</p>
  </div>
</template>
<script>
export default {
  name: 'NoteText',
  props: ['title', 'value', 'inputType'],
  computed: {
    formattedValue() {
      return this.inputType === 'datetime-local'
        ? this.$options.filters.formatDateTime(`${this.value.slice(0, 16)}:00Z`)
        : this.value;
    },
  },
};
</script>
<style scoped></style>
