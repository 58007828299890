<template>
  <v-container fluid class="mt-6">
    <v-row justify="center">
      <v-col md="5" class="pr-0">
        <v-row>
          <v-col md="12">
            <h1 class="head_text">Build valuable skills in a group setting.</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12">
            <p>
              Taught by Daivergent coaches, Skill Path classes provide useful
              training for real-world situations for work, home, and life. Sign
              up for as many classes as you'd like &mdash; just make sure you
              can attend before you sign up. After registering, you'll receive a
              confirmation email with a virtual classroom link that will be open
              15 minutes before the start of class.
            </p>
          </v-col>
        </v-row>
      </v-col>
      <v-col md="5" class="pl-0">
        <v-row>
          <v-col md="12">
            <v-img
              src="/img/skillpathclasses/hero-drop-in.png"
              contain
              height="250"
            ></v-img>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="mt-0" justify="center">
      <v-col>
        <iframe
          class="frame"
          width="1100"
          height="800"
          src="https://daivergent-social.as.me/skill-path-class-signup"
        ></iframe>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({}),
};
</script>
<style scoped>
.frame {
  border-width: 0px;
}

.head_text {
  word-break: break-word;
}
</style>
