export const getFutureMeetings = (meetings) => {
  const future = meetings.filter(
    (meeting) => Date.parse(meeting.scheduled_finish) > new Date()
  );
  return future.sort((meeting_a, meeting_b) => {
    return (
      new Date(meeting_a.scheduled_start) - new Date(meeting_b.scheduled_start)
    );
  });
};
