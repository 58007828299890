import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"pa-4",attrs:{"height":"100%"}},[_c(VCardTitle,{staticClass:"pb-0",attrs:{"primary-title":""}},[_c('h1',{staticClass:"head_text"},[_vm._v("Social Groups")])]),(_vm.pathName === '/')?_c(VCardText,[_c(VRow,{staticClass:"pt-6"},[_c(VCol,{staticClass:"text-center",attrs:{"cols":"12","xs":"12","sm":"3","md":"2","justify":"center"}},[_c(VImg,{attrs:{"src":require("@/img/card-images/social-groups.svg"),"max-width":"200","contain":""}})],1),_c(VCol,{attrs:{"cols":"12","xs":"12","sm":"9","md":"10"}},[_c(VRow,[_c('p',{staticClass:"subheading"},[_vm._v(" "+_vm._s(_vm.cardText)+" ")])]),_c(VRow,{staticClass:"pt-2"},[_c('p',[_c('TrackedButton',{attrs:{"buttonText":"Social Groups","color":"primary","icon":"mdi-account-group","iconClass":"mr-2","iconDark":"","name":"social-groups-card-click","rounded":""},on:{"click":function($event){return _vm.$router.push({ name: 'socialgroups' })}}})],1)])],1)],1)],1):_c(VCardText,[_c('p',[_c(VImg,{attrs:{"src":require("@/img/card-images/social-groups.svg"),"width":"300","height":"300","contain":""}})],1),_c('p',[_vm._v(" "+_vm._s(_vm.cardText)+" ")]),_c('p',[_c('TrackedButton',{attrs:{"buttonText":"Social Groups","color":"primary","icon":"mdi-account-group","iconClass":"mr-2","iconDark":"","name":"social-groups-card-click","rounded":""},on:{"click":function($event){return _vm.$router.push({ name: 'socialgroups' })}}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }