<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col md="6">
        <v-card class="pt-4">
          <v-row>
            <v-col md="6">
              <v-card-text>
                <v-text-field
                  v-model="fields.firstName.value"
                  clearable
                  :label="fields.firstName.label"
                  :placeholder="fields.firstName.label"
                  persistent-placeholder
                />
              </v-card-text>
              <v-card-text>
                <v-text-field
                  v-model.trim="fields.lastName.value"
                  clearable
                  :label="fields.lastName.label"
                  :placeholder="fields.lastName.label"
                  persistent-placeholder
                />
              </v-card-text>
              <v-card-text>
                <v-text-field
                  v-model="fields.dateOfBirth.value"
                  clearable
                  :label="fields.dateOfBirth.label"
                  :placeholder="fields.dateOfBirth.placeholder"
                  persistent-placeholder
                  type="date"
                />
              </v-card-text>
              <v-card-text>
                <v-text-field
                  v-model.trim="fields.email.value"
                  clearable
                  :label="fields.email.label"
                  :placeholder="fields.email.label"
                  persistent-placeholder
                  disabled
                />
              </v-card-text>
              <v-card-text>
                <v-text-field
                  v-model="fields.password.value"
                  clearable
                  :label="fields.password.label"
                  :placeholder="fields.password.label"
                  persistent-placeholder
                />
                <b>Password must:</b>
                <ul>
                  <li>Be at least 8 characters long.</li>
                  <li>Include a lower case letter.</li>
                  <li>Include an upper case letter.</li>
                  <li>Include a number.</li>
                </ul>
              </v-card-text>
              <v-card-text v-if="showConsentToShare" class="py-0">
                <v-switch
                  v-model="fields.consentToShare.value"
                  :label="fields.consentToShare.label"
                />
              </v-card-text>
              <v-card-text class="py-0">
                <v-switch
                  v-model="fields.agreeToTerms.value"
                  :label="fields.agreeToTerms.label"
                />
                <p>
                  Please
                  <a @click="confirmDialog = true">click here</a> to view Terms
                  of Service
                </p>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="primary"
                  @click="intakeUser"
                  :disabled="!submitButtonEnabled"
                  >Submit</v-btn
                >
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="confirmDialog" width="800">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title
          >Terms of Service</v-card-title
        >
        <TermsOfService />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios';
import { get } from 'lodash';
import TermsOfService from './TermsOfService.vue';
import moment from 'moment-timezone';
export default {
  name: 'Intake',
  components: { TermsOfService },
  data: () => ({
    confirmDialog: false,
    defaultTimezone: moment.tz.guess(),
    fields: {
      firstName: {
        label: 'Client First Name',
        value: null,
      },
      lastName: {
        label: 'Client last Name',
        value: null,
      },
      dateOfBirth: {
        label: 'Date of Birth',
        placeholder: 'MM/DD/YYYY',
        value: null,
      },
      email: {
        label: 'Client Email',
        value: null,
      },
      timezone: {
        label: 'Client Timezone',
        value: null,
        items: moment.tz.zonesForCountry('US'),
      },
      password: {
        label: 'Client Password',
        value: null,
      },
      agreeToTerms: {
        label: 'Agree to Terms?',
        value: false,
      },
      consentToShare: {
        label:
          'Is it okay for us to share info on your progress with your designated parent or champion?',
        value: true,
      },
      userUuid: null,
      championUserUuid: null,
    },
  }),
  computed: {
    showConsentToShare: function () {
      return !!this.championUserUuid;
    },
    submitButtonEnabled: function () {
      return (
        this.fields.agreeToTerms.value &&
        this.fields.dateOfBirth.value &&
        this.fields.timezone.value &&
        this.passwordIsValid
      );
    },
    passwordIsValid: function () {
      const val = this.fields.password.value;
      return (
        val &&
        val.length >= 8 &&
        !!val.match(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])^[^ ]+$/)
      );
    },
  },
  created() {
    const user_data = this.$cookies.get('user_data');
    if (user_data) {
      const decoded = JSON.parse(atob(user_data));
      this.userUuid = get(decoded, 'user_uuid');
      this.fields.email.value = get(decoded, 'email');
      this.championUserUuid = get(decoded, 'champion_user_uuid');
      this.fields.firstName.value = get(decoded, 'profile.first_name');
      this.fields.lastName.value = get(decoded, 'profile.last_name');
      this.fields.timezone.value = this.fields.timezone.items.includes(
        this.defaultTimezone
      )
        ? this.defaultTimezone
        : get(decoded, 'profile.timezone');
    }
  },
  methods: {
    async intakeUser() {
      await axios
        .post('/intake', {
          email: this.fields.email.value,
          password: this.fields.password.value,
          name: `${this.fields.firstName.value} ${this.fields.lastName.value}`,
        })
        .then(({ data }) => {
          if (data.open_id) {
            this.$cookies.set(
              'intake_data',
              {
                user_uuid: this.userUuid,
                open_id: data.open_id,
                email: this.fields.email.value,
                profile: {
                  agree_to_terms: this.fields.agreeToTerms.value,
                  consent_to_share:
                    this.showConsentToShare && this.fields.consentToShare.value,
                  first_name: this.fields.firstName.value,
                  last_name: this.fields.lastName.value,
                  date_of_birth: this.fields.dateOfBirth.value,
                  timezone: this.fields.timezone.value,
                },
              },
              '1d'
            );
            if (this.championUserUuid && this.fields.consentToShare.value)
              this.$cookies.set(
                'champion_user_uuid',
                this.championUserUuid,
                '1d'
              );
            this.$router.push({
              name: 'home',
            });
          }
        });
    },
  },
};
</script>
<style scoped>
.head_text {
  word-break: break-word;
}
</style>
