import Vue from 'vue';
import createAuth0Client from '@auth0/auth0-spa-js';
import axios from 'axios';
import { domainSocket } from '@/socket';
import { userChannel } from '@/userChannel';
import store from '@/store';
import router from '@/router/index';
import mixpanel from 'mixpanel-browser';

/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

let instance;

const setupUser = async (data, token) => {
  store.dispatch('init_all');
  await domainSocket.connect(token);
  await userChannel.join_channel(data.user_uuid);
  store.dispatch('globalErrors/listen');
  mixpanel.identify(data.user_uuid);
  gtag('config', 'G-5K1LWYMKYJ', {
    user_id: data.user_uuid,
  });
  if (data.intercom_id) {
    window.Intercom('boot', {
      app_id: 'u1eumtke',
      user_id: data.user_uuid,
    });
  }
};

/** Returns the current instance of the SDK */
export const getInstance = () => instance;

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
}) => {
  if (instance) return instance;

  // The 'instance' is simply a Vue object
  instance = new Vue({
    data() {
      return {
        loading: true,
        isAuthenticated: false,
        user: {},
        auth0Client: null,
        popupOpen: false,
        error: null,
      };
    },
    methods: {
      /** Authenticates the user using a popup window */
      async loginWithPopup(o) {
        this.popupOpen = true;

        try {
          await this.auth0Client.loginWithPopup(o);
        } catch (e) {
          // eslint-disable-next-line
          // console.error(e);
        } finally {
          this.popupOpen = false;
        }

        this.user = await this.auth0Client.getUser();
        this.isAuthenticated = true;
      },
      /** Handles the callback when logging in using a redirect */
      async handleRedirectCallback() {
        this.loading = true;
        try {
          await this.auth0Client.handleRedirectCallback();
          this.user = await this.auth0Client.getUser();
          this.isAuthenticated = true;
        } catch (e) {
          this.error = e;
        } finally {
          this.loading = false;
        }
      },
      /** Authenticates the user using the redirect method */
      loginWithRedirect(o) {
        return this.auth0Client.loginWithRedirect(o);
      },
      /** Returns all the claims present in the ID token */
      getIdTokenClaims(o) {
        return this.auth0Client.getIdTokenClaims(o);
      },
      /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
      getTokenSilently(o) {
        return this.auth0Client.getTokenSilently(o);
      },
      /** Gets the access token using a popup window */

      getTokenWithPopup(o) {
        return this.auth0Client.getTokenWithPopup(o);
      },
      /** Logs the user out and removes their session on the authorization server */
      logout(o) {
        return this.auth0Client.logout(o);
      },
    },
    /** Use this lifecycle method to instantiate the SDK client */
    async created() {
      // Create a new instance of the SDK client using members of the given options object

      // fetch environment-specific auth0 vars
      const { domain, client_id, audience } = await axios
        .get('/auth0')
        .then(({ data }) => {
          return data;
        });
      this.auth0Client = await createAuth0Client({
        domain: domain,
        client_id: client_id,
        audience: audience,
        redirect_uri: redirectUri,
      });

      try {
        // If the user is returning to the app after authentication..
        if (
          window.location.search.includes('code=') &&
          window.location.search.includes('state=')
        ) {
          // handle the redirect and retrieve tokens
          const { appState } = await this.auth0Client.handleRedirectCallback();

          // Notify subscribers that the redirect callback has happened, passing the appState
          // (useful for retrieving any pre-authentication state)
          onRedirectCallback(appState);
        }
      } catch (e) {
        this.error = e;
      } finally {
        // Initialize our internal authentication state
        this.isAuthenticated = await this.auth0Client.isAuthenticated();
        if (this.isAuthenticated) {
          //yes, they are auth0 user, let's check db
          this.user = await this.auth0Client.getUser(); //this was before the isAuthenticated check before, don't know why
          store.dispatch('user/set_auth_user', this.user);
          // Set bearer token, connect to websocket, and make initial API calls
          const token = await this.auth0Client.getTokenSilently();
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          try {
            //intake_data (and potentially champion_user_uuid) cookies will be set if this is their first login post-intake
            const intake_data = $cookies.get('intake_data');
            $cookies.remove('intake_data');
            if (intake_data) {
              await axios
                .post('/api/user/update', intake_data)
                .then(({ data }) => {
                  try {
                    setupUser(data, token);
                  } catch (error) {
                    // console.log(error);
                    // if there's an error with intercom or gtag or an API call from a vuex store, keep going to the current route
                  }
                });
              const champion_user_uuid = $cookies.get('champion_user_uuid');
              $cookies.remove('champion_user_uuid');
              if (champion_user_uuid) {
                axios.post('/api/user/connect_champion_user', {
                  champion_user_uuid: champion_user_uuid,
                });
              }
            } else {
              await axios.get('/api/user').then(({ data }) => {
                try {
                  setupUser(data, token);
                } catch (error) {
                  // console.log(error);
                  // if there's an error with intercom or gtag or an API call from a vuex store, keep going to the current route
                }
              });
            }
          } catch (error) {
            router.push({ name: 'systemerror' });
          }
        } else if (
          window.location.pathname.indexOf('/intake') === -1 &&
          window.location.pathname.indexOf('/error') === -1 &&
          window.location.pathname.indexOf('/expired') === -1
        ) {
          axios.defaults.headers.common['Authorization'] = '';
          domainSocket.close();
          this.loginWithRedirect();
        }
        this.loading = false;
      }
    },
  });

  return instance;
};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const Auth0Plugin = {
  install(Vue, options) {
    Vue.prototype.$auth = useAuth0(options);
  },
};
