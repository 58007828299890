<template>
  <div>
    <about-groups />
    <v-skeleton-loader
      v-if="loading"
      type="image, article, actions"
      class="mx-8 mb-6"
    />
    <class-list
      :items="social_groups"
      v-else-if="social_groups"
      :user="user"
      type="social"
    />
  </div>
</template>

<script>
import AboutGroups from '@/components/classes/AboutGroups.vue';
import ClassList from '@/components/classes/ClassList.vue';
import { mapState } from 'vuex';

export default {
  name: 'Community',
  components: { AboutGroups, ClassList },
  computed: {
    ...mapState({
      social_groups: (state) => state.meetings.social_groups,
      loading: (state) => state.meetings.loading,
      user: (state) => state.user.user,
    }),
  },
};
</script>

<style scoped></style>
