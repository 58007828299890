import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"height":"100%"}},[_c(VRow,{staticClass:"full-height pa-4",attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"order-sm-2 order-md-1 d-flex flex-column justify-space-between",attrs:{"md":"8"}},[_c(VCardTitle,{attrs:{"primary-title":""}},[_c(VCol,{staticClass:"pa-0 d-flex justify-space-between"},[_c('h1',{staticClass:"head_text"},[_vm._v(_vm._s(_vm.card.title))]),(_vm.completed)?_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"green"}},'v-icon',attrs,false),on),[_vm._v(" mdi-checkbox-marked-circle ")])]}}],null,false,1707546215)},[_c('span',[_vm._v("Completed")])]):_vm._e()],1)],1),_c(VCardText,[_c('p',[_vm._v(" "+_vm._s(_vm.card.short_description || '[SHORT DESCRIPTION]')+" ")])]),_c(VCardText,{staticClass:"pt-0"},[_c('TrackedButton',{attrs:{"buttonText":_vm.entityUserData.buttonText,"color":"primary","iconDark":"","name":_vm.entityUserData.eventName + '-click',"small":""},on:{"click":_vm.cardClicked}},[_c(VImg,{staticClass:"ml-2",attrs:{"src":require("@/assets/svgs/arrow.svg"),"width":"20"}})],1)],1)],1),_c(VCol,{staticClass:"order-sm-1 order-md-2",attrs:{"md":"4"}},[_c('div',{staticClass:"img-wrapper"},[_c(VImg,{staticClass:"mr-3 rounded",attrs:{"width":"100%","height":"100%","src":_vm.card.image.url}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }