<template>
  <v-card :class="{ 'pa-12': !datetime }">
    <v-btn icon absolute top right @click="$emit('close')" v-if="!datetime">
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <div :class="{ 'mx-6': !datetime }">
      <h2>{{ meeting.subject }}</h2>
      <p v-if="!datetime">
        {{ meeting.description.description }}
      </p>
    </div>
    <v-card color="light-blue py-6 px-8 my-2" :class="{ 'mt-4': datetime }">
      <v-row class="align-center justify-space-between">
        <div class="font-weight-medium">
          <v-row class="align-center">
            <v-col class="flex-grow-0 flex-shrink-1">
              <v-img src="@/assets/svgs/video.svg" width="30" />
            </v-col>
            <v-col class="flex-grow-1 flex-shrink-0">
              <span :class="{ 'text-caption': datetime }">
                {{ meeting.scheduled_start | formatDateExt }}
                <span v-if="!datetime">/</span> <br v-else />
                {{ meeting.scheduled_start | formatTime }} -
                {{ meeting.scheduled_finish | formatTime }}
                {{ meeting.scheduled_finish | formatTimezone }}
              </span>
              <div v-if="isVR && isSubstitute" class="text-caption mt-2">
                <span
                  >Your coach for this session is
                  {{ meeting.organizers[0].first_name }}
                  {{ meeting.organizers[0].last_name }}</span
                >
              </div>
            </v-col>
          </v-row>
        </div>
        <v-btn
          color="grape"
          dark
          @click="joinMeeting()"
          small
          v-if="meeting.link"
        >
          <span v-if="meeting.format === 'acuity'">
            <span
              v-if="
                meeting.metadata &&
                meeting.metadata.acuity_calendar === 'social_groups'
              "
            >
              Join Meetup
            </span>
            <span v-else>Join Class</span>
          </span>
          <span v-else>Join Meeting</span>
          <v-img src="@/assets/svgs/arrow.svg" width="20" class="ml-2" />
        </v-btn>
      </v-row>
    </v-card>
  </v-card>
</template>

<script>
export default {
  name: 'MeetingDetails',
  props: ['meeting', 'datetime'],
  computed: {
    isVR: function () {
      return (
        this.meeting.metadata &&
        this.meeting.metadata.service_code &&
        this.meeting.metadata.state
      );
    },
    isSubstitute: function () {
      return this.meeting.tags && this.meeting.tags.includes('substitute');
    },
  },
  methods: {
    joinMeeting() {
      window.open(this.meeting.link, '_blank');
    },
  },
};
</script>
