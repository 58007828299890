<template>
  <v-container id="community-center" fluid>
    <v-row justify="center">
      <v-col md="10">
        <v-row>
          <v-col md="12">
            <h1 class="head_text">Social</h1>
          </v-col>
          <v-col cols="12" sm="10" md="4">
            <ForumCard />
          </v-col>
          <v-col cols="12" sm="10" md="4">
            <SocialGroupsCard />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ForumCard from '@/components/homepage/ForumCard';
import SocialGroupsCard from '@/components/homepage/SocialGroupsCard';
export default {
  components: {
    ForumCard,
    SocialGroupsCard,
  },
  data: () => ({}),
  computed: {},
  methods: {},
};
</script>
<style scoped>
.head_text {
  word-break: break-word;
}
</style>
