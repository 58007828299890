<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col md="10">
        <v-container fluid>
          <v-row justify="center">
            <v-col md="12">
              <h1>System Error</h1>
            </v-col>
            <v-col sm="12">
              <v-row row wrap>
                <v-col xs="12" sm="3">
                  <p>
                    <v-img
                      src="@/img/system-error.svg"
                      alt="surveys"
                      contain
                      max-width="350"
                    />
                  </p>
                </v-col>
                <v-col xs="12" sm="9">
                  <p class="mt-3">
                    Our system is experiencing problems. Please try back later
                    or contact
                    <a href="mailto:help@daivergent.com">help@daivergent.com</a>
                    if you need assistance.
                  </p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'SystemError',
};
</script>
<style lang="scss" scoped></style>
