import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":_vm.persistent,"width":_vm.width || 500},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline grey lighten-2",attrs:{"primary-title":""}},[_vm._v(" "+_vm._s(_vm.note.title)+" ")]),_vm._l((_vm.note.domainNoteElements),function(element,e){return _c(VCardText,{key:e,staticClass:"mt-3"},[_c(_vm.noteComponents[element.type],_vm._b({tag:"v-component"},'v-component',element,false))],1)}),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c('TrackedButton',{attrs:{"buttonText":"Cancel","color":"primary","name":_vm.cancelActionName,"textStyle":""},on:{"click":function($event){return _vm.emitCancel()}}})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }