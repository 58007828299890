import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":_vm.persistent,"width":_vm.width || 500},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline grey lighten-2",attrs:{"primary-title":""}},[_vm._v(_vm._s(_vm.title))]),_c(VCardText,{staticClass:"mt-3"},[_vm._v(" "+_vm._s(_vm.confirmationText)+" ")]),(_vm.requireReason)?_c('div',[_c('br'),_c(VCardText,{staticClass:"mt-3"},[_vm._v(" "+_vm._s(_vm.reasonPrompt)+" ")]),_c(VTextField,{staticClass:"mx-4",attrs:{"label":"Reason"},model:{value:(_vm.reason),callback:function ($$v) {_vm.reason=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"reason"}})],1):_vm._e(),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c('TrackedButton',{attrs:{"buttonText":"Cancel","color":"primary","name":_vm.cancelActionName,"textStyle":""},on:{"click":function($event){return _vm.emitCancel()}}}),_c('TrackedButton',{attrs:{"buttonText":"Submit","color":"primary","disabled":_vm.requireReason && !_vm.reason,"name":_vm.submitActionName},on:{"click":function($event){return _vm.emitSubmit()}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }