<template>
  <v-container grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex xs12 sm12 class="mb-4">
        <Confirm />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Confirm from '@/components/Confirm';

export default {
  data: () => ({}),
  components: { Confirm },
  computed: {},
  methods: {},
};
</script>
