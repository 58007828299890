import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"pa-8",attrs:{"id":"pathway","fluid":""}},[(_vm.pathway)?_c(VRow,[_c(VCol,{attrs:{"md":"12"}},[_c('TrackedButton',{attrs:{"buttonText":"Back to Goals","color":"primary","name":"back-to-goal-center-click","small":"","textStyle":""},on:{"click":function($event){return _vm.$router.push({
            name: 'goals',
          })}}})],1)],1):_vm._e(),(_vm.pathway && _vm.pathway.strapi_data)?_c(VRow,[_c(VCol,{attrs:{"md":"12"}},[_c('h1',{staticClass:"head_text"},[_vm._v(" "+_vm._s(_vm.pathway.strapi_data.name)+" ")])]),_c(VCol,{attrs:{"md":"12"}},[_c(VRow,[_vm._l((_vm.pathway.strapi_data.content),function(content_block,i){return _c(VCol,{key:i,attrs:{"md":"12"}},[(content_block.__component === 'content.rich-text')?_c('RichText',{attrs:{"content":content_block}}):(
              content_block.__component === 'content.video' &&
              content_block.oembed
            )?_c('OEmbed',{attrs:{"content":content_block}}):_vm._e(),(content_block.__component === 'content.slider')?_c('Slider',{attrs:{"content":content_block}}):_vm._e(),(content_block.__component === 'content.file')?_c('StrapiFile',{attrs:{"content":content_block}}):_vm._e()],1)}),_c(VDivider),(_vm.pathway && _vm.pathway.sequence_items)?_c(VRow,{staticClass:"fit-card mx-auto"},_vm._l((_vm.pathway.sequence_items),function(item,i){return _c(VCol,{key:i,attrs:{"cols":"12","sm":"12","md":"6"}},[(
                item.strapi_data &&
                item.strapi_data.cards[0] &&
                item.strapi_data.cards[0].image
              )?_c('div',{staticClass:"card-wrapper card-img-fit"},[_c('card-with-image',{attrs:{"card":item.strapi_data.cards[0],"pathway":item.pathway_uuid ? item : _vm.pathway,"module":item.pathway_uuid ? null : item},on:{"start-module-user":_vm.startAndGoToModuleUser,"continue-module-user":_vm.goToModuleUser,"create-module-user":_vm.createAndGoToModuleUser,"resume-module-user":_vm.resumeAndGoToModuleUser,"start-pathway-user":_vm.startAndGoToPathwayUser,"continue-pathway-user":_vm.goToPathwayUser,"create-pathway-user":_vm.createAndGoToPathwayUser,"resume-pathway-user":_vm.resumeAndGoToPathwayUser}})],1):(item.strapi_data && item.strapi_data.cards[0])?_c('div',{staticClass:"card-wrapper card-img-fit"},[_c('card-no-image',{attrs:{"card":item.strapi_data.cards[0],"pathway":item.pathway_uuid ? item : _vm.pathway,"module":item.pathway_uuid ? null : item},on:{"start-module-user":_vm.startAndGoToModuleUser,"continue-module-user":_vm.goToModuleUser,"create-module-user":_vm.createAndGoToModuleUser,"resume-module-user":_vm.resumeAndGoToModuleUser,"start-pathway-user":_vm.startAndGoToPathwayUser,"continue-pathway-user":_vm.goToPathwayUser,"create-pathway-user":_vm.createAndGoToPathwayUser,"resume-pathway-user":_vm.resumeAndGoToPathwayUser}})],1):_c('div',{staticClass:"card-wrapper card-img-fit"},[_c('fallback-card',{attrs:{"pathway":item.pathway_uuid ? item : _vm.pathway,"module":item.pathway_uuid ? null : item}})],1)])}),1):_c(VRow,[_c(VCol,{staticClass:"pl-12",attrs:{"md":"12"}},[_c(VProgressCircular,{attrs:{"size":50,"color":"primary","indeterminate":""}})],1)],1)],2)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }