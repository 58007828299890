import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,[_c(VCard,[_c(VCardTitle,[_vm._v("Supporting Content/Actions")]),_c(VCardText,[_vm._v(" Something goes here. maybe another pathway, maybe form to complete profile, etc. Should use sub components ")]),_c(VCardActions,[_c(VBtn,{attrs:{"plain":"","small":"","text":""}},[_vm._v("action maybe?")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }