<template>
  <span class="d-flex flex-column justify-space-between">
    <v-row>
      <v-col
        :md="nextMeeting ? 6 : 12"
        cols="12"
        class="d-flex flex-column justify-space-between px-8 py-6"
      >
        <span>
          <v-card-text>
            <span class="text-h4 font-weight-medium">
              Welcome back, {{ user | formatName }}
            </span>
            <br />
            <span class="text-body-2">Today is {{ '' | formatToday }}</span>
          </v-card-text>
        </span>
        <v-card-actions v-if="note">
          <TrackedButton
            buttonText="See Latest Note"
            color="#FFAC00"
            icon="mdi-star"
            iconClass="mr-1"
            name="see-latest-note-click"
            outlined
            small
            @click="openLatestNote"
          />
        </v-card-actions>
      </v-col>
      <v-divider v-if="showNextMeeting" vertical class="my-3" />
      <v-col v-if="showNextMeeting" md="6" cols="12" class="pa-6 pr-8">
        <v-card-text>
          <span
            class="font-weight-medium text-caption orange--text"
            v-if="nextMeeting.format === 'acuity'"
          >
            <span
              v-if="nextMeeting.metadata.acuity_calendar === 'social_groups'"
            >
              Upcoming Meetup
            </span>
            <span v-else>Upcoming Class</span>
          </span>
          <span v-else class="font-weight-medium text-caption orange--text">
            Upcoming Meeting:
          </span>
          <v-card-text class="px-0 py-2">
            <meeting-details
              :meeting="nextMeeting"
              :datetime="true"
              class="px-0 pb-0"
            />
          </v-card-text>
        </v-card-text>
      </v-col>
    </v-row>
  </span>
</template>

<script>
import TrackedButton from '@/components/TrackedButton';
import MeetingDetails from './MeetingDetails';
export default {
  name: 'Personal',
  props: ['note', 'user', 'meetings'],
  components: { TrackedButton, MeetingDetails },
  computed: {
    nextMeeting: function () {
      if (this.meetings && this.meetings.length) {
        return this.meetings[0];
      }
      return undefined;
    },
    showNextMeeting: function () {
      return this.nextMeeting;
    },
  },
  methods: {
    openLatestNote() {
      this.$emit('openNote');
    },
  },
};
</script>

<style lang="scss" scoped></style>
