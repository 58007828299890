<template>
  <!-- prettier-ignore-start -->

  <div class="pa-4">
    <p
      class="MsoNormal"
      align="center"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: center;
        line-height: normal;
      "
    >
      <b
        ><span style="font-size: 24pt; font-family: 'Times New Roman', serif"
          >Agreement Between Daivergent and Client
        </span></b
      >
    </p>

    <p
      class="MsoNormal"
      align="center"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: center;
        line-height: normal;
      "
    >
      <b
        ><span style="font-size: 24pt; font-family: 'Times New Roman', serif"
          >for Use of Daivergent Professional Services
        </span></b
      >
    </p>

    <p
      class="MsoNormal"
      align="center"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: center;
        line-height: normal;
      "
    >
      <b
        ><span style="font-size: 24pt; font-family: 'Times New Roman', serif"
          >and Technology Platform</span
        ></b
      >
    </p>

    <p
      class="MsoNormal"
      align="right"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: right;
        line-height: normal;
      "
    >
      <b
        ><u
          ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
            ><span style="text-decoration: none">&nbsp;</span></span
          ></u
        ></b
      >
    </p>

    <p
      class="MsoNormal"
      align="right"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: right;
        line-height: normal;
      "
    >
      <b
        ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
          >9-30-2018</span
        ></b
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 0.25in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: 0.25in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >WHEREAS, the undersigned Data Specialist ('Client') is maintaining an
        independent business providing data classification, processing, and
        support services (such services referred to as the 'Client
        Services');</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: 0.25in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: 0.25in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >WHEREAS, Client wishes to concentrate on practicing Client's profession
        instead of spending valuable time and money creating Client's own
        website, marketing tools, client-base, and payment processing system;
        and</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: 0.25in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: 0.25in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >WHEREAS, Daivergent Inc. ('Daivergent') is an online professional
        services company that creates marketing, sales, and payment technology
        platforms for Data Specialists (the 'Platform Services').</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: 0.25in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: 0.25in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >WHEREAS, Daivergent does not provide Client Services nor does it employ
        any Data Specialists, is not licensed or otherwise qualified to be a
        Data Specialist, and does not hold itself out as a provider of Client
        Services, as such services are exclusively rendered by Client.
      </span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; margin-bottom: 0.0001pt; text-align: justify"
    >
      <b
        ><span
          style="
            font-size: 12pt;
            line-height: 115%;
            font-family: 'Times New Roman', serif;
          "
          >&nbsp;</span
        ></b
      >
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; margin-bottom: 0.0001pt; text-align: justify"
    >
      <b
        ><span
          style="
            font-size: 12pt;
            line-height: 115%;
            font-family: 'Times New Roman', serif;
          "
          >IMPORTANT: PLEASE REVIEW THE 'ARBITRATION PROVISION' SET FORTH BELOW
          IN SECTION 20 CAREFULLY, AS IT WILL REQUIRE CLIENT TO RESOLVE DISPUTES
          WITH
        </span></b
      ><b
        ><span
          style="
            font-size: 12pt;
            line-height: 115%;
            font-family: 'Times New Roman Bold', serif;
            text-transform: uppercase;
          "
          >Daivergent</span
        ></b
      ><b
        ><span
          style="
            font-size: 12pt;
            line-height: 115%;
            font-family: 'Times New Roman', serif;
          "
        >
          ON AN INDIVIDUAL BASIS, EXCEPT AS PROVIDED IN SECTION 20, THROUGH
          FINAL AND BINDING ARBITRATION UNLESS CLIENT CHOOSES TO OPT OUT OF THE
          ARBITRATION PROVISION. BY VIRTUE OF CLIENT'S ELECTRONIC EXECUTION OF
          THIS AGREEMENT, CLIENT WILL BE ACKNOWLEDGING THAT CLIENT HAS READ AND
          UNDERSTOOD ALL OF THE TERMS OF THIS AGREEMENT (INCLUDING SECTION 20)
          AND HAVE TAKEN TIME TO CONSIDER THE CONSEQUENCES OF THIS IMPORTANT
          BUSINESS DECISION. IF CLIENT DOES NOT WISH TO BE SUBJECT TO
          ARBITRATION, CLIENT MAY OPT OUT OF THE ARBITRATION PROVISION BY
          FOLLOWING THE INSTRUCTIONS PROVIDED IN SECTION 20 BELOW.</span
        ></b
      >
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; margin-bottom: 0.0001pt; text-align: justify"
    >
      <b
        ><span
          style="
            font-size: 12pt;
            line-height: 115%;
            font-family: 'Times New Roman', serif;
          "
          >&nbsp;</span
        ></b
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: 0.25in;
      "
    >
      <span
        style="
          font-size: 12pt;
          line-height: 115%;
          font-family: 'Times New Roman', serif;
        "
        >NOW THEREFORE, Client shall engage Daivergent for the Platform
        Services, as set forth below.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 0.25in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: 0.25in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >1.<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><u
        ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
          >Acceptance</span
        ></u
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >. By clicking 'I accept' below, Client hereby agrees (a) to engage
        Daivergent to provide it with the Platform Services and (b) to comply
        with the terms of service set forth in this Agreement between Daivergent
        and Client for Use of Daivergent Professional Services and Technology
        Platform ('Agreement').
      </span>
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: 0.25in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: 0.25in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >2.<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><u
        ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
          >The Platform Services</span
        ></u
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >. Daivergent shall provide the following services to Client:
      </span>
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 1.25in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: 1in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >a.<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><u
        ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
          >Site Listing</span
        ></u
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >: Daivergent shall list Client on Daivergent's website, mobile website,
        and mobile applications (collectively, the 'Platform') with biography,
        photo, and other information provided by Client and approved by
        Daivergent, as supplemented by third-party sources gleaned by
        Daivergent.
      </span>
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 1in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: 1in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >b.<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><u
        ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
          >Appointment Service</span
        ></u
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >: Daivergent shall provide a Web and mobile-based appointment system on
        the Platform, by which visitors to the Platform ('Platform Users') can
        make appointments with Client for Client Services.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: 1in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: 1in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >c.<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><u
        ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
          >Recordkeeping Service</span
        ></u
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >: Daivergent shall maintain general appointment and billing records of
        services provided by Client to Platform Users who make appointments with
        Client for Client Services.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: 1in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: 1in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >d.<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><u
        ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
          >Administrative, Management, &amp; Technology Services</span
        ></u
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >: Daivergent will provide miscellaneous administrative, technology, and
        management services to facilitate Client's business, including the
        technology and operations necessary to communicate with Client and
        Platform Users.
      </span>
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: 1in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: 1in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >e.<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><u
        ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
          >Billing Service</span
        ></u
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >: Daivergent will provide an online, secure billing and payment system
        for Client to bill Platform Users who use the Client's Client
        Services.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 1in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: 1in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >f.<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><u
        ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
          >Customer Service</span
        ></u
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >.' Daivergent will provide operators to field Platform Users'
        complaints and refund requests.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 1in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: 1in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >g.<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><u
        ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
          >Premium Services</span
        ></u
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >.' Client may elect to pay a greater Fee for premium services, which
        includes expanded professional development modules, social &amp;
        communications practice with live one-on-one sessions, and expanded
        Client support services.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: 1in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: 0.25in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >3.<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><u
        ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
          >Exclusions from the Platform Services</span
        ></u
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >. The Platform Services only relate to the business services set forth
        above.'
        <b
          ><u
            >The Platform Services do not include anything related to data
            management and business analytics services</u
          ></b
        >.' Client is solely responsible for all of Client's own tools,
        equipment, training, automobiles, office space, licensing, and other
        materials or requirements needed, desired or related to the Client
        Services. Client is also solely responsible for the payment of insurance
        premiums, licensing fees, professional dues or other costs or expenses
        connected with Client's business, and acknowledges that Daivergent will
        not reimburse Client for any such expenses. Daivergent is not, and shall
        not be, responsible for any liability arising out of the Client
        Services, including, but not limited to, injuries to the Platform Users
        from the Client Services.' Client is <u>not</u> permitted to represent
        to Platform Users or others that Client is an employee, contractor, or
        agent of Daivergent.'
      </span>
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 0.25in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoListParagraphCxSpFirst"
      style="
        margin: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: 0.25in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >4.<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><u
        ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
          >Platform Control</span
        ></u
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >.' As an independent business and Client of Daivergent, Client
        maintains complete control over Client's use of the Platform,
        including:</span
      >
    </p>

    <p
      class="MsoListParagraphCxSpMiddle"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 1in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoListParagraphCxSpMiddle"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 1in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: -0.25in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >a.<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >Client decides when to log into the Platform and be available for
        appointments through the Platform appointment service;</span
      >
    </p>

    <p
      class="MsoListParagraphCxSpMiddle"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 1in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoListParagraphCxSpMiddle"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 1in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: -0.25in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >b.<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >Client decides whether to accept, reject, or ignore offers of projects
        if logged in;</span
      >
    </p>

    <p
      class="MsoListParagraphCxSpMiddle"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 1in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoListParagraphCxSpMiddle"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 1in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: -0.25in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >c.<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >Client is permitted to select Client's attire for the Client Services '
        no uniforms or other specific clothes are required;</span
      >
    </p>

    <p
      class="MsoListParagraphCxSpMiddle"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 1in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoListParagraphCxSpMiddle"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 1in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: -0.25in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >d.<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >Client confers with the Platform User to determine the time and
        location of the Client Services ' Daivergent is not involved;</span
      >
    </p>

    <p
      class="MsoListParagraphCxSpMiddle"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 1in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoListParagraphCxSpMiddle"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 1in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: -0.25in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >e.<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >Client is permitted to maintain Client's independent business and other
        clients without using the Platform;</span
      >
    </p>

    <p
      class="MsoListParagraphCxSpMiddle"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 1in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoListParagraphCxSpMiddle"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 1in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: -0.25in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >f.<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >Client is not restricted from using competitive services or technology
        platforms;</span
      >
    </p>

    <p
      class="MsoListParagraphCxSpMiddle"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 1in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoListParagraphCxSpMiddle"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 1in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: -0.25in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >g.<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >Client retains the right to hire employees or subcontractors in
        performing Client Services, and acknowledges that these employees or
        subcontractors will possess the credentials, training, experience, skill
        and ability to provide Client Services;
      </span>
    </p>

    <p
      class="MsoListParagraphCxSpMiddle"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 1in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoListParagraphCxSpMiddle"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 1in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: -0.25in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >h.<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >Client's opportunity for profit or loss is dependent on his or her own
        managerial skill;
      </span>
    </p>

    <p
      class="MsoListParagraphCxSpMiddle"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 1in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoListParagraphCxSpMiddle"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 1in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: -0.25in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >i.<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >Client is responsible for resolving any disputes with Platform Users,
        including financial responsibilities for refunds, breakage, spoilage,
        and other losses directly or indirectly caused by Client or Client's
        agents;</span
      >
    </p>

    <p
      class="MsoListParagraphCxSpMiddle"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 1in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoListParagraphCxSpMiddle"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 1in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: -0.25in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >j.<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >Client solely controls the amount of Client's investment in the Client
        Services, including expenses on training and tools; and</span
      >
    </p>

    <p
      class="MsoListParagraphCxSpMiddle"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 1in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoListParagraphCxSpMiddle"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 1in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: -0.25in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >k.<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >Client exclusively controls Client's performance of the Client
        Services, including, but not limited to, possessing the requisite
        skills, credentials and training to perform Client Services that he or
        she accepts hereunder.</span
      >
    </p>

    <p
      class="MsoListParagraphCxSpMiddle"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 0.25in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoListParagraphCxSpLast"
      style="
        margin: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: 0.25in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >5.<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><u
        ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
          >Default Site Terms</span
        ></u
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >. In addition to the Terms of Use, which Client agreed to when engaging
        Daivergent, Daivergent maintains the policies stated in this Section 5
        (the 'Default Site Terms') for clients as a default on the Platform.
        <b>Client is permitted to negotiate the Default Site Terms.</b>'
        Notwithstanding, unless other terms are set forth in a writing signed by
        Client and Daivergent's Chief Executive Officer, the Daivergent Default
        Site Terms to which Client agrees are:</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 0.75in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: 0.75in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >a.<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><u
        ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
          >Client Pricing</span
        ></u
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >.' Daivergent shall advertise for Client's Client Services at the
        market rate for data processing, labeling, and support that is assessed
        by comparable businesses.' Client must provide Daivergent in writing
        with notice of any pricing changes.' The total amount paid by Platform
        Users through Daivergent's payment processing system is referred to
        hereafter as the 'User Fee.''
      </span>
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 0.75in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: 0.75in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >b.<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><u
        ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
          >Refund</span
        ></u
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >. As a default, Daivergent maintains a refund and cancellation policy
        to which Client agrees unless otherwise set forth in a writing signed by
        Client and Daivergent's Chief Executive Officer.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 0.75in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoListParagraphCxSpFirst"
      style="
        margin: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: 0.25in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >6.<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><u
        ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
          >Fees for Platform Services</span
        ></u
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >.' The fee for the Platform Services shall be: (a) $49/month for
        standard services; or (b) $199/month for premium services (the 'Monthly
        Fee'), plus the Daivergent User Fee Proceeds, provided, however, that
        any Monthly Fees paid to Daivergent shall be credited against future
        Daivergent User Fee Proceeds. To the extent that a User Fee is refunded,
        Client agrees that Client shall still owe Daivergent the Daivergent User
        Fee Proceeds on such refunded amount.''
      </span>
    </p>

    <p
      class="MsoListParagraphCxSpLast"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 0.25in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: 0.25in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >7.<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><u
        ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
          >Client's Business</span
        ></u
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >.' By signing this Agreement and under penalty of perjury, Client
        affirms that Client is self-employed, maintains and operates a data
        support business that is separate and independent from Daivergent, holds
        himself or herself out to the public as independently competent and
        available to provide the applicable services, and has obtained and/or
        expects to obtain clients for whom Client performs Client Services
        through other means other than Daivergent and prior to entering into
        this Agreement.''
      </span>
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 0.5in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: 0.25in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >8.<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><u
        ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
          >Client Responsibilities</span
        ></u
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >. Client assumes complete responsibility for all services to each
        Daivergent Site User and for compliance with all laws, regulations, and
        professional ethical guidelines and standards pertaining to Client's
        products and services. Client represents that Client is free to enter
        into this Agreement and perform each of its terms, is not restricted
        (contractually or otherwise) from entering into and performing this
        Agreement, and is not subject to any suit, action, claim, arbitration or
        legal, administrative or other proceeding, or government or professional
        investigation, pending or threatened or affecting Client's ability to
        perform services hereunder.' Client will immediately inform Daivergent
        of any such action.
      </span>
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: 0.25in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: 0.25in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >9.<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><u
        ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
          >Confidential Information</span
        ></u
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >. Daivergent agrees to keep Client's confidential information ('Client
        Confidential Information') absolutely confidential, except as required
        or provided by law.' Client agrees to keep Daivergent's confidential
        information ('Daivergent Confidential Information') absolutely
        confidential, except as required or provided by law.' Client further
        agrees to keep each Platform User's confidential information ('Platform
        User Confidential Information') absolutely confidential, except as
        required or provided by law. Daivergent Confidential Information
        includes but is not limited to information about Daivergent's customer
        base, including Platform Users, business model of creating a marketplace
        for connecting professionals with new or existing customers/users and
        providing tools, products and services, and technology to assist
        Client's existing business.' Platform User Confidential Information
        includes but is not limited to information about the data
        classification, processing, and support services provided by Client to a
        Platform User, including any confidential information furnished by the
        Platform User to Client in connection with the performance of such
        services. Client agrees that Client shall not plan, invest in, assist
        with, start, operate, or otherwise participate in the Business using
        Daivergent Confidential Information.' For the purposes of this Section,
        the 'Business' is any Web or mobile-based marketplace for connecting
        individuals or businesses providing services to third parties seeking
        the same services and providing related business tools.' Client also
        agrees to use and disclose information about Platform Users only as
        necessary to provide the Client Services under this Agreement and for no
        other purpose during the term of this Agreement.' Upon termination of
        this Agreement, Client shall return to Daivergent or destroy any
        information Client obtained about Platform Users through the Platform
        and shall no longer maintain, use, or disclose such information for any
        purpose, except as required by applicable law.' Nothing in this Section
        shall prohibit Client from performing services using another third party
        platform or as an independent business.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: 0.25in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >10.<span style="font: 7pt 'Times New Roman'">&nbsp; </span></span
      ><u
        ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
          >Independent Contractor Relationship; No Legal or Other Advice</span
        ></u
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >. </span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >Client acknowledges that Client is an independent contractor and not an
        employee of </span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >Daivergent </span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >for all purposes, and acknowledges Client's sole responsibility for
        complying with all federal, state and local tax filing and payment
        obligations that pertain to any remuneration received from clients in
        connection with this Agreement. </span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >Neither this Agreement nor Client's performance under this Agreement
        shall create an association, partnership, joint venture, or relationship
        of principal and agent, master and servant, or employer and employee,
        between Daivergent and Client. Client shall be solely responsible for
        all tax withholding, Social Security, Worker's Compensation Insurance,
        FICA, unemployment compensation, medical insurance, life insurance, paid
        vacations, paid holidays, pensions, and other obligations or benefits.
        Client acknowledges that Daivergent is not rendering legal, tax, or
        investment advice, nor is Daivergent a fiduciary of Client.'
        Accordingly, Client acknowledges that Client may seek advice from an
        appropriate professional to comply with any and all applicable federal,
        state, and local laws or ordinances.'
      </span>
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 0.25in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: 0.25in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >11.<span style="font: 7pt 'Times New Roman'">&nbsp; </span></span
      ><u
        ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
          >No Employment</span
        ></u
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >.' Nothing in this Agreement shall in any way be construed to
        constitute Client or its employees or agents as an agent, employee or
        representative of the Company. Without limiting the generality of the
        foregoing, Client is not authorized to bind the Company to any liability
        or obligation or to represent that Client has any such authority.
        Daivergent and Client agree that Client and its employees and agents
        will receive no Company-sponsored benefits from the Company where
        benefits include, but are not limited to, paid vacation, sick leave,
        medical insurance and 401k participation. If Client, its employees, or
        agents, are reclassified by a state or federal agency or court as
        Daivergent's employee, Client, its employees, or agents, as applicable,
        will become a reclassified employee and will receive no benefits from
        Daivergent, except those mandated by state or federal law, even if by
        the terms of Daivergent's benefit plans or programs of Daivergent in
        effect at the time of such reclassification, Client, its employees or
        agents, as applicable, would otherwise be eligible for such
        benefits.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 0.25in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: 0.25in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >12.<span style="font: 7pt 'Times New Roman'">&nbsp; </span></span
      ><u
        ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
          >Indemnification</span
        ></u
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >. Client agrees to promptly defend, indemnify and reimburse, and hold
        harmless Daivergent and its directors, officers, employees, agents or
        advisors ('Representatives'), to the fullest extent permitted by law, as
        such may be amended from time to time, from any losses, liabilities,
        legal fees or expenses incurred by Daivergent or its Representatives, as
        a result of</span
      >: (a)<span
        style="font-size: 12pt; font-family: 'Times New Roman', serif"
      >
        Client's negligence, intentional wrongdoing, or a breach of this
        Agreement, or alleged negligence, intentional wrongdoing, or breach of
        this Agreement, of the Client or of persons under Client's control; or
        (b) any liability of Client or Daivergent for the payment or non-payment
        of federal, state, or local taxes, or other withholdings, involving
        Client.' Daivergent shall be entitled to the rights of indemnification
        provided if, by reason of Client's providing Client Services under this
        Agreement, Daivergent is, or is threatened to be made, a party to or
        participant in any proceeding, including any actual or threatened
        administrative or civil action or lawsuit, arbitration, or criminal
        indictment or case.' Client will cooperate as fully and reasonably as
        required by Daivergent in the defense of any claim related to any
        services under this Agreement.
      </span>
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: 0.25in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: 0.25in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >13.<span style="font: 7pt 'Times New Roman'">&nbsp; </span></span
      ><u
        ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
          >Expenses</span
        ></u
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >. Except as otherwise specifically provided herein, Daivergent and
        Client shall each bear his, her or its own expenses relating to this
        Agreement and performance thereunder.
      </span>
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: 0.25in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: 0.25in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >14.<span style="font: 7pt 'Times New Roman'">&nbsp; </span></span
      ><u
        ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
          >Disclaimer of Warranties; Limitation of Liability</span
        ></u
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >. Daivergent provides the Platform on an 'as is' and 'as available
        basis' and hereby disclaims all warranties, both express and implied,
        including any warranty of non-infringement, fitness for a particular
        purpose or merchantability; and Daivergent's and its Representatives'
        collective maximum liability hereunder, regardless of the cause of
        action (whether in contract, tort, breach of warranty, or otherwise),
        shall be limited to the aggregate fees paid to Daivergent by Client
        during the prior calendar year.' Daivergent reserves complete and sole
        discretion with respect to the operation of the Platform, and may, among
        other things withdraw, suspend or discontinue any functionality or
        feature.&nbsp; Further, neither party shall be liable for lost profits,
        lost revenues, lost business, interruption of business, or any indirect,
        special, incidental, punitive or consequential damages of any kind,
        regardless of the form of action, whether in contract, tort (including
        negligence), strict liability or otherwise, even if advised of the
        possibility of such damages.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: 0.25in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: 0.25in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >15.<span style="font: 7pt 'Times New Roman'">&nbsp; </span></span
      ><u
        ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
          >Marks</span
        ></u
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >. The domain name and other names for the Platform, all page headers,
        graphics, and button icons are service marks, trademarks (whether
        registered or unregistered), logos, and/or trade dress of Daivergent
        (collectively, 'Daivergent Marks').' Client will not use any of the
        Daivergent Marks without prior written authorization; except that Client
        has a revocable and non-transferable license for the sole purpose of
        identifying and promoting Daivergent's services under the terms of this
        Agreement.'
      </span>
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: 0.25in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: 0.25in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >16.<span style="font: 7pt 'Times New Roman'">&nbsp; </span></span
      ><u
        ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
          >Termination</span
        ></u
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >.' The initial term of this Agreement is for one (1) year from the
        effective date written above.' Either party may terminate this Agreement
        by giving three (3) business days prior written notice thereof.' All
        licenses granted by Daivergent under this Agreement shall be revoked as
        of the termination of this Agreement; notwithstanding the foregoing,
        Sections 7-20, and any liabilities or payment obligations that have
        accrued prior to termination shall survive such termination. Daivergent </span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >may also terminate this Agreement immediately with written notice to
        Client if Client has breached any material term ('Material Breach') of
        the Agreement. The Agreement shall automatically renew for successive
        one (1) year terms if not terminated within 30 days prior to the end of
        the existing term.''''
      </span>
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoListParagraphCxSpFirst"
      style="
        margin-bottom: 6pt;
        text-align: justify;
        text-indent: -0.25in;
        line-height: normal;
        page-break-after: avoid;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >17.<span style="font: 7pt 'Times New Roman'">&nbsp; </span></span
      ><u
        ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
          >Material Breach</span
        ></u
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >. A Material Breach of this Agreement shall include, but is not limited
        to, the following:</span
      >
    </p>

    <p
      class="MsoListParagraphCxSpMiddle"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 6pt;
        margin-left: 0.75in;
        text-align: justify;
        text-indent: -27pt;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >(a)<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >Failure to pay </span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >Daivergent's </span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >fee for its Platform Services related to the provision of Client
        Services by Client;
      </span>
    </p>

    <p
      class="MsoListParagraphCxSpMiddle"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 6pt;
        margin-left: 0.75in;
        text-align: justify;
        text-indent: -27pt;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >(b)<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >Failure to abide by the Terms of Use; and
      </span>
    </p>

    <p
      class="MsoListParagraphCxSpLast"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 12pt;
        margin-left: 0.75in;
        text-align: justify;
        text-indent: -27pt;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >(c)<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >Violation by Client of applicable state law and/or possession of
        deficient and/or misrepresented credentials.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: 0.25in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >18.<span style="font: 7pt 'Times New Roman'">&nbsp; </span></span
      ><span style="font-family: 'Times New Roman', serif">'</span
      ><u
        ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
          >Changes</span
        ></u
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >. Except for changes in the Price List (which Daivergent may in its
        sole discretion amend from time to time), changes to this Agreement
        shall be binding upon a party who confirms such change in writing,
        including via electronic mail, if such writing specifically refers to
        this Agreement.
      </span>
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 0.25in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: 0.25in;
        line-height: normal;
        text-autospace: none;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >19.<span style="font: 7pt 'Times New Roman'">&nbsp; </span></span
      ><u
        ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
          >Governing Law</span
        ></u
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >. </span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >The choice of law provisions contained in this Section 19 do not apply
        to the arbitration clause contained in Section 20, such arbitration
        clause being governed by the Federal Arbitration Act. Accordingly, and
        except as otherwise stated in Section 20, the interpretation of this
        Agreement shall be governed by New York law, without regard to the
        choice or conflicts of law provisions of any jurisdiction. </span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >Any legal fees and expenses relating thereto shall be awarded to the
        successful party. </span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >Any disputes, actions, claims or causes of action arising out of or in
        connection with this Agreement or the Daivergent that are not subject to
        the arbitration clause contained in this Section 19 shall be subject to
        the exclusive jurisdiction of the state and federal courts located in
        the City and County of New York, New York. However, neither the choice
        of law provision regarding the interpretation of this Agreement nor the
        forum selection provision is intended to create any other substantive
        right to non-New Yorkers to assert claims under New York law whether
        that be by statute, common law, or otherwise. These provisions, and
        except as otherwise provided in Section 20, are only intended to specify
        the use of New York law to interpret this Agreement and the forum for
        disputes asserting a breach of this Agreement, and these provisions
        shall not be interpreted as generally extending New York law to Client
        if Client does not otherwise operate its business in New York. The
        foregoing choice of law and forum selection provisions do not apply to
        the arbitration clause in Section 20 or to any arbitrable disputes as
        defined therein. Instead, as described in Section 20, the Federal
        Arbitration Act shall apply to any such dispute. The failure of
        Daivergent to enforce any right or provision in this Agreement shall not
        constitute a waiver of such right or provision unless acknowledged and
        agreed to by Daivergent in writing. Other than disputes regarding the
        intellectual property rights of the parties and other claims identified
        in Section 20(b), any disputes, actions, claims or causes of action
        arising out of or in connection with this Agreement or the Client
        Services shall be subject to arbitration pursuant to Section 20.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        line-height: normal;
        text-autospace: none;
      "
    >
      &nbsp;
    </p>

    <p
      class="MsoNormal"
      style="
        margin: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: 0.25in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >20.<span style="font: 7pt 'Times New Roman'">&nbsp; </span></span
      ><u
        ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
          >Arbitration</span
        ></u
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >.
      </span>
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 0.25in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <ol style="margin-top: 0in" start="20" type="1">
      <ol style="margin-top: 0in" start="1" type="a">
        <li
          class="MsoNormal"
          style="
            margin-bottom: 0in;
            margin-bottom: 0.0001pt;
            text-align: justify;
            line-height: normal;
          "
        >
          <u
            ><span
              style="font-size: 12pt; font-family: 'Times New Roman', serif"
              >Background on This Arbitration Provision</span
            ></u
          ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
            >.</span
          >
        </li>
      </ol>
    </ol>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 1in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoListParagraphCxSpFirst"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: -0.25in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: Symbol"
        >'<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >Except as provided below, arbitration does not limit or affect the
        legal claims Client may bring against </span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >Daivergent</span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >. Agreeing to arbitration only affects where any such claims may be
        brought and how they will be resolved.
      </span>
    </p>

    <p
      class="MsoListParagraphCxSpMiddle"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoListParagraphCxSpMiddle"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: -0.25in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: Symbol"
        >'<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >Arbitration is a process of private dispute resolution that does not
        involve the civil courts, a civil judge, or a jury. Instead, the
        parties' dispute is decided by a private arbitrator selected by the
        parties using the process set forth herein. Other arbitration rules and
        procedures are also set forth herein.
      </span>
    </p>

    <p
      class="MsoListParagraphCxSpMiddle"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoListParagraphCxSpMiddle"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: -0.25in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: Symbol"
        >'<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >Unless the law requires otherwise, as determined by the Arbitrator
        based upon the circumstances presented, Client will be required to split
        the cost of any arbitration with </span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >Daivergent</span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >.</span
      >
    </p>

    <p class="MsoListParagraphCxSpMiddle">
      <span
        style="
          font-size: 12pt;
          line-height: 115%;
          font-family: 'Times New Roman', serif;
        "
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoListParagraphCxSpMiddle"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: -0.25in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: Symbol"
        >'<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >IMPORTANT: This Arbitration Provision will require Client to resolve
        any claim that Client may have against </span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >Daivergent </span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >on an individual basis, except as provided below, pursuant to the terms
        of the Agreement unless Client chooses to opt out of the Arbitration
        Provision. Except as provided below, this provision will preclude Client
        from bringing any class, collective, or representative action (other
        than actions under the Private Attorneys General Act of 2004 ('PAGA'),
        California Labor Code ' 2698 et seq. ('PAGA')) against </span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >Daivergent</span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >, and also precludes Client from participating in or recovering relief
        under any current or future class, collective, or representative
        (non-PAGA) action brought against </span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >Daivergent </span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >by someone else.</span
      >
    </p>

    <p class="MsoListParagraphCxSpMiddle">
      <span
        style="
          font-size: 12pt;
          line-height: 115%;
          font-family: 'Times New Roman', serif;
        "
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoListParagraphCxSpMiddle"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: -0.25in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: Symbol"
        >'<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><b
        ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
          >The mere existence of such class, collective, and/or representative
          lawsuits, however, does not mean that such lawsuits will ultimately
          succeed. But if Client does agree to arbitration with
        </span></b
      ><b
        ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
          >Daivergent</span
        ></b
      ><b
        ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
          >, Client is agreeing in advance, except as otherwise provided, that
          Client will not participate in and, therefore, will not seek to
          recover monetary or other relief under any such class, collective,
          and/or representative (non-PAGA) lawsuit.
        </span></b
      >
    </p>

    <p class="MsoListParagraphCxSpMiddle">
      <b
        ><span
          style="
            font-size: 12pt;
            line-height: 115%;
            font-family: 'Times New Roman', serif;
          "
          >&nbsp;</span
        ></b
      >
    </p>

    <p
      class="MsoListParagraphCxSpMiddle"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: -0.25in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: Symbol"
        >'<span style="font: 7pt 'Times New Roman'"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ><b
        ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
          >However, as discussed above and except as provided below, if Client
          agrees to arbitration, Client will not be precluded from bringing
          Client's claims against
        </span></b
      ><b
        ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
          >Daivergent
        </span></b
      ><b
        ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
          >in an individual arbitration proceeding. If successful on such
          claims, Client could be awarded money or other relief by an arbitrator
          (subject to splitting the cost of arbitration as mentioned
          above).</span
        ></b
      >
    </p>

    <p
      class="MsoListParagraphCxSpLast"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <b
        ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
          >WHETHER TO AGREE TO ARBITRATION IS AN IMPORTANT BUSINESS DECISION. IT
          IS CLIENT'S DECISION TO MAKE, AND CLIENT SHOULD NOT RELY SOLELY UPON
          THE INFORMATION PROVIDED IN THIS AGREEMENT AS IT IS NOT INTENDED TO
          CONTAIN A COMPLETE EXPLANATION OF THE CONSEQUENCES OF ARBITRATION.
          CLIENT SHOULD TAKE REASONABLE STEPS TO CONDUCT FURTHER RESEARCH AND TO
          CONSULT WITH OTHERS ' INCLUDING BUT NOT LIMITED TO AN ATTORNEY '
          REGARDING THE CONSEQUENCES OF CLIENT'S DECISION, JUST AS CLIENT WOULD
          WHEN MAKING ANY OTHER IMPORTANT BUSINESS OR LIFE DECISION.</span
        ></b
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 1in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <ol style="margin-top: 0in" start="20" type="1">
      <ol style="margin-top: 0in" start="2" type="a">
        <li
          class="MsoNormal"
          style="
            margin-bottom: 0in;
            margin-bottom: 0.0001pt;
            text-align: justify;
            line-height: normal;
          "
        >
          <u
            ><span
              style="font-size: 12pt; font-family: 'Times New Roman', serif"
              >How This Arbitration Provision Applies</span
            ></u
          ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
            >.</span
          >
        </li>
      </ol>
    </ol>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >This Arbitration Provision is governed by the Federal Arbitration Act,
        9 U.S.C. ' 1 et seq. (the 'FAA') and evidences a transaction involving
        interstate commerce.' This Arbitration Provision applies to any dispute
        arising out of or related to this Agreement or termination of the
        Agreement and survives after the Agreement terminates.' Nothing
        contained in this Arbitration Provision shall be construed to prevent or
        excuse Client from utilizing any informal procedure for resolution of
        complaints established in this Agreement (if any), and this Arbitration
        Provision is not intended to be a substitute for the utilization of such
        procedures.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <b
        ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
          >Except as it otherwise provides, this Arbitration Provision is
          intended to apply to the resolution of disputes that otherwise would
          be resolved in a court of law or before any forum other than
          arbitration, with the exception of proceedings that must be exhausted
          under applicable law before pursuing a claim in a court of law or in
          any forum other than arbitration.' Except as it otherwise provides,
          this Arbitration Provision requires all such disputes to be resolved
          only by an arbitrator through final and binding arbitration on an
          individual basis only and not by way of court or jury trial, or by way
          of class, collective, or representative action.</span
        ></b
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >Except as provided in Section 20(e), below, regarding the Class Action
        Waiver, such disputes include without limitation disputes arising out of
        or relating to interpretation or application of this Arbitration
        Provision, including the enforceability, revocability or validity of the
        Arbitration Provision or any portion of the Arbitration Provision.' All
        such matters shall be decided by an Arbitrator and not by a court or
        judge. However, as set forth below, the preceding sentences shall not
        apply to disputes relating to the interpretation or application of the
        Class Action Waiver or PAGA Waiver below, including their
        enforceability, revocability or validity.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >Except as it otherwise provides, this Arbitration Provision also
        applies, without limitation, to all disputes between Client and </span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >Daivergent</span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >, as well as all disputes between Client and </span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >Daivergent's </span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >fiduciaries, administrators, affiliates, subsidiaries, parents, and all
        successors and assigns of any of them, including but not limited to any
        disputes arising out of or related to this Agreement and disputes
        arising out of or related to Client's relationship with </span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >Daivergent</span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >, including termination of the relationship. This Arbitration Provision
        also applies, without limitation, to disputes regarding any city,
        county, state or federal wage-hour law, trade secrets, unfair
        competition, compensation, breaks and rest periods, expense
        reimbursement, termination, harassment and' claims arising under the
        Uniform Trade Secrets Act, Civil Rights Act of 1964, Americans With
        Disabilities Act, Age Discrimination in Employment Act, Family Medical
        Leave Act, Fair Labor Standards Act, Employee Retirement Income Security
        Act (except for individual claims for employee benefits under any
        benefit plan sponsored by </span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >Daivergent </span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >and covered by the Employee Retirement Income Security Act of 1974 or
        funded by insurance), Genetic Information Non-Discrimination Act, and
        state statutes, if any, addressing the same or similar subject matters,
        and all other similar federal and state statutory and common law claims.
      </span>
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >This Agreement is intended to require arbitration of every claim or
        dispute that lawfully can be arbitrated, except for those claims and
        disputes which by the terms of this Agreement are expressly excluded
        from the Arbitration Provision.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <ol style="margin-top: 0in" start="20" type="1">
      <ol style="margin-top: 0in" start="3" type="a">
        <li
          class="MsoNormal"
          style="
            margin-bottom: 0in;
            margin-bottom: 0.0001pt;
            text-align: justify;
            line-height: normal;
          "
        >
          <u
            ><span
              style="font-size: 12pt; font-family: 'Times New Roman', serif"
              >Limitations on How This Agreement Applies</span
            ></u
          ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
            >.</span
          >
        </li>
      </ol>
    </ol>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >The disputes and claims set forth below shall not be subject to
        arbitration and the requirement to arbitrate set forth in Section 20 of
        this Agreement shall not apply:
      </span>
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >A representative action brought on behalf of others under the Private
        Attorneys General Act of 2004 ('PAGA'), California Labor Code ' 2698 et
        seq., to the extent waiver of such a claim is deemed unenforceable by a
        court of competent jurisdiction;
      </span>
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >Claims for workers compensation, state disability insurance and
        unemployment insurance benefits;
      </span>
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >Regardless of any other terms of this Agreement, nothing prevents
        Client from making a report to or filing a claim or charge with the
        Equal Employment Opportunity Commission, U.S. Department of Labor,
        Securities Exchange Commission, National Labor Relations Board, or
        Office of Federal Contract Compliance Programs, and nothing in this
        Agreement or Arbitration Provision prevents the investigation by a
        government agency of any report, claim or charge otherwise covered by
        this Arbitration Provision. Nothing in this Arbitration Provision shall
        be deemed to preclude or excuse a party from bringing an administrative
        claim before any agency in order to fulfill the party's obligation to
        exhaust administrative remedies before making a claim in
        arbitration;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >Disputes that may not be subject to a predispute arbitration agreement
        pursuant to applicable Federal law or Executive Order are excluded from
        the coverage of this Arbitration Provision; Disputes regarding the
        Intellectual Property Rights of the parties; This Arbitration Provision
        shall not be construed to require the arbitration of any claims against
        a contractor that may not be the subject of a mandatory arbitration
        agreement as provided by section 8116 of the Department of Defense
        (&quot;DoD&quot;) Appropriations Act for Fiscal Year 2010 (Pub. L.
        111-118), section 8102 of the Department of Defense (&quot;DoD&quot;)
        Appropriations Act for Fiscal Year 2011 (Pub. L. 112-10, Division A),
        and their implementing regulations, or any successor DoD appropriations
        act addressing the arbitrability of claims.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <ol style="margin-top: 0in" start="20" type="1">
      <ol style="margin-top: 0in" start="4" type="a">
        <li
          class="MsoNormal"
          style="
            margin-bottom: 0in;
            margin-bottom: 0.0001pt;
            text-align: justify;
            line-height: normal;
          "
        >
          <u
            ><span
              style="font-size: 12pt; font-family: 'Times New Roman', serif"
              >Selecting The Arbitrator and Location of the Arbitration</span
            ></u
          ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
            >.</span
          >
        </li>
      </ol>
    </ol>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >The Arbitrator shall be selected by mutual agreement of the Company and
        Client.' Unless Client and the Company mutually agree otherwise, the
        Arbitrator shall be an attorney licensed to practice in the location
        where the arbitration proceeding will be conducted or a retired federal
        or state judicial officer who presided in the jurisdiction where the
        arbitration will be conducted.' If the Parties cannot agree on an
        Arbitrator, then an arbitrator will be selected using the alternate
        strike method from a list of five (5) neutral arbitrators provided by
        JAMS (Judicial Arbitration &amp; Mediation Services).' Client will have
        the option of making the first strike.' If a JAMS arbitrator is used,
        then the JAMS Streamlined Arbitration Rules &amp; Procedures rules will
        apply; however, if there is a conflict between the JAMS Rules and this
        Agreement, this Agreement shall govern. Those rules are available here:
        <span class="MsoHyperlink"
          ><a href="http://www.jamsadr.com/rules-streamlined-arbitration/"
            >http://www.jamsadr.com/rules-streamlined-arbitration/</a
          ></span
        >
      </span>
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >The location of the arbitration proceeding shall be no more than 45
        miles from the place where Client last provided Client Services under
        this Agreement, unless each party to the arbitration agrees in writing
        otherwise.
      </span>
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <ol style="margin-top: 0in" start="20" type="1">
      <ol style="margin-top: 0in" start="5" type="a">
        <li
          class="MsoNormal"
          style="
            margin-bottom: 0in;
            margin-bottom: 0.0001pt;
            text-align: justify;
            line-height: normal;
          "
        >
          <u
            ><span
              style="font-size: 12pt; font-family: 'Times New Roman', serif"
              >Starting The Arbitration</span
            ></u
          ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
            >.</span
          >
        </li>
      </ol>
    </ol>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >All claims in arbitration are subject to the same statutes of
        limitation that would apply in court.' The party bringing the claim must
        demand arbitration in writing and deliver the written demand by hand or
        first class mail to the other party within the applicable statute of
        limitations period.' The demand for arbitration shall include
        identification of the Parties, a statement of the legal and factual
        basis of the claim(s), and a specification of the remedy sought. Any
        demand for arbitration made to the Company or Company shall be provided
        to the Company at its Company Headquarters address, c/o Human Resources.
        The arbitrator shall resolve all disputes regarding the timeliness or
        propriety of the demand for arbitration.'' A party may apply to a court
        of competent jurisdiction for temporary or preliminary injunctive relief
        in connection with an arbitrable controversy, but only upon the ground
        that the award to which that party may be entitled may be rendered
        ineffectual without such provisional relief.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <ol style="margin-top: 0in" start="20" type="1">
      <ol style="margin-top: 0in" start="6" type="a">
        <li
          class="MsoNormal"
          style="
            margin-bottom: 0in;
            margin-bottom: 0.0001pt;
            text-align: justify;
            line-height: normal;
          "
        >
          <u
            ><span
              style="font-size: 12pt; font-family: 'Times New Roman', serif"
              >How Arbitration Proceedings Are Conducted</span
            ></u
          ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
            >.</span
          >
        </li>
      </ol>
    </ol>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 1in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >In arbitration, the Parties will have the right to conduct adequate
        civil discovery, bring dispositive motions, and present witnesses and
        evidence as needed to present their cases and defenses, and any disputes
        in this regard shall be resolved by the Arbitrator.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <b
        ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
          >Client and the Company agree to resolve any dispute that is in
          arbitration on an individual basis only, and not on a class,
          collective action, or representative basis ('Class Action Waiver').
          The Arbitrator shall have no authority to consider or resolve any
          claim or issue any relief on any basis other than an individual basis.
          The Arbitrator shall have no authority to consider or resolve any
          claim or issue any relief on a class, collective, or representative
          basis.</span
        ></b
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >' Notwithstanding any other provision of this Agreement, the
        Arbitration Provision or the JAMS Streamlined Arbitration Rules &amp;
        Procedures, disputes regarding the enforceability, revocability or
        validity of the Class Action Waiver may be resolved only by a civil
        court of competent jurisdiction and not by an arbitrator. In any case in
        which (1) the dispute is filed as a class, collective, or representative
        action and (2) there is a final judicial determination that all or part
        of the Class Action Waiver unenforceable, the class, collective, and/or
        representative action to that extent must be litigated in a civil court
        of competent jurisdiction, but the portion of the Class Action Waiver
        that is enforceable shall be enforced in arbitration.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >While </span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >Daivergent </span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >will not take any retaliatory action in response to any exercise of
        rights Client may have under Section 7 of the National Labor Relations
        Act, if any, </span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >Daivergent </span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >shall not be precluded from moving to enforce its rights under the FAA
        to compel arbitration on the terms and conditions set forth in this
        Agreement.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <b
        ><u
          ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
            >Private Attorneys General Act</span
          ></u
        ></b
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >Notwithstanding any other provision of this Agreement or the
        Arbitration Provision, to the extent permitted by law, (1) Client and </span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >Daivergent </span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >agree not to bring a representative action on behalf of others under
        the Private Attorneys General Act of 2004 ('PAGA'), California Labor
        Code ' 2698 et seq., in any court or in arbitration, and (2) for any
        claim brought on a private attorney general basis'i.e., where Client are
        seeking to pursue a claim on behalf of a government entity'both Client
        and </span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >Daivergent </span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >agree that any such dispute shall be resolved in arbitration on an
        individual basis only (i.e., to resolve whether Client has personally
        been aggrieved or subject to any violations of law), and that such an
        action may not be used to resolve the claims or rights of other
        individuals in a single or collective proceeding (i.e., to resolve
        whether other individuals have been aggrieved or subject to any
        violations of law) ('PAGA Waiver'). Notwithstanding any other provision
        of this Agreement or the Arbitration Provision, the validity of the PAGA
        Waiver may be resolved only by a civil court of competent jurisdiction
        and not by an arbitrator. If any provision of the PAGA Waiver is found
        to be unenforceable or unlawful for any reason, (1) the unenforceable
        provision shall be severed from this Agreement; (2) severance of the
        unenforceable provision shall have no impact whatsoever on the
        Arbitration Provision or the Parties' attempt to arbitrate any remaining
        claims on an individual basis pursuant to the Arbitration Provision; and
        (3) any representative action brought under PAGA on behalf of others
        must be litigated in a civil court of competent jurisdiction and not in
        arbitration. To the extent that there are any claims to be litigated in
        a civil court of competent jurisdiction because a civil court of
        competent jurisdiction determines that the PAGA Waiver is unenforceable
        with respect</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 1in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <ol style="margin-top: 0in" start="20" type="1">
      <ol style="margin-top: 0in" start="7" type="a">
        <li
          class="MsoNormal"
          style="
            margin-bottom: 0in;
            margin-bottom: 0.0001pt;
            text-align: justify;
            line-height: normal;
          "
        >
          <u
            ><span
              style="font-size: 12pt; font-family: 'Times New Roman', serif"
              >Paying For The Arbitration</span
            ></u
          ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
            >.</span
          >
        </li>
      </ol>
    </ol>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >Each party will pay the fees for his, her or its own attorneys, subject
        to any remedies to which that party may later be entitled under
        applicable law (i.e., a party prevails on a claim that provides for the
        award of reasonable attorney fees to the prevailing party).' In all
        cases where required by law, the Company will pay the Arbitrator's and
        arbitration fees. If under applicable law the Company is not required to
        pay all of the Arbitrator's and/or arbitration fees, such fee(s) will be
        apportioned equally between the Parties or as otherwise required by
        applicable law. However, Client will not be required to bear any type of
        fee or expense that Client would not be required to bear if Client had
        filed the action in a court of law.' Any disputes in that regard will be
        resolved by the Arbitrator as soon as practicable after the Arbitrator
        is selected, and Company shall bear all of the Arbitrator's and
        arbitration fees until such time as the Arbitrator resolves any such
        dispute.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 1in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <ol style="margin-top: 0in" start="20" type="1">
      <ol style="margin-top: 0in" start="8" type="a">
        <li
          class="MsoNormal"
          style="
            margin-bottom: 0in;
            margin-bottom: 0.0001pt;
            text-align: justify;
            line-height: normal;
          "
        >
          <u
            ><span
              style="font-size: 12pt; font-family: 'Times New Roman', serif"
              >The Arbitration Hearing And Award</span
            ></u
          ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
            >.</span
          >
        </li>
      </ol>
    </ol>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >The Parties will arbitrate their dispute before the Arbitrator, who
        shall confer with the Parties regarding the conduct of the hearing and
        resolve any disputes the Parties may have in that regard.' Within 30
        days of the close of the arbitration hearing, or within a longer period
        of time as agreed to by the Parties or as ordered by the Arbitrator, any
        party will have the right to prepare, serve on the other party and file
        with the Arbitrator a brief.' The Arbitrator may award any party any
        remedy to which that party is entitled under applicable law, but such
        remedies shall be limited to those that would be available to a party in
        his or her individual capacity in a court of law for the claims
        presented to and decided by the Arbitrator, and no remedies that
        otherwise would be available to an individual in a court of law will be
        forfeited by virtue of this Arbitration Provision.' The Arbitrator will
        issue a decision or award in writing, stating the essential findings of
        fact and conclusions of law.' A court of competent jurisdiction shall
        have the authority to enter a judgment upon the award made pursuant to
        the arbitration.'
      </span>
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <ol style="margin-top: 0in" start="20" type="1">
      <ol style="margin-top: 0in" start="9" type="a">
        <li
          class="MsoNormal"
          style="
            margin-bottom: 0in;
            margin-bottom: 0.0001pt;
            text-align: justify;
            line-height: normal;
          "
        >
          <u
            ><span
              style="font-size: 12pt; font-family: 'Times New Roman', serif"
              >Client's Right To Opt Out of Arbitration</span
            ></u
          ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
            >.</span
          >
        </li>
      </ol>
    </ol>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >Arbitration is not a mandatory condition of Client's contractual
        relationship with </span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >Daivergent</span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >. If Client does not want to be subject to this Arbitration Provision,
        Client may opt out of this Arbitration Provision by notifying </span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >Daivergent </span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >in writing of Client's desire to opt out of this Arbitration Provision,
        which writing must be dated, signed and delivered by electronic mail to
        <span class="MsoHyperlink"
          ><a href="mailto:legal@daivergent.com">legal@daivergent.com</a></span
        >, by U.S. Mail, or by any nationally recognized delivery service (e.g,
        UPS, Federal Express, etc.), or by hand delivery to:</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >ATTN: Daivergent Legal Department</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >ADDRESS: 415 Madison Avenue, Fourth Floor, New York, NY 10017</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >In order to be effective, the writing must clearly indicate Client's
        intent to opt out of this Arbitration Provision and the envelope
        containing the signed writing must be received (if delivered by hand) or
        post-marked within 30 days of the date this Agreement is executed by
        Client. Client's writing opting out of this Arbitration Provision will
        be filed with a copy of this Agreement and maintained by </span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >Daivergent</span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >. Should Client not opt out of this Arbitration Provision within the
        30-day period, Client and </span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >Daivergent </span
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >shall be bound by the terms of this Arbitration Provision. Client has
        the right to consult with counsel of Client's choice concerning this
        Arbitration Provision. Client understand that Client will not be subject
        to retaliation if Client exercises Client's right to assert claims or
        opt-out of coverage under this Arbitration Provision.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 1in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <ol style="margin-top: 0in" start="20" type="1">
      <ol style="margin-top: 0in" start="10" type="a">
        <li
          class="MsoNormal"
          style="
            margin-bottom: 0in;
            margin-bottom: 0.0001pt;
            text-align: justify;
            line-height: normal;
          "
        >
          <u
            ><span
              style="font-size: 12pt; font-family: 'Times New Roman', serif"
              >Full and Complete Agreement Related to Formal Resolution of
              Disputes; Enforcement Of This Agreement</span
            ></u
          ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
            >.</span
          >
        </li>
      </ol>
    </ol>

    <p
      class="MsoNormal"
      style="
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 1in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >This Arbitration Provision is the full and complete agreement relating
        to the formal resolution of disputes arising out of this Agreement.'
        Except as stated in Section 20(e), above, in the event any portion of
        this Arbitration Provision is deemed unenforceable, the remainder of
        this Arbitration Provision will be enforceable.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin-bottom: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: 0.25in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >&nbsp;</span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        margin: 0in;
        margin-bottom: 0.0001pt;
        text-align: justify;
        text-indent: 0.25in;
        line-height: normal;
      "
    >
      <span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >21.<span style="font: 7pt 'Times New Roman'">&nbsp; </span></span
      ><u
        ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
          >Miscellaneous</span
        ></u
      ><span style="font-size: 12pt; font-family: 'Times New Roman', serif"
        >. (a) This Agreement, together with any Daivergent rules or policies
        referred to herein, represents the complete agreement between Client and
        Daivergent concerning the subject matter hereof, and it replaces and
        supersedes all prior oral or written communications concerning such
        subject matter; provided, however, that to the extent Client has also
        retained Daivergent in connection with its calendar-based appointment
        booking service, such agreement shall remain in full force and effect
        until terminated. (b) Any notice hereunder must be given in writing by
        electronic mail to the Daivergent email below (if made by Client to
        Daivergent) or to the Client email below or updated in Daivergent's
        system (if made by Daivergent to Client). (c) Client may not assign,
        transfer or delegate this Agreement or any part of it without
        Daivergent's prior written consent.&nbsp; Daivergent may freely
        transfer, assign or delegate all or any part of this Agreement, and any
        rights and duties thereunder, upon the giving of notice.&nbsp;(d) This
        Agreement will be binding upon and inure to the benefit of the heirs,
        successors and permitted assignees of the parties. (e) The provisions of
        this Agreement are severable, and in the event any provision hereof is
        determined to be invalid or unenforceable, such invalidity or
        unenforceability shall not in any way affect the validity or
        enforceability of the remaining provisions hereof.&nbsp; If any
        provision of this Agreement is held to be invalid or unenforceable, such
        provision shall be reformed, if reasonably possible, only to the extent
        necessary to make it enforceable. (f) The headings and subheadings in
        this Agreement are for convenience only, confer no rights or obligations
        in either party, and do not alter any terms of this Agreement. (g) The
        parties represent and agree that they fully understand their right to
        discuss all aspects of this Agreement with their attorneys, that they
        have availed themselves of this right, that their attorneys have
        counseled them with respect thereto, that they have carefully read all
        of the provisions of this Agreement, that they fully understand those
        provisions and that they are voluntarily entering into this Agreement.
        (h) The Terms of Use shall be incorporated by reference into this
        Agreement therein to the same extent as if set out herein
        verbatim.</span
      >
    </p>
  </div>
  <!-- prettier-ignore-end -->
</template>

<script>
export default {
  name: 'Confirm',
};
</script>

<style lang="stylus" scoped></style>
