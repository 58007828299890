<template>
  <v-row dense align="stretch" align-content="stretch">
    <v-col class="pr-3">
      <div>
        <v-card v-if="hasPathways" class="pa-4">
          <v-card-text>
            <h2>You have completed all your work!</h2>
          </v-card-text>
          <v-card-text>
            <p>
              Click <router-link :to="{ name: 'goals' }">here</router-link> to
              check out your completed work. Contact your VSP to find out what
              you should work on next.
            </p>
          </v-card-text>
        </v-card>
      </div>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'NoPrimary',
  props: ['hasPathways'],
};
</script>
<style scoped></style>
