<template>
  <v-container id="work-center" fluid>
    <v-row justify="center">
      <v-col cols="10">
        <h1>Daivergent Webinars</h1>
      </v-col>
      <v-col cols="10" sm="10" md="5">
        <v-card class="pa-2 ma-2">
          <h2 class="head_text mb-2">Getting Started in the Job Process</h2>
          <p>
            The first in a series of Daivergent Webinars with a focus on Job
            Seeking.
          </p>
          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/7ylngztsPPY"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </v-card>
      </v-col>
      <v-col cols="10" sm="10" md="5">
        <v-card class="pa-2 ma-2">
          <h2 class="head_text mb-2">What Careers are Out There?</h2>
          <p>
            The second part of the Daivergent Job Seekers Webinar Series
            discusses how to start to approach the job process and what path may
            be right for you.
          </p>
          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/3Q-zqcCoSk0"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </v-card>
      </v-col>
      <v-col cols="10" sm="10" md="5">
        <v-card class="pa-2 ma-2">
          <h2 class="head_text mb-2">
            Tips and Tricks to Build a Great Resume and LinkedIn Profile
          </h2>
          <p>
            The third part of the Daivergent Job Seekers Webinar Series
            discusses how to develop your LinkedIn Profile and Resume - two
            critical components of the Job Search.
          </p>
          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/EXtCX9PfGB8"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </v-card>
      </v-col>
      <v-col cols="10" sm="10" md="5">
        <v-card class="pa-2 ma-2">
          <h2 class="head_text mb-2">
            How to Handle Interviews and Applications
          </h2>
          <p>
            The fourth video in the Daivergent Job Seekers Webinar discusses the
            interview and application process.
          </p>
          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/D1U5lmveOc4"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </v-card>
      </v-col>
      <v-col cols="10" sm="10" md="5">
        <v-card class="pa-2 ma-2">
          <h2 class="head_text mb-2">Self Advocacy in the Workplace</h2>
          <p>
            The fifth video in the Daiverent Job Seekers Webinar Series
            discusses how to appropriately advocate for your needs and wants in
            a professional environment.
          </p>
          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/suID85KEADI"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </v-card>
      </v-col>
      <v-col cols="10" sm="10" md="5">
        <v-card class="pa-2 ma-2">
          <h2 class="head_text mb-2">Budgeting and Financial Literacy</h2>
          <p>
            The sixth and final video in the Daivergent Job Seekers Webinar
            Series discusses the basics of how to budget and develop financial
            literacy skills.
          </p>
          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/_4vyd8PErvk"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({}),
  computed: {},
  methods: {},
};
</script>

<style scoped>
#yt-frame {
  width: 100%;
}
.head_text {
  word-break: break-word;
}
</style>
