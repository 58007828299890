<template>
  <v-card class="pa-4">
    <v-card-text>
      <v-layout row wrap>
        <v-flex xs12 md3>
          <v-avatar class="mx-auto d-block mb-4" size="160" color="grey">
            <img v-if="authUser.picture" :src="authUser.picture" />
            <v-icon width="300" x-large color="white">mdi-account</v-icon>
          </v-avatar>
          <h3 class="card-title text-center base--text">
            {{ user.profile.first_name }} {{ user.profile.last_name }}
          </h3>
          <h4 class="category text-center mb-2 base--text">
            <em>{{ userType }}</em>
          </h4>
          <p class="text-center base--text text-body-2">
            <v-icon small left>mdi-mail-outline</v-icon>
            {{ user.email }}
          </p>
          <p class="text-center base--text">
            <TrackedButton
              buttonText="Sign Out"
              color="blue-grey lighten-2"
              name="sign-out-click"
              outlined
              rounded
              small
              @click="logout()"
            />
          </p>
        </v-flex>
        <v-flex xs12 md9>
          <v-layout row wrap>
            <v-flex xs12 sm6 class="pb-0 mb-3">
              <h2 class="head_text pt-1 base--text">PROFILE SETTINGS</h2>
              <!-- <h2 class="pt-1">PERSONAL SETTINGS</h2> -->
            </v-flex>
            <v-flex xs12 sm6 class="text-sm-right pb-0 mb-3">
              <TrackedButton
                buttonText="edit"
                color="primary"
                name="edit-profile-settings-click"
                outlined
                rounded
                small
                @click="editing = !editing"
                v-if="!editing"
              />
              <TrackedButton
                buttonText="cancel"
                class="mr-1"
                color="primary"
                name="cancel-profile-settings-click"
                rounded
                small
                textStyle
                @click="editing = false"
                v-if="editing"
              />
              <TrackedButton
                buttonText="Save"
                color="primary"
                icon="mdi-content-save"
                iconClass="mr-3"
                iconSmall
                name="save-profile-settings-click"
                rounded
                small
                @click="validate()"
                v-if="editing"
              />
            </v-flex>
          </v-layout>
          <!-- <p class="subheading-1">
            Please fill out your personal settings. These settings will be kept
            private and only be used for job matching purposes.
          </p> -->
          <p class="subheading-1 base--text">
            Please update your virtual resume and job preferences below.
            <br />This information will remain private and only be used for job
            matching purposes. <br />Make sure to save your changes before
            leaving the page.
          </p>
          <p class="subheading-1 base--text">
            If you have difficulties with the forms below please email us at
            <a href="mailto:help@daivergent.com">help@daivergent.com</a>.
          </p>
          <transition name="fade">
            <div v-if="!editing">
              <div class="my-2" v-if="personalSettings.first_name">
                <h3 class="mb-0 pb-0">First Name</h3>
                <span>
                  {{ personalSettings.first_name }}
                  <br />
                </span>
              </div>
              <div class="my-2" v-if="personalSettings.last_name">
                <h3 class="mb-0 pb-0">Last Name</h3>
                <span>
                  {{ personalSettings.last_name }}
                  <br />
                </span>
              </div>
              <div v-if="personalSettings.date_of_birth" class="my-2">
                <h3 class="mb-0 pb-0">Date of Birth</h3>
                <span>
                  {{ personalSettings.date_of_birth | formatDateExt }}
                  <br />
                </span>
              </div>
              <div
                class="my-2"
                v-if="personalSettings.gender_other || personalSettings.gender"
              >
                <h3 class="mb-0 pb-0">Gender</h3>
                <span v-if="personalSettings.gender_other">
                  {{ personalSettings.gender_other }}
                  <br />
                </span>
                <span v-else-if="personalSettings.gender">
                  {{
                    personalSettings.gender ? personalSettings.gender.label : ''
                  }}
                  <br />
                </span>
              </div>
              <div
                class="my-2"
                v-if="personalSettings.race_other || personalSettings.race"
              >
                <h3 class="mb-0 pb-0">Race</h3>
                <span v-if="personalSettings.race_other">
                  {{ personalSettings.race_other }}
                  <br />
                </span>
                <span v-else-if="personalSettings.race">
                  {{ personalSettings.race ? personalSettings.race.label : '' }}
                  <br />
                </span>
              </div>
              <div class="my-2" v-if="personalSettings.ethnicity">
                <h3 class="mb-0 pb-0">Ethnicity</h3>
                <span>
                  {{
                    personalSettings.ethnicity
                      ? personalSettings.ethnicity.label
                      : ''
                  }}
                  <br />
                </span>
              </div>
              <div class="my-2" v-if="personalSettings.phone_number">
                <h3 class="mb-0 pb-0">Phone Number</h3>
                <span>
                  {{ personalSettings.phone_number }}
                  <br />
                </span>
              </div>
              <div
                class="my-2"
                v-if="
                  personalSettings.address ||
                  personalSettings.extended_address ||
                  personalSettings.city ||
                  personalSettings.state ||
                  personalSettings.zip_code ||
                  personalSettings.country
                "
              >
                <h3 class="mb-0 pb-0">Address</h3>
                <span
                  v-if="
                    personalSettings.address ||
                    personalSettings.extended_address
                  "
                >
                  <span v-if="personalSettings.address">
                    {{ personalSettings.address }}
                  </span>
                  <span v-if="personalSettings.extended_address">
                    {{ personalSettings.extended_address }}
                  </span>
                  <br />
                </span>
                <span v-if="personalSettings.city || personalSettings.state">
                  <span v-if="personalSettings.city">
                    {{ personalSettings.city
                    }}{{ personalSettings.state ? ', ' : '' }}
                  </span>
                  <span v-if="personalSettings.state">
                    {{ personalSettings.state }}
                  </span>
                  <span v-if="personalSettings.zip_code">
                    {{ personalSettings.zip_code }}
                    <br />
                  </span>
                </span>
                <span v-if="personalSettings.country">
                  {{ display_country(personalSettings.country) }}
                  <br />
                </span>
              </div>
              <div class="my-2" v-if="personalSettings.timezone">
                <h3 class="mb-0 pb-0">Timezone</h3>
                <span v-if="personalSettings.timezone">
                  {{ personalSettings.timezone }}
                  <br />
                </span>
              </div>
              <div v-if="personalSettings.intent" class="my-2">
                <h3 class="mb-0 pb-0">
                  What do you wish to get out of Daivergent?
                </h3>
                <div v-for="(intent, i) in personalSettings.intent" :key="i">
                  <span v-if="intent.value">
                    {{ intent.label }}
                    <br />
                  </span>
                </div>
              </div>
              <div class="my-2" v-if="personalSettings.affiliation">
                <h3 class="mb-0 pb-0">Affiliation</h3>
                <span v-if="personalSettings.affiliation">
                  {{ personalSettings.affiliation }}
                  <br />
                </span>
              </div>
            </div>
          </transition>
          <transition name="fade">
            <p v-if="editing">
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                  id="first_name"
                  label="First Name"
                  required
                  :rules="rules.nameRules"
                  v-model.trim="personalSettings.first_name"
                  name="FirstName"
                  prepend-icon="mdi-account-circle"
                />
                <v-text-field
                  id="last_name"
                  label="Last Name"
                  required
                  :rules="rules.nameRules"
                  v-model.trim="personalSettings.last_name"
                  name="LastName"
                  prepend-icon="mdi-account-supervisor-circle"
                />
                <v-text-field
                  id="dateOfBirth"
                  type="date"
                  label="Date of Birth (MM/DD/YYYY)"
                  :rules="rules.dobRules"
                  required
                  v-model="personalSettings.date_of_birth"
                  name="DateOfBirth"
                  prepend-icon="mdi-calendar"
                />
                <div
                  v-if="
                    personalSettings.gender_other ||
                    personalSettings.gender === 'other'
                  "
                >
                  <v-text-field
                    v-model.trim="personalSettings.gender_other"
                    id="gender"
                    name="Gender"
                    clearable
                    @click:clear="
                      personalSettings.gender_other = undefined;
                      personalSettings.gender = undefined;
                    "
                    :rules="rules.genderOtherRules"
                    label="Gender: how do you identify?"
                    prepend-icon="mdi-account-details"
                  />
                </div>
                <div
                  v-else-if="
                    (personalSettings.gender &&
                      personalSettings.gender !== 'other') ||
                    (!personalSettings.gender && !personalSettings.gender_other)
                  "
                >
                  <v-select
                    v-model="personalSettings.gender"
                    id="gender"
                    name="Gender"
                    :items="genders"
                    :rules="rules.genderRules"
                    clearable
                    label="Gender: how do you identify?"
                    item-text="label"
                    item-value="value"
                    prepend-icon="mdi-account-details"
                  />
                </div>
                <div
                  v-if="
                    personalSettings.race_other ||
                    personalSettings.race === 'other'
                  "
                >
                  <v-text-field
                    v-model.trim="personalSettings.race_other"
                    id="race"
                    name="Race"
                    clearable
                    @click:clear="
                      personalSettings.race_other = undefined;
                      personalSettings.race = undefined;
                    "
                    :rules="rules.raceOtherRules"
                    label="Race: how do you identify?"
                    prepend-icon="mdi-account-details"
                  />
                </div>
                <div
                  v-else-if="
                    (personalSettings.race &&
                      personalSettings.race !== 'other') ||
                    (!personalSettings.race && !personalSettings.race)
                  "
                >
                  <v-select
                    v-model="personalSettings.race"
                    id="race"
                    name="Race"
                    :items="races"
                    :rules="rules.raceRules"
                    clearable
                    label="Race: how do you identify?"
                    item-text="label"
                    item-value="value"
                    prepend-icon="mdi-account-multiple"
                  />
                </div>
                <v-select
                  v-model="personalSettings.ethnicity"
                  id="ethnicity"
                  label="Ethnicity: how do you identify?"
                  :items="ethnicities"
                  :rules="rules.ethnicityRules"
                  item-text="label"
                  item-value="value"
                  name="Ethnicity"
                  prepend-icon="mdi-alpha-e-box"
                />
                <v-select
                  v-model="personalSettings.education"
                  id="education"
                  label="Highest level of education completed"
                  :items="educationLevels"
                  :rules="rules.educationRules"
                  item-text="label"
                  item-value="value"
                  name="Education"
                  prepend-icon="mdi-book-open-blank-variant"
                />
                <v-text-field
                  id="phoneNumber"
                  type="tel"
                  label="Phone Number"
                  v-model="personalSettings.phone_number"
                  name="PhoneNumber"
                  prepend-icon="mdi-phone"
                />
                <v-text-field
                  id="address"
                  type="text"
                  label="Address Line 1"
                  required
                  v-model.trim="personalSettings.address"
                  name="address"
                  prepend-icon="mdi-mailbox"
                />
                <v-text-field
                  id="extended_address"
                  type="text"
                  label="Address Line 2"
                  required
                  v-model.trim="personalSettings.extended_address"
                  name="extended_address"
                  class="ml-8"
                />
                <v-text-field
                  id="city"
                  type="text"
                  label="City"
                  required
                  v-model.trim="personalSettings.city"
                  name="city"
                  class="ml-8"
                />
                <v-autocomplete
                  :items="states"
                  label="State"
                  v-model="personalSettings.state"
                  item-value="abbreviation"
                  item-text="name"
                  :filter="filterState"
                  clearable
                  class="ml-8"
                >
                  <template v-slot:selection="{ item }">
                    {{ item.abbreviation }} - {{ item.name }}
                  </template>
                  <template v-slot:item="{ item }">
                    {{ item.abbreviation }} - {{ item.name }}
                  </template>
                </v-autocomplete>
                <v-text-field
                  id="zipCode"
                  type="text"
                  label="Zip Code"
                  class="ml-8"
                  required
                  :rules="rules.zipRules"
                  v-model.trim="personalSettings.zip_code"
                  name="ZipCode"
                />
                <v-autocomplete
                  id="country"
                  name="Country"
                  :rules="rules.countryRules"
                  :items="countries"
                  item-text="country"
                  label="Country of Residence"
                  v-model="personalSettings.country"
                  class="ml-8"
                />
                <v-autocomplete
                  id="timezone"
                  name="Timezone"
                  :rules="rules.timezoneRules"
                  :items="timezoneList"
                  label="Timezone"
                  v-model="personalSettings.timezone"
                  prepend-icon="mdi-map-clock"
                />
                <div v-for="(intent, i) in personalSettings.intent" :key="i">
                  <v-switch
                    :id="intent.id"
                    :label="intent.label"
                    v-model="intent.value"
                    :name="intent.id"
                  />
                </div>
                <v-text-field
                  id="affiliation"
                  label="Affiliated Organization"
                  required
                  :rules="rules.affiliationRules"
                  v-model.trim="personalSettings.affiliation"
                  name="Affiliation"
                  prepend-icon="mdi-school"
                />
                <p>Enter 'n/a' if no affiliated organization</p>
              </v-form>
            </p>
          </transition>
        </v-flex>
      </v-layout>
      <v-snackbar color="info" top v-model="snackbar" :timeout="timeout">{{
        text
      }}</v-snackbar>
    </v-card-text>
  </v-card>
</template>

<script>
import { includes, map, mapValues, sortBy, find, defaults } from 'lodash/fp';
import axios from 'axios';
import { mapState, mapGetters } from 'vuex';
import { mask } from 'vue-the-mask';
import countries from '@/assets/countries.json';
import TrackedButton from '@/components/TrackedButton.vue';
import moment from 'moment-timezone';
import { states } from '@/helpers/states';

export default {
  components: { TrackedButton },
  directives: {
    mask,
  },
  data: () => ({
    states: states,
    valid: true,
    editing: false,
    personalSettings: {
      first_name: undefined,
      last_name: undefined,
      date_of_birth: undefined,
      gender: undefined,
      gender_other: undefined,
      race: undefined,
      race_other: undefined,
      ethnicity: undefined,
      education: undefined,
      zip_code: undefined,
      phone_number: undefined,
      address: undefined,
      extended_address: undefined,
      city: undefined,
      state: undefined,
      country: undefined,
      affiliation: undefined,
      timezone: undefined,
      intent: {
        job_seeker: false,
        work_experience: false,
        skills_improvement: false,
      },
    },
    genders: [
      { label: 'Man', value: 'male' },
      { label: 'Woman', value: 'female' },
      { label: 'Non-binary', value: 'nonbinary' },
      { label: 'Other (if selected, please type in response)', value: 'other' },
    ],
    races: [
      { label: 'White', value: 'white' },
      { label: 'Black', value: 'black' },
      { label: 'American Indian or Alaskan Native', value: 'amerindian' },
      { label: 'Asian', value: 'asian' },
      { label: 'Native Hawaiian or other Pacific islander', value: 'nhpi' },
      { label: 'Biracial / Multiracial / From Multiple Races', value: 'multi' },
      { label: 'Other (if selected, please type in response)', value: 'other' },
    ],
    ethnicities: [
      { label: 'I am Hispanic / Latino', value: 'hispanic' },
      { label: 'I am not Hispanic / Latino', value: 'not_hispanic' },
    ],
    educationLevels: [
      { label: 'Some High School, no diploma', value: 'some_hs' },
      { label: 'High School or Equivalent (e.g. GED)', value: 'high_school' },
      { label: 'Associates Degree', value: 'associate_deg' },
      { label: "Bachelor's Degree", value: 'bachelor_deg' },
      { label: 'Masters Degree', value: 'masters_deg' },
      { label: 'Doctoral Degree (PhD)', value: 'doctoral_deg' },
      {
        label: 'Professional Degree (MD, JD, etc.)',
        value: 'professional_deg',
      },
      { label: 'Prefer not to respond', value: 'prefer_no_response' },
    ],
    intents: [
      {
        id: 'skills_improvement',
        label: "I'd like to get skills that help me live independently",
        value: false,
      },
      {
        id: 'work_experience',
        label: "I'd like to get skills that help me socialize with others",
        value: false,
      },
      {
        id: 'job_seeker',
        label: "I'd like to get skills that help me get a job",
        value: false,
      },
    ],
    timezoneList: moment.tz.zonesForCountry('US'),
    snackbar: false,
    text: 'Successfully Saved Personal Settings.',
    timeout: 2000,
    countries: sortBy(['country'], countries),
    stripeLink: null,
    stripeLoginLink: null,
  }),
  async created() {
    this.setPersonalsettings();
  },
  computed: {
    rules() {
      const vm = this;
      return {
        nameRules: [(v) => !!v || 'Name is required'],
        affiliationRules: [
          (v) => !!v || "Referrer is required, If not applicable, put 'n/a'",
        ],
        zipRules: [
          (v) => !!v || 'Zip Code is required',
          (v) =>
            /^[a-z0-9][a-z0-9\- ]{0,10}[a-z0-9]$/.test(v) ||
            'Zip Code must be valid',
        ],
        countryRules: [
          (v) => !!v || 'Country is required',
          (v) => vm.validate_country(v),
        ],
        timezoneRules: [(v) => !!v || 'Timezone is required'],
        termsRules: [(v) => !!v || 'Must accept terms before continuing'],
        dobRules: [(v) => !!v || 'Date of Birth is required'],
        genderRules: [(v) => !!v || 'Gender is required'],
        genderOtherRules: [(v) => !!v || 'Gender is required'],
        raceRules: [(v) => !!v || 'Race is required'],
        raceOtherRules: [(v) => !!v || 'Race is required'],
        ethnicityRules: [(v) => !!v || 'Ethnicity is required'],
        educationRules: [(v) => !!v || 'Education is required'],
      };
    },
    ...mapState({
      user: (state) => state.user.user,
      authUser: (state) => state.user.auth_user,
      stripe_id: (state) => state.user.user.stripe_id,
    }),
    ...mapGetters({
      userType: 'user/userType',
    }),
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) this.updatePersonalSettings();
    },

    setPersonalsettings() {
      const vm = this;
      // get personal settings from user store
      vm.personalSettings = vm.user.profile;
      if (vm.personalSettings) {
        vm.personalSettings.gender = vm.personalSettings.gender
          ? find((r) => r.value === vm.personalSettings.gender, vm.genders)
          : undefined;
        vm.personalSettings.race = vm.personalSettings.race
          ? find((r) => r.value === vm.personalSettings.race, vm.races)
          : undefined;
        vm.personalSettings.ethnicity = vm.personalSettings.ethnicity
          ? find(
              (r) => r.value === vm.personalSettings.ethnicity,
              vm.ethnicities
            )
          : undefined;
        vm.personalSettings.education = vm.personalSettings.education
          ? find(
              (r) => r.value === vm.personalSettings.education,
              vm.educationLevels
            )
          : undefined;
        vm.personalSettings.intent = vm.personalSettings.intent
          ? map((i) => {
              if (vm.personalSettings.intent[i.id]) {
                i.value = true;
              }
              return i;
            }, vm.intents)
          : vm.intents;
        vm.personalSettings.timezone = vm.personalSettings.timezone
          ? vm.personalSettings.timezone
          : undefined;
        moment.tz.setDefault(vm.personalSettings.timezone);
      }
    },

    async updatePersonalSettings() {
      const vm = this;
      try {
        const output = defaults(vm.user.profile, vm.personalSettings);
        const intent = {};
        if (output.intent && output.intent.length)
          output.intent = map((o) => {
            if (o.value) {
              intent[o.id] = true;
            }
          }, output.intent);
        output.intent = intent;
        await axios
          .post('/api/profile', {
            profile: mapValues((o) => o.value || o, output),
          })
          .then(() => vm.setPersonalsettings());
        setTimeout(() => {
          vm.snackbar = true;
          vm.editing = false;
        }, 500);
      } catch (error) {
        //console.log('Not able to update Personal Settings.', error);
      }
    },
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
    validate_country(code) {
      return includes(code, map('value', countries));
    },
    display_country(code) {
      const row = find({ value: code }, countries);
      if (row && row.country) {
        return row.country;
      }
      return code;
    },
    filterState(item, queryText) {
      return (
        item.abbreviation.toLowerCase().includes(queryText.toLowerCase()) ||
        item.name.toLowerCase().includes(queryText.toLowerCase())
      );
    },
  },
};
</script>

<style>
.editing {
  display: none !important;
}

.fade-enter-active {
  transition: opacity 0.5s ease-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.head_text {
  word-break: break-word;
}
</style>
