import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import { authGuard } from '@/auth/authGuard';
import { intercomGuard } from '@/router/intercomGuard';
import { userVerifiedGuard } from '@/router/userVerifiedGuard';
import { pageChangeGuard } from '@/router/pageChangeGuard';
import { isUndefined, isFunction } from 'lodash/fp';
import mixpanel from 'mixpanel-browser';

import Profile from '../views/Profile';
import Intake from '../views/Intake';
import VerifyEmail from '../views/VerifyEmail';
import DaivergentLearning from '../views/DaivergentLearning';
import CommunityCenter from '../views/CommunityCenter';
import JobCenter from '../views/JobCenter';
import Classes from '../views/Classes';
import SkillPathClasses from '../views/SkillPathClasses';
import GoalCenter from '../views/GoalCenter';
import TermsOfService from '../views/TermsOfService';
import Expired from '../views/Expired';
import SystemError from '../views/SystemError';
import PageNotFound from '../views/PageNotFound';
import Dashboard from '../views/Dashboard';
import Community from '../views/Community';

import Pathway from '../views/strapi/Pathway';
import Module from '../views/strapi/Module';

Vue.use(VueRouter);

const routes = [
  /*
  add userVerifiedGuard to whichever route you want to check
  if the user has verified against the 72hr threshold
  */
  {
    path: '/',
    name: 'home',
    component: Dashboard,
    meta: {
      store: store,
    },
    beforeEnter: (to, from, next) =>
      evaluateRouteFns(
        [authGuard, intercomGuard, userVerifiedGuard, pageChangeGuard],
        to,
        from,
        next
      ),
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    beforeEnter: (to, from, next) =>
      evaluateRouteFns(
        [authGuard, intercomGuard, userVerifiedGuard, pageChangeGuard],
        to,
        from,
        next
      ),
  },
  {
    path: '/login',
    redirect: { name: 'home' },
    beforeEnter: (to, from, next) =>
      evaluateRouteFns(
        [authGuard, intercomGuard, userVerifiedGuard, pageChangeGuard],
        to,
        from,
        next
      ),
  },
  {
    path: '/intake',
    name: 'intake',
    component: Intake,
    meta: { title: () => 'Register for Domain' },
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: { title: () => 'Domain: Profile' },
    beforeEnter: (to, from, next) =>
      evaluateRouteFns(
        [authGuard, intercomGuard, userVerifiedGuard, pageChangeGuard],
        to,
        from,
        next
      ),
  },
  {
    path: '/verify-email',
    name: 'verify-email',
    component: VerifyEmail,
    meta: { title: () => 'Domain: Verify Email' },
    beforeEnter: (to, from, next) =>
      evaluateRouteFns(
        [authGuard, intercomGuard, userVerifiedGuard, pageChangeGuard],
        to,
        from,
        next
      ),
  },
  {
    path: '/goals/:goal_user_uuid',
    name: 'goal-details',
    component: GoalCenter,
    meta: { title: () => 'Domain: Goals', permissions: 'goals' },
    beforeEnter: (to, from, next) =>
      evaluateRouteFns(
        [authGuard, intercomGuard, userVerifiedGuard, pageChangeGuard],
        to,
        from,
        next
      ),
  },
  {
    path: '/goals',
    name: 'goals',
    component: GoalCenter,
    meta: { title: () => 'Domain: Goals', permissions: 'goals' },
    beforeEnter: (to, from, next) =>
      evaluateRouteFns(
        [authGuard, intercomGuard, userVerifiedGuard, pageChangeGuard],
        to,
        from,
        next
      ),
  },
  // {
  //   path: '/goal-center/pathway/:pathway_id/(pathway/)?',
  //   name: 'pathway-content',
  //   component: Pathway,
  //   meta: { title: () => 'Domain: Pathway' },
  //   beforeEnter: (to, from, next) =>
  //     evaluateRouteFns(
  //       [authGuard, intercomGuard, goalCenterGuard, pageChangeGuard],
  //       to,
  //       from,
  //       next
  //     ),
  // },
  {
    path: '/goal-center/pathway/:pathway_uuid/module/:module_uuid',
    name: 'module-content',
    component: Module,
    meta: { title: () => 'Domain: Module', permissions: 'modules' },
    beforeEnter: (to, from, next) =>
      evaluateRouteFns(
        [authGuard, intercomGuard, userVerifiedGuard, pageChangeGuard],
        to,
        from,
        next
      ),
  },
  {
    path: '/goal-center/pathway/:pathway_uuid',
    name: 'pathway-content',
    component: Pathway,
    meta: { title: () => 'Domain: Pathway', permissions: 'pathways' },
    beforeEnter: (to, from, next) =>
      evaluateRouteFns(
        [authGuard, intercomGuard, userVerifiedGuard, pageChangeGuard],
        to,
        from,
        next
      ),
  },
  {
    path: '/webinars',
    name: 'webinars',
    component: DaivergentLearning,
    meta: { title: () => 'Domain: Webinars' },
    beforeEnter: (to, from, next) =>
      evaluateRouteFns(
        [authGuard, intercomGuard, userVerifiedGuard, pageChangeGuard],
        to,
        from,
        next
      ),
  },
  {
    path: '/social',
    name: 'social',
    component: CommunityCenter,
    meta: { title: () => 'Domain: Social' },
    beforeEnter: (to, from, next) =>
      evaluateRouteFns(
        [authGuard, intercomGuard, userVerifiedGuard, pageChangeGuard],
        to,
        from,
        next
      ),
  },
  {
    path: '/skill-path',
    name: 'skillpathclasses',
    component: SkillPathClasses,
    meta: { title: () => 'Domain: Skill Path Classes' },
    beforeEnter: (to, from, next) =>
      evaluateRouteFns(
        [authGuard, intercomGuard, userVerifiedGuard, pageChangeGuard],
        to,
        from,
        next
      ),
  },
  {
    path: '/job-listings',
    name: 'joblistings',
    component: JobCenter,
    meta: { title: () => 'Domain: Job Listings', permissions: 'jobs' },
    beforeEnter: (to, from, next) =>
      evaluateRouteFns(
        [authGuard, intercomGuard, userVerifiedGuard, pageChangeGuard],
        to,
        from,
        next
      ),
  },
  {
    path: '/classes',
    name: 'classes',
    component: Classes,
    meta: { title: () => 'Domain: Classes', permissions: 'classes' },
    beforeEnter: (to, from, next) =>
      evaluateRouteFns(
        [authGuard, intercomGuard, userVerifiedGuard, pageChangeGuard],
        to,
        from,
        next
      ),
  },
  {
    path: '/community',
    name: 'community',
    component: Community,
    meta: { title: () => 'Domain: Community', permissions: 'community' },
    beforeEnter: (to, from, next) =>
      evaluateRouteFns(
        [authGuard, intercomGuard, userVerifiedGuard, pageChangeGuard],
        to,
        from,
        next
      ),
  },
  {
    path: '/terms-of-service',
    name: 'termsofservice',
    component: TermsOfService,
    meta: { title: () => 'Domain: Terms of Service' },
    beforeEnter: (to, from, next) =>
      evaluateRouteFns(
        [authGuard, intercomGuard, userVerifiedGuard, pageChangeGuard],
        to,
        from,
        next
      ),
  },
  {
    path: '/error',
    name: 'systemerror',
    component: SystemError,
    meta: { title: () => 'Domain: System Error' },
    // beforeEnter: (to, from, next) =>
    //   evaluateRouteFns([authGuard, intercomGuard, pageChangeGuard], to, from, next),
  },
  {
    path: '/expired',
    name: 'expired',
    component: Expired,
    meta: { title: () => 'Domain: Expired' },
    // beforeEnter: (to, from, next) =>
    //   evaluateRouteFns([authGuard, intercomGuard, pageChangeGuard], to, from, next),
  },
  {
    path: '*',
    name: 'notfound',
    component: PageNotFound,
    meta: { title: () => 'Domain: Page Not Found' },
    // beforeEnter: (to, from, next) =>
    //   evaluateRouteFns([authGuard, intercomGuard, pageChangeGuard], to, from, next),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeResolve(async (to, from, next) => {
  const permissions = store.getters['user/permissions'];
  if (Object.keys(permissions).length) {
    if (to.meta.permissions && !permissions[to.meta.permissions])
      return next(from);
  }
  return next();
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = 'Domain';
    if (to.meta.title && isFunction(to.meta.title)) {
      document.title = to.meta.title(to);
    }
    mixpanel.track('Domain Page View');
  });
});

export default router;

function evaluateRouteFns(guards, to, from, next) {
  const guardsLeft = guards.slice(0); // clone the array so we do not accidentally modify it
  const nextGuard = guardsLeft.shift();

  if (isUndefined(nextGuard)) {
    next();
    return;
  }

  nextGuard(to, from, (nextArg) => {
    if (isUndefined(nextArg)) {
      evaluateRouteFns(guardsLeft, to, from, next);
      return;
    }

    next(nextArg);
  });
}
