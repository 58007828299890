<template>
  <v-btn
    :absolute="absolute"
    top
    right
    small
    color="primary"
    @click="toggleDetail()"
  >
    {{ settings[status].text }}
  </v-btn>
</template>

<script>
export default {
  name: 'ToggleDetailsBtn',
  props: {
    show: {
      required: false,
      default: true,
    },
    absolute: {
      required: false,
      default: false,
    },
  },
  methods: {
    toggleDetail() {
      this.$emit('toggle');
    },
  },
  data() {
    return {
      settings: {
        show: {
          icon: 'mdi-chevron-down',
          text: 'Show Details',
        },
        hide: {
          icon: 'mdi-chevron-up',
          text: 'Hide Details',
        },
      },
    };
  },
  computed: {
    status: function () {
      return this.show ? 'show' : 'hide';
    },
  },
};
</script>
