import axios from 'axios';
export default {
  async createModuleUser(module_uuid, pathway_user_uuid) {
    return axios.post(`/api/module_user/`, {
      module_uuid,
      pathway_user_uuid,
      metadata: {},
    });
  },
  async startModuleUser(module_user_uuid) {
    return axios.post(`/api/module_user/${module_user_uuid}/start`);
  },
  async resumeModuleUser(module_user_uuid) {
    return axios.post(`/api/module_user/${module_user_uuid}/resume`);
  },
  async completeModuleUser(module_user_uuid) {
    return axios.post(`/api/module_user/${module_user_uuid}/complete`);
  },
  async gradeModuleUser(payload) {
    return axios.post('/api/module_user/grade', payload);
  },
  async updateModuleUser(payload) {
    return axios.post('/api/module_user/update', payload);
  },
  async checkModuleVisible(pathway_uuid) {
    return axios.get(`/api/pathway/${pathway_uuid}/visible`);
  },
};
