<template>
  <v-row justify="center">
    <v-col cols="12">
      <div v-html="oembedHtml"></div>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios';
export default {
  props: ['content'],
  data() {
    return {
      oembedHtml: '',
    };
  },
  created() {
    const oembed = JSON.parse(this.content.oembed);
    this.oembedHtml = oembed.rawData.html;
  },
};
</script>

<style></style>
