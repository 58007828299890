<template>
  <v-row>
    <v-col class="pb-0 pb-md-3">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-progress-linear
            v-bind="attrs"
            v-on="on"
            :value="progress"
            :color="settings[status].color"
            height="10"
          />
        </template>
        <span>{{ settings[status].text }}</span>
      </v-tooltip>
    </v-col>
    <v-icon :color="settings[status].iconColor">
      {{ settings[status].icon }}
    </v-icon>
  </v-row>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: ['progress', 'status'],
  data() {
    return {
      settings: {
        created: {
          color: 'primary',
          text: 'You have not started this goal yet.',
        },
        started: {
          color: 'primary',
          text: 'You made some progress with this goal.',
        },
        completed: {
          icon: 'mdi-trophy',
          iconColor: '#FFAC00',
          color: 'green',
          text: 'This goal was completed!',
        },
      },
    };
  },
};
</script>
