import { Socket } from 'phoenix';
import { has, isUndefined } from 'lodash/fp';

class DomainSocket {
  constructor() {
    this.socket;
    this.channels = {};
  }

  connect(token) {
    this.socket = new Socket('/socket', { params: { token } });
    this.socket.connect();
  }

  close() {
    if (!isUndefined(this.socket)) this.socket.close();
    this.channels = {};
  }

  join_channel(user_uuid, context) {
    const vm = this;
    // console.log('joining: ' + context + ':' + user_uuid);
    if (vm.socket) {
      const channel = vm.socket.channel(`${context}:${user_uuid}`, {});
      if (has(context, vm.channels)) {
        return vm.channels[context];
      }
      channel
        .join()
        .receive('ok', (resp) => {
          // console.log('Joined successfully looooooool', resp);
        })
        .receive('error', (resp) => {
          // console.error('Unable to join', resp);
        });
      vm.channels[context] = channel;
      channel.onClose(() => delete vm.channels[context]);

      return channel;
    } else {
      // console.log('no socket');
      return;
    }
  }
}

export const domainSocket = new DomainSocket();
