<template>
  <v-dialog v-model="open" :persistent="persistent" :width="width || 500">
    <v-card>
      <v-card-title class="headline grey lighten-2" primary-title>{{
        title
      }}</v-card-title>
      <v-card-text class="mt-3">
        {{ confirmationText }}
      </v-card-text>
      <div v-if="requireReason">
        <br />
        <v-card-text class="mt-3">
          {{ reasonPrompt }}
        </v-card-text>
        <v-text-field
          v-model.trim="reason"
          label="Reason"
          class="mx-4"
        ></v-text-field>
      </div>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <TrackedButton
          @click="emitCancel()"
          buttonText="Cancel"
          color="primary"
          :name="cancelActionName"
          textStyle
        />
        <TrackedButton
          @click="emitSubmit()"
          buttonText="Submit"
          color="primary"
          :disabled="requireReason && !reason"
          :name="submitActionName"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import TrackedButton from '@/components/TrackedButton.vue';
export default {
  name: 'ActionDialog',
  components: { TrackedButton },
  props: [
    'cancelActionName',
    'confirmationText',
    'persistent',
    'reasonPrompt',
    'requireReason',
    'submitActionName',
    'title',
    'toggle',
    'width',
  ],
  data: () => {
    return {
      reason: null,
    };
  },
  computed: {
    open: {
      get() {
        const vm = this;
        return vm.toggle;
      },
      set(val) {
        return val;
      },
    },
  },
  methods: {
    emitCancel() {
      this.$emit('cancel');
    },
    emitSubmit() {
      this.reason ? this.$emit('submit', reason) : this.$emit('submit');
    },
  },
};
</script>
<style scoped></style>
