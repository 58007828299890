import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCol,{attrs:{"cols":"10","sm":"10","md":"4"}},[_c(VCard,{staticClass:"pl-4 pa-4",attrs:{"rounded":"","p-xs-2":""}},[_c(VCardTitle,{staticClass:"justify-center pb-0",attrs:{"primary-title":""}},[_c('h2',{staticClass:"head_text"},[_vm._v(_vm._s(_vm.listing.name))])]),_c(VCardText,[(_vm.listing.user_uuid === _vm.user.user_uuid)?_c('div',{staticClass:"ribbon-wrapper"},[_c('div',{staticClass:"ribbon"},[_vm._v("Interested")])]):_vm._e(),_c(VContainer,{staticClass:"pa-0",attrs:{"fluid":"","grid-list-lg":""}},[_c(VLayout,{attrs:{"row":"","wrap":""}},[_c(VFlex,{attrs:{"xs12":""}},[_c('p',{staticClass:"text-center"},[_c(VImg,{attrs:{"src":_vm.listing.image_path ||
                  'https://storage.googleapis.com/daivergent-guilford-public/domain/listing_default.png',"contain":"","height":"270"}})],1),_c('p',{staticClass:"listing-title"},[_vm._v(_vm._s(_vm.listing.title))]),_c('p',{staticClass:"subheading",staticStyle:{"font-size":"medium"}},[_vm._v(" "+_vm._s(_vm.listing.description.short || 'Click "See More" for details.')+" ")]),_c('TrackedButton',{attrs:{"buttonText":"See More","color":"primary","data":{ listing: _vm.listing.listing_uuid },"large":"","name":"see-more-listing-click"},on:{"click":function($event){return _vm.$emit('showSeeMoreDialog', _vm.listing)}}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }