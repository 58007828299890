import { find } from 'lodash/fp';
import { userChannel } from '../../userChannel';

const state = {
  newFeedbackSurvey: null,
};

const getters = {};

const actions = {
  setNewFeedbackSurvey({ commit }, { survey }) {
    commit('set_new_feedback_survey', survey);
  },
  async init({ dispatch, state }) {
    if (userChannel.channel) {
      userChannel.channel.on('survey', (survey) => {
        if (
          !find(
            ({ survey_uuid }) => survey_uuid === survey.survey_uuid,
            state.surveys
          )
        ) {
        }
        dispatch('setNewFeedbackSurvey', { survey: survey });
      });
    }
    return;
  },
};

const modules = {};

const mutations = {
  set_new_feedback_survey(_state, survey) {
    _state.newFeedbackSurvey = survey;
  },
};

export const surveys = {
  state,
  getters,
  actions,
  mutations,
  modules,
  namespaced: true,
};
