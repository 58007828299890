import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import moment from 'moment-timezone';
import VueCookies from 'vue-cookies';
import { Auth0Plugin } from './auth';
import { domainSocket } from './socket';
import { get } from 'lodash';
// import '@babel/polyfill';
import PortalVue from 'portal-vue';

import mixpanel from 'mixpanel-browser';
// Enabling the debug mode flag is useful during implementation,
// but it's recommended you remove it for production
mixpanel.init('0f124eb8c6ae01a33138f3619867f25e');
mixpanel.track('Domain Start');

Vue.use(Auth0Plugin, {
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  },
});

Vue.use(VueCookies);

Vue.use(PortalVue);

Vue.filter('formatDateTime', function (paramval) {
  if (paramval) {
    return moment(String(paramval)).format('MMMM D, YYYY, h:mm a');
  }
});

Vue.filter('formatDatetimeExt', function (paramval) {
  if (paramval) {
    return moment(String(paramval)).format('MMMM D, YYYY, h:mm a');
  }
});

Vue.filter('formatStartEndExt', function (paramval, duration) {
  if (paramval) {
    const start = moment(String(paramval)).format('MMMM D, YYYY, h:mm a');
    const finish = moment(String(paramval))
      .add(duration, 'minutes')
      .format('h:mm a z');
    return `${start} - ${finish}`;
  }
});

Vue.filter('formatDateExt', function (paramval) {
  if (paramval) {
    return moment(String(paramval)).format('MMMM D, YYYY');
  }
});

Vue.filter('formatTime', function (paramval) {
  if (paramval) {
    return moment(String(paramval)).format('h:mm a');
  }
});

Vue.filter('formatTimeOnly', function (paramval) {
  if (paramval) {
    const arbitraryDatePrefix = '2024-01-01T';
    return moment(String(arbitraryDatePrefix + paramval)).format('h:mm a');
  }
});

Vue.filter('formatTimezone', function (paramval) {
  if (paramval) {
    return moment(String(paramval)).format('z');
  }
});

Vue.filter('formatName', function (paramval) {
  let name = '';
  const first_name = get(paramval, 'profile.first_name') || '';
  const last_name = get(paramval, 'profile.last_name') || '';
  name = first_name + ' ' + last_name;
  return name.trim();
});

Vue.filter('formatToday', function () {
  return moment().format('MMMM D, YYYY');
});

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  vuetify,
  domainSocket,
  render: (h) => h(App),
}).$mount('#app');
