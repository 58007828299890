<template>
  <v-card height="100%">
    <v-card-title primary-title>
      <h1 class="head_text">{{ card.title }}</h1>
    </v-card-title>
    <v-card-text>
      <p>
        {{ card.short_description || '[SHORT DESCRIPTION]' }}
      </p>
    </v-card-text>
    <v-card-text>
      <TrackedButton
        @click="cardClicked"
        :buttonText="entityUserData.buttonText"
        color="primary"
        icon="mdi-calendar-star"
        iconClass="mr-2"
        iconDark
        :name="entityUserData.eventName + '-click'"
        small
      >
        <v-img src="@/assets/svgs/arrow.svg" width="20" class="ml-2" />
      </TrackedButton>
    </v-card-text>
  </v-card>
</template>

<script>
import TrackedButton from '@/components/TrackedButton';
import { get, chain } from 'lodash';
export default {
  name: 'CardNoImage',
  components: { TrackedButton },
  props: ['card', 'pathway', 'module'],
  computed: {
    entityUserData: function () {
      const latest_pathway_user = this.getLatestEntityUser(
        this.pathway.pathway_users
      );
      if (this.module) {
        const latest_module_user = this.getLatestEntityUser(
          this.module.module_users
        );
        if (this.module.module_users && latest_module_user) {
          if (latest_module_user.status == 'completed')
            return {
              buttonText: 'MODULE COMPLETED',
              eventName: 'continue-module-user',
              eventData: {
                module_uuid: this.module.module_uuid,
                module_user_uuid: latest_module_user.module_user_uuid,
                pathway_uuid: this.pathway.pathway_uuid,
                pathway_user_uuid: get(
                  latest_pathway_user,
                  'pathway_user_uuid'
                ),
              },
            };
          else if (latest_module_user.status == 'stopped')
            return {
              buttonText: 'RESUME MODULE',
              eventName: 'resume-module-user',
              eventData: {
                module_uuid: this.module.module_uuid,
                module_user_uuid: latest_module_user.module_user_uuid,
                pathway_uuid: this.pathway.pathway_uuid,
                pathway_user_uuid: get(
                  latest_pathway_user,
                  'pathway_user_uuid'
                ),
              },
            };
          else if (latest_module_user.status == 'in_progress')
            return {
              buttonText: 'CONTINUE MODULE',
              eventName: 'continue-module-user',
              eventData: {
                module_uuid: this.module.module_uuid,
                module_user_uuid: latest_module_user.module_user_uuid,
                pathway_uuid: this.pathway.pathway_uuid,
                pathway_user_uuid: get(
                  latest_pathway_user,
                  'pathway_user_uuid'
                ),
              },
            };
          else {
            return {
              buttonText: 'START MODULE',
              eventName: 'start-module-user',
              eventData: {
                module_uuid: this.module.module_uuid,
                module_user_uuid: latest_module_user.module_user_uuid,
                pathway_uuid: this.pathway.pathway_uuid,
                pathway_user_uuid: get(
                  latest_pathway_user,
                  'pathway_user_uuid'
                ),
              },
            };
          }
        } else {
          return {
            buttonText: 'START MODULE',
            eventName: 'create-module-user',
            eventData: {
              module_uuid: this.module.module_uuid,
              pathway_uuid: this.pathway.pathway_uuid,
              pathway_user_uuid: get(latest_pathway_user, 'pathway_user_uuid'),
            },
          };
        }
      } else if (this.pathway) {
        if (this.pathway.pathway_users && latest_pathway_user) {
          if (latest_pathway_user.status == 'completed')
            return {
              buttonText: 'PATHWAY COMPLETED',
              eventName: 'continue-pathway-user',
              eventData: {
                pathway_uuid: this.pathway.pathway_uuid,
                pathway_user_uuid: get(
                  latest_pathway_user,
                  'pathway_user_uuid'
                ),
              },
            };
          else if (latest_pathway_user.status == 'stopped')
            return {
              buttonText: 'RESUME PATHWAY',
              eventName: 'resume-pathway-user',
              eventData: {
                pathway_uuid: this.pathway.pathway_uuid,
                pathway_user_uuid: get(
                  latest_pathway_user,
                  'pathway_user_uuid'
                ),
              },
            };
          else if (latest_pathway_user.status == 'in_progress')
            return {
              buttonText: 'CONTINUE PATHWAY',
              eventName: 'continue-pathway-user',
              eventData: {
                pathway_uuid: this.pathway.pathway_uuid,
                pathway_user_uuid: get(
                  latest_pathway_user,
                  'pathway_user_uuid'
                ),
              },
            };
          else {
            return {
              buttonText: 'START PATHWAY',
              eventName: 'start-pathway-user',
              eventData: {
                pathway_uuid: this.pathway.pathway_uuid,
                pathway_user_uuid: get(
                  latest_pathway_user,
                  'pathway_user_uuid'
                ),
              },
            };
          }
        } else {
          return {
            buttonText: 'START PATHWAY',
            eventName: 'create-pathway-user',
            eventData: {
              pathway_uuid: this.pathway.pathway_uuid,
            },
          };
        }
      } else {
        // console.error('no module or pathway found');
        return 'ERROR';
      }
    },
  },
  methods: {
    cardClicked: function () {
      this.$emit(this.entityUserData.eventName, this.entityUserData.eventData);
    },
    getLatestEntityUser(entity_users) {
      return chain(entity_users).sortBy('status_timestamp').last().value();
    },
  },
};
</script>

<style scoped>
.icon-adjust {
  vertical-align: middle;
  margin-right: 10px;
}

.v-card .v-card__title h1 {
  word-break: break-word;
}

.head_text {
  word-break: break-word;
}
</style>
